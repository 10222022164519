import { connect } from 'react-redux';
import { flow, isNil, get } from 'lodash';

import ColumnGroupedView, { StateProps, DispatchProps } from 'src/components/StandardCardView/ColumnGroupedView';
import container from 'src/ServiceContainer';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { DIMENSION_PRODUCT, ASSORTMENT } from 'src/utils/Domain/Constants';
import { GroupBySlice } from 'src/components/Subheader/Subheader.slice';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { setRightContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { receiveTenantConfig, receiveError, requestTenantConfig } from './TopTYvsLY.slice';
import { getProcessedData } from './TopTYvsLY.selectors';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { Summary } from 'src/utils/Pivot/RollUp';
import { getfabProps, withFab } from 'src/components/higherOrder/withFab';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/Favorites.types';
import { AppState, AppThunkDispatch } from 'src/store';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { TopTYvsLYViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { RollupDefn } from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { ColumnGroupedViewOwnProps } from 'src/services/configuration/codecs/ownProps';
import { FabType } from 'src/services/configuration/codecs/confdefnView';

export function generateGroupBy(dataIndex: string): GroupBySlice {
  return {
    selection: 0,
    defaultSelection: 0,
    options: [
      {
        text: 'empty',
        dataIndex,
        dimension: DIMENSION_PRODUCT,
      },
    ],
  };
}

function mapStateToProps(state: AppState, ownProps: ColumnGroupedViewOwnProps): StateProps {
  const { topTYvsLY: viewState } = state.pages.hindsighting.styleColorReview;
  const { defns, groupingInfo, subheader, fabType = FabType.none } = ownProps;
  const groupedStyles = getProcessedData(state);
  const groupBy = generateGroupBy(groupingInfo.dataIndex);
  const summary: Summary[] = [];
  const loaded = !viewState.tenantConfigLoading && isDataLoaded(viewState.viewDataState);
  const fabProps = getfabProps(state, fabType);
  const adornments: AdornmentType[] = get(viewState.viewDefn, 'adornments', []);

  return {
    loaded,
    config: viewState.viewDefn,
    showCountLimit: !isNil(defns.subheader.countLimit),
    sortBy: state.subheader.sortBy,
    groupBy,
    subheaderViewDefns: defns.subheader,
    groupedStyles,
    summary, // Required for view component
    currentTab: state.perspective.activeTab,
    unmodifiedViewDefn: viewState.unmodifiedViewDefn,
    fab: fabProps,
    isFabDisabled: false,
    downloadLink: subheader?.downloadLink,
    viewDataState: viewState.viewDataState,
    adornments,
  };
}

function dispatchToProps(dispatch: AppThunkDispatch, ownProps: ColumnGroupedViewOwnProps): DispatchProps {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;

  return {
    onShowView() {
      dispatch(requestTenantConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [TopTYvsLYViewDefn, RollupDefn],
        })
        .then((resp) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          if (localConfig && localConfig.config) {
            resp[0] = localConfig.config;
          }
          dispatch(
            receiveTenantConfig({
              viewDefn: resp[0],
              calcViewDefn: resp[1],
              unmodifiedViewDefn,
            })
          );
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.topTyLy,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    showStylePane(_item: ContainerPayload) {
      // do nothing
    },
    onItemClicked(item: ContainerPayload) {
      dispatch(setRightContainerPayload(item));
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
        })
      );
    },
  };
}

const wrappedView = flow(() => ColumnGroupedView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, dispatchToProps)(wrappedView);

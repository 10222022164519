import React from 'react';
import { partial } from 'lodash/fp';
import { connect } from 'react-redux';

import { isEmpty, noop, flow, get, isNil, findIndex } from 'lodash';

import store, { AppState, AppThunkDispatch } from 'src/store';
import ColumnGroupedView, {
  StateProps as FloorsetComparisonStateProps,
  DispatchProps,
} from 'src/components/StandardCardView/ColumnGroupedView';
import container from 'src/ServiceContainer';
import { generateGroupBy } from 'src/pages/Hindsighting/StyleColorReview/TopTYvsLY/TopTYvsLY.container';
import { getCurrentFloorsetIndex, getProcessedData } from './FloorsetComparison.selectors';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';

import { ASSORTMENT } from 'src/utils/Domain/Constants';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { FloorsetDropdownProps, FloorsetDropdownItem, FloorsetComparisonOwnProps } from './FloorsetComparison.types';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { isViewDefnLoaded, TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { FabType, getfabProps, withFab } from 'src/components/higherOrder/withFab';
import { FavoriteListItemStorage } from 'src/components/Subheader/Favorites/Favorites.types';
import { receiveError, receiveTenantConfig, requestTenantConfig } from './FloorsetComparison.slice';
import { isDataLoaded } from 'src/services/pivotServiceCache';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { FloorsetComparisonViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { RollupDefn } from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { AnyAction, Dispatch } from 'redux';
import { updateTopMemberSelection } from 'src/components/Subheader/Subheader.slice';
import { parseFloorsetDropdownData } from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';

let onCustomElChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
function handleCustomElChange(dispatch: Dispatch<AnyAction>, event: React.ChangeEvent<HTMLSelectElement>) {
  const newValue = event.currentTarget.textContent;
  const { topMemberOptions } = store.getState().subheader;
  if (isNil(newValue) || isNil(topMemberOptions)) {
    return;
  }
  const valueIndex = findIndex(topMemberOptions, (option) => {
    return option.name === newValue;
  });
  const selection = topMemberOptions[valueIndex].id;
  setTimeout(() => {
    dispatch(updateTopMemberSelection(selection));
  });
}

export const FloorsetDropdown = (props: FloorsetDropdownProps) => {
  if (isEmpty(props.floorsets)) {
    return null;
  }

  return (
    <SubheaderDropdown
      label={'Floorsets'}
      options={props.floorsets}
      selection={props.selectedFloorsetIndex >= 0 ? props.selectedFloorsetIndex : 0} // default to first item
      handleChangeOnDropdown={onCustomElChange}
      dataQa={'floor-sets'}
    />
  );
};

function mapStateToProps(
  state: AppState,
  { defns, groupingInfo, fabType = FabType.none }: FloorsetComparisonOwnProps
): FloorsetComparisonStateProps {
  const { floorsetComparison: viewState } = state.pages.assortmentBuild;
  const fabProps = getfabProps(state, fabType);
  const groupedStyles = getProcessedData(state);
  const groupBy = generateGroupBy(groupingInfo.dataIndex);
  const { topMemberOptions } = state.subheader;
  const processedTopMemberOptions = topMemberOptions ? parseFloorsetDropdownData(topMemberOptions) : [];
  const topMemberSelectionIndex = getCurrentFloorsetIndex(state);
  const loaded = isViewDefnLoaded(viewState.viewDefnState) && isDataLoaded(viewState.viewDataState);
  const adornments: AdornmentType[] = get(viewState.viewDefn, 'adornments', []);

  return {
    loaded,
    config: viewState.viewDefn,
    showCountLimit: false,
    sortBy: state.subheader.sortBy,
    groupBy,
    subheaderViewDefns: defns.subheader,
    groupedStyles,
    summary: [], // Required for view component
    subheaderCustomEl: isEmpty(processedTopMemberOptions) ? (
      undefined
    ) : (
      <FloorsetDropdown floorsets={processedTopMemberOptions} selectedFloorsetIndex={topMemberSelectionIndex} />
    ),
    fab: fabProps,
    unmodifiedViewDefn: viewState.unmodifiedViewDefn,
    isFabDisabled: false,
    viewDataState: viewState.viewDataState,
    adornments,
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch, ownProps: FloorsetComparisonOwnProps): DispatchProps {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;

  // setup event handler with access to dispatch
  onCustomElChange = partial(handleCustomElChange, [dispatch]);

  return {
    onShowView() {
      dispatch(requestTenantConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [FloorsetComparisonViewDefn, RollupDefn],
        })
        .then((resp) => {
          const unmodifiedViewDefn = resp[0];
          const localConfig: FavoriteListItemStorage | undefined = getLocalConfig(
            defns.view[0],
            (resp as any)[defns.view.length],
            dispatch,
            unmodifiedViewDefn
          );
          if (localConfig && localConfig.config) {
            resp[0] = localConfig.config;
          }
          dispatch(
            receiveTenantConfig({
              viewDefn: resp[0],
              calcViewDefn: resp[1],
              unmodifiedViewDefn: unmodifiedViewDefn,
            })
          );
        })
        .catch((error) =>
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.floorsetComparison,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          )
        );
    },
    onConfigUpdate(config: TenantConfigViewData) {
      dispatch(
        receiveTenantConfig({
          viewDefn: config,
        })
      );
    },
    showStylePane: noop,
    onItemClicked: noop,
  };
}

const wrappedView = flow(() => ColumnGroupedView, withFab, makePopoverSensitive, makePrintSensitive)();

export default connect(mapStateToProps, mapDispatchToProps)(wrappedView);

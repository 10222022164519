import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import {
  inputIsNotNullOrUndefined,
  isScopeDefined,
  isSubheaderLoaded,
  topMemInWorklistSelected,
} from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  ColumnGroupedViewComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  fetchFloorsetComparisonData,
  fetchFloorsetsAndSelect,
  receiveTenantConfig,
  setGroupingInfo,
} from './FloorsetComparison.slice';
import {
  receiveScope,
  receiveScopeRefreshTrigger,
} from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { setActivePage, setActiveSubPage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { ListDataOptions } from 'src/worker/pivotWorker.types';
import { isNil, isEmpty } from 'lodash';
import { updateTopMemberSelection } from 'src/components/Subheader/Subheader.slice';

export const fetchFloorsetsOnPageLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScope.type,
      // the above action is only needed since it is potentially dispatched after
      // setActivePage is dispatched and floorset comparison data fetch
      // needs scope info that may not be ready yet.
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      setActivePage.type,
      setActiveSubPage.type,
      receiveTenantConfig.type
    ),
    map(() =>
      maybeGetComponentProps<ColumnGroupedViewComponent>(state$.value, ConfDefnComponentType.floorsetComparison)
    ),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ groupingInfo }) => {
      const productMember = state$.value.scope.scope.productMember;

      // if missing, skip data fetches
      if (isEmpty(productMember)) {
        return of();
      }

      return of(setGroupingInfo(groupingInfo), fetchFloorsetsAndSelect());
    })
  );
};

export const floorsetComparisonDataLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(updateTopMemberSelection.type),
    map(() =>
      maybeGetComponentProps<ColumnGroupedViewComponent>(state$.value, ConfDefnComponentType.floorsetComparison)
    ),
    filter(inputIsNotNullOrUndefined),
    filter(() => topMemInWorklistSelected(state$.value)),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    mergeMap(({ defns, groupingInfo }) => {
      const { model: modelDefn } = defns;

      const { topMemberSelection } = state$.value.subheader;
      const productMember = state$.value.scope.scope.productMember;

      const topMembers = !isNil(topMemberSelection) ? topMemberSelection : '';
      const options: ListDataOptions = { topMembers };

      // fetchFloorsetAndSelect requires product member
      // fetchFloorsetComparision requires top member value

      // if both are missing, skip data fetches
      if (isEmpty(productMember) && isEmpty(topMembers)) {
        return of();
      }

      // could possibly check for topMembers value here but not really necessary
      return of(setGroupingInfo(groupingInfo), fetchFloorsetComparisonData(modelDefn, options));
    })
  );
};

import React from 'react';
import {
  ColDef,
  ColGroupDef,
  ValueGetterParams,
  SuppressKeyboardEventParams,
  EditableCallbackParams,
  CellEditorSelectorResult,
  CellEditingStoppedEvent,
} from '@ag-grid-community/core';
import { GridReadyEvent } from '@ag-grid-community/core';
import { classes } from 'typestyle';
import _, { isEqual } from 'lodash';

import { CSSProperties } from 'react';
import { GridApi } from '@ag-grid-community/core';
import '@ag-grid-enterprise/core';
import styles from '../FlowSheet/FlowSheetGrid.styles';
import { TimeEntry } from 'src/worker/pivotWorker.types';
import Renderer from 'src/utils/Domain/Renderer';
import {
  EDITABLE_BG_COLOR,
  FlowSheetCellRendererParams,
  FlowSheetCellValueChangedEvent,
  FrameworkComponents,
  getId,
  RowData,
} from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.types';
import { PriceEvent } from 'src/pages/AssortmentBuild/Pricing/PricingOverTime.slice';

import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import AgGridHeaderBreakClass from 'src/utils/Style/AgGridThemeBreakHeaders';
import { isEmpty, isNil } from 'lodash';
import { BLOCK_ENTER_EDITORS, POPOVER_BLOCK_CODES } from 'src/utils/Domain/Constants';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor/IntegerEditor';

import { BigNumber, evaluate, round } from 'mathjs';
import { executeCalculation, importDefaultFunctions } from 'src/utils/LibraryUtils/MathUtils';
import {
  ColumnApi,
  GetMainMenuItemsParams,
  ICellEditorParams,
  IRowNode,
  ProcessCellForExportParams,
} from '@ag-grid-community/core';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { getGridRowHeight, sortingComparator } from '../FlowSheet/FlowSheet.utils';
import { FLOWSHEET_HEADER_HEIGHT } from '../FlowSheet/FlowSheetGrid';
import { multiHeaderDecorate } from 'src/common-ui/components/DataGrid/NestedHeader';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import coalesce from 'src/utils/Functions/Coalesce';

import { create, all } from 'mathjs';
import * as globalMath from 'mathjs';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import Adornments from 'src/components/Adornments/Adornments';
type BaseOrNull = string | number | boolean | null;
interface UpdateCalculatedFieldsArgs {
  groupId: string;
  field: string;
  rowData: RowData[];
  path: string[];
}
interface CellRendererProps extends FlowSheetCellRendererParams {
  editable: () => boolean;
}
class FlowSheetCellRenderer extends React.Component<CellRendererProps> {
  constructor(props: CellRendererProps) {
    super(props);
  }
  render() {
    const colDef = this.props.column?.getColDef();
    const data = this.props.data;
    const field = colDef?.field || '';
    const measureId = data.measureId;

    const isEditable = !!data.extraData[field + '_editable'] && !!data.editable && this.props.editable();
    const isVisiblyEditable = isEditable ? EDITABLE_BG_COLOR : undefined;
    const valueStyle: CSSProperties = {
      backgroundColor: isVisiblyEditable,
      width: '100%',
      height: '100%',
      whiteSpace: 'pre',

      // Fixes for centering columns
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    const renderFn = Renderer[data.renderer || ''] || ((a: string | number | undefined) => a);

    if (data.extraData.calculation && this.props.value) {
      return <div style={valueStyle}>{renderFn(this.props.value)}</div>;
    }

    if (data.path.length === 2 && measureId) {
      const value = this.props.column && this.props.api.getValue(this.props.column, this.props.node);

      const valueIsEmpty = value === null || typeof value === 'undefined' || String(value).trim() === '';
      const display = valueIsEmpty ? '' : renderFn(value);
      return <div style={valueStyle}>{display}</div>;
    }

    return null;
  }
}

export class HeaderCellRenderer extends React.Component<FlowSheetCellRendererParams> {
  constructor(props: FlowSheetCellRendererParams) {
    super(props);
  }

  onToggle = () => {
    const { data, onHeaderClick } = this.props;

    if (onHeaderClick) {
      onHeaderClick({
        id: data.id,
        parentId: data.additionalId,
      });
    }
  };

  render() {
    const params = this.props;
    const data = params.data;
    let textName = data.name || data.measureId;
    const level = data.path.length;
    const adornments: AdornmentType[] = this.props.getAdornments();

    if (level === 1) {
      textName = data.name || data.groupId;
      if (adornments.length) {
        return (
          <div style={{ display: 'flex' }} className={`${styles.headerName}`}>
            <Adornments adornments={adornments} productId={data.id} />
            <span className={`level-${level}`} onClick={this.onToggle}>
              {textName}
            </span>
          </div>
        );
      }
      return (
        <span className={`${styles.headerName} level-${level}`} onClick={this.onToggle}>
          {textName}
        </span>
      );
    }
    const configClasses = data.classes?.join(' ');
    const classes = `${styles.headerName} level-${level} ${configClasses}`;

    return <span className={classes}>{textName}</span>;
  }
}

// these props are used when PricingGrid component is directly accessed (WorklistViewContainer)
export interface ComponentTypeGridDispatchProps {
  onRenderGrid?: () => void;
  onCompanionItemChange?: () => void;
  submitPayload?: (payload: GranularEditPayloadItem[], shouldRefresh: boolean) => Promise<void>;
  updateAssortmentPlan?: () => void;
  onCleanup?: () => void;
  onRefetchData?: () => void;
  onUpdateConfig?: (config: any) => void;
}

export interface PricingGridProps extends ComponentTypeGridDispatchProps {
  loaded: boolean;
  itemId?: string;
  rowData?: RowData[];
  rowHeight?: number;
  groupRowHeight?: number;
  timeEntries: TimeEntry[];
  anchorField?: string;
  events?: PriceEvent[];
  editable: boolean;
  columnWidth?: number;
  showUndoBtn?: boolean;
  onValueChange?: (payload: GranularEditPayloadItem[]) => void;
  onItemClicked?: (item: Record<string, any>, eventTarget?: HTMLElement) => void;
  adornments: AdornmentType[];
}

export interface PricingGridState {
  columnDefs?: (ColDef | ColGroupDef)[];
  rowData: RowData[];
  anchorField?: string;
  context: {
    componentParent: PricingGrid;
  };
  frameworkComponents: FrameworkComponents;
}
export class PricingGrid extends React.Component<PricingGridProps, PricingGridState> {
  private _isUnMounted = false;
  protected gridApi!: GridApi;
  protected columnApi!: ColumnApi;
  protected GRID_EXPORT_NAME = 'PricingGrid-Export';
  math = create(all) as globalMath.MathJsStatic;
  bigNumMath: globalMath.MathJsStatic;

  constructor(props: PricingGridProps) {
    super(props);
    importDefaultFunctions(this.math);
    this.bigNumMath = create(all, {
      number: 'BigNumber',
    }) as globalMath.MathJsStatic;

    const anchorField = props.anchorField || '';
    const columnDefs = this.decorateWithStyleClass(this.props.timeEntries, 0, anchorField);
    this.state = {
      anchorField,
      columnDefs: columnDefs,
      context: { componentParent: this },
      rowData: [],
      frameworkComponents: {
        flowSheetRenderer: FlowSheetCellRenderer,
        headerCellRenderer: HeaderCellRenderer,
        validValuesEditor: ValidValuesEditor,
        integerEditor: IntegerEditor,
      },
    };
  }

  componentDidMount() {
    if (this.props.onRenderGrid) {
      this.props.onRenderGrid();
    }
  }

  updateColumnDefs = () => {
    const columnDefs = this.decorateWithStyleClass(this.props.timeEntries, 0, this.props.anchorField || '');
    this.setState(
      {
        columnDefs,
      },
      () => {
        if (this.gridApi != null && !this._isUnMounted) {
          this.gridApi.setColumnDefs([]);
          this.gridApi.setColumnDefs(multiHeaderDecorate(this.state.columnDefs || []));
          this.gridApi.refreshCells({ force: true });
          // We always scroll back to week start when data/columns change (INT-3249)
          this.scrollAnchorIntoView(this.gridApi, this.columnApi);
        }
      }
    );
  };

  componentDidUpdate(prevProps: PricingGridProps) {
    if (
      this.props.onCompanionItemChange &&
      !isEmpty(prevProps.itemId) &&
      !isEqual(prevProps.itemId, this.props.itemId)
    ) {
      this.props.onCompanionItemChange();
    }
    if (
      this.props.timeEntries.length > 0 &&
      (!isEqual(prevProps.timeEntries.length, this.props.timeEntries.length) || isEmpty(this.state.columnDefs))
    ) {
      this.updateColumnDefs();
    }
    if (this.props.rowData !== prevProps.rowData && this.props.rowData) {
      this.setState({
        rowData: this.props.rowData,
      });
    }
    if (!isEqual(prevProps.editable, this.props.editable) && this.gridApi) {
      this.gridApi.refreshCells({ force: true });
    }
  }

  componentWillUnmount() {
    this._isUnMounted = true;

    if (this.props.onCleanup) {
      this.props.onCleanup();
    }
  }

  getDiff(): GranularEditPayloadItem[] {
    const before = this.props.rowData || [];
    const after = this.state.rowData;
    if (before.length !== after.length) {
      throw new Error('Size Mismatch for diff');
    }
    const ln = before.length;
    const weeks = _.flatMap(this.props.timeEntries, (month) => month.children);

    const groups = {};
    weeks.forEach((week) => {
      for (let i = 0; i < ln; ++i) {
        const rowBefore = before[i];
        const rowAfter = after[i];

        // Skip things not at level 2
        if (rowBefore.path.length !== 2) {
          continue;
        }

        if (rowBefore.groupId !== rowAfter.groupId) {
          throw new Error('Data Key Mismatch');
        }
        const measureId = rowBefore.measureId;
        if (measureId !== rowAfter.measureId) {
          throw new Error('Data Key Mismatch');
        }

        // can't work with undefined measures
        if (!measureId) {
          continue;
        }

        const d1 = rowBefore.extraData[week.id];
        const d2 = rowAfter.extraData[week.id];

        // same data - do nothing
        if (d1 === d2) {
          continue;
        }

        let groupItem = groups[rowBefore.groupId];
        if (!groupItem) {
          groupItem = groups[rowBefore.groupId] = {};
        }

        let weekItem = groupItem[week.id];
        if (!weekItem) {
          weekItem = groupItem[week.id] = {};
        }

        let value: string | number | boolean | null = rowAfter.extraData[week.id];
        if (typeof value === 'undefined' || value === '') {
          value = null;
        }
        weekItem[measureId] = value;
      }
    });

    const items: GranularEditPayloadItem[] = [];
    // need to reshape data here
    Object.keys(groups).forEach((groupId) => {
      const group = groups[groupId];
      Object.keys(group).forEach((weekId) => {
        const weekItem = {
          coordinates: {
            product: groupId,
            time: weekId,
          },
          ...group[weekId],
        };
        items.push(weekItem);
      });
    });
    return items;
  }

  onGridReady = (event: GridReadyEvent) => {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.updateColumnDefs();
  };

  scrollAnchorIntoView(gridApi: GridApi, columnApi: ColumnApi) {
    const anchorField = !isNil(columnApi) ? columnApi.getColumn(this.state.anchorField!) : null;

    if (isNil(anchorField)) {
      return;
    }

    // find index of anchor field to determine next predetermined number of columns to ensureColumnVisible on
    const allColumns = columnApi.getColumns();
    const anchorCol = allColumns?.find((column) => column.getColId() === this.state.anchorField);

    if (anchorCol && !isNil(allColumns)) gridApi.ensureColumnVisible(allColumns[allColumns.length - 1]);
    if (anchorCol) gridApi.ensureColumnVisible(anchorCol);
  }

  decorateWithStyleClass(items: TimeEntry[], depth = 0, anchorField: string): ColDef[] {
    const firstIndex = 0;
    const lastIndex = items.length - 1;
    const newItems: ColDef[] = items.map((item, i) => {
      const classList = [`depth-${depth}-item-${i}`];

      if (item.id === anchorField) {
        classList.push('anchor-field');
      }

      if (firstIndex === i) {
        classList.push(`depth-${depth}-first`);
      }
      if (lastIndex === i) {
        classList.push(`depth-${depth}-last`);
      }

      return {
        headerName: item.description || item.id,
        field: item.id,
        cellClass: () => classList,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          if (params.data && params.data.type && BLOCK_ENTER_EDITORS.includes(params.data.type)) {
            if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
              return true;
            }
          }
          return false;
        },
        valueGetter: (params: ValueGetterParams) => {
          const measureId = params.data.measureId;
          const groupId = params.data.groupId;
          const field = params.colDef.field || '';
          let value = params.data.extraData[field];
          const rowData = this.state.rowData;
          const rowDataIndex = rowData.findIndex((data) => {
            if (data.id === params.data.id) {
              return true;
            }
            return false;
          });

          const getDataFromKey = (key: string) => {
            const returnObj = {
              rowNodeFound: false,
              data: undefined,
            };
            if (isNil(params.api)) {
              return returnObj;
            }
            const choiceId = params.node?.data.groupId ?? '';
            const rowNode = params.api.getRowNode(choiceId + `_${key}`);
            if (rowNode) {
              // empty string is equivalent to a "no path" for lodash get.
              // Falls through to null value when coldef/field not available. - you're welcome denver
              const week = params.colDef?.field || '';
              const val = params.api.getValue(week, rowNode);
              const weekData = coalesce(val, null);
              returnObj.rowNodeFound = true;
              returnObj.data = weekData;
            }
            return returnObj;
          };
          // Hardcoded eventprice reserved calculation
          if (measureId === 'eventprice_reserved') {
            const allMeasures = rowData.filter((rowItem: RowData) => {
              return rowItem.groupId === groupId && (rowItem.measureId || '').length > 1;
            });
            const valueObject = allMeasures.reduce((a: Record<string, any>, b: RowData) => {
              a[b.measureId || ''] = b.extraData[field];
              return a;
            }, {});

            const event =
              this.props.events &&
              this.props.events.find((e: PriceEvent) => {
                const valueObjEvent = isEmpty(valueObject['event']) ? 'none' : valueObject['event'];
                return e.ccpriceevent === valueObjEvent;
              });
            if (event) {
              try {
                // We use Big Number here to avoid some floating point errors as a result of the event calculation.
                // It's a dollar amount, so we're rounding so exports make sense
                value = round(executeCalculation(this.math, event.expression, getDataFromKey), 2);
                if (this.bigNumMath.typeOf(value) === 'BigNumber') {
                  value = (value as BigNumber).toNumber();
                }
              } catch (e) {
                console.error(event.expression); // eslint-disable-line no-console
              }
            }
          }

          if (rowData[rowDataIndex] && rowData[rowDataIndex].calculation) {
            const newValue = executeCalculation(this.math, rowData[rowDataIndex].calculation as string, getDataFromKey);
            return newValue;
          } else {
            return value;
          }
        },
      };
    });

    items.forEach((item: TimeEntry, i) => {
      if (item.children && item.children.length) {
        const colDef = newItems[i] as ColGroupDef;
        colDef.marryChildren = true;
        colDef.children = this.decorateWithStyleClass(item.children, depth + 1, anchorField);
      }
    });

    return newItems;
  }

  cellEditorSelector = (params: ICellEditorParams): CellEditorSelectorResult => {
    const colDef = params.colDef;
    if (params.data.measureId && colDef) {
      if (params.data.measureId === 'event') {
        return {
          component: 'validValuesEditor',
          params: {
            dataConfig: {
              url: '/api/attribute/validValues/one?appName=Assortment&ids=ccpriceevent',
              params: [],
            },
            dataQa: 'select-price-grid',
          },
        };
      }

      switch ((params.data as RowData).inputType) {
        case 'integer':
          const rowData = this.state.rowData;
          const rowDataIndex = rowData.findIndex((data) => {
            if (data.id === params.data.id) {
              return true;
            }
            return false;
          });
          const percent = params.data.renderer === 'percent';
          const inputParams = rowData[rowDataIndex].inputParams;
          return {
            component: 'integerEditor',
            params: {
              passedInt: colDef.field && params.data.extraData[colDef.field],
              inputParams: { ...inputParams, percent },
              regularPosition: true,
            },
          };
        default:
      }

      return {
        component: 'agTextCellEditor',
      };
    }

    return (null as unknown) as CellEditorSelectorResult;
  };

  isGroupEditable(params: ICellEditorParams): boolean {
    const data = params.data;
    return data.path.length === 1;
  }

  isEditable = (params: EditableCallbackParams): boolean => {
    const data = params.data;
    const colDef = params.colDef;
    if (data && colDef) {
      const field = colDef.field || '';
      const isEditable = !!data.extraData[field + '_editable'] && !!data.editable && this.props.editable;
      return data.path.length > 0 && !!data.measureId && isEditable;
    }
    return false;
  };

  updateCell(groupId: string, measureId: string, field: string, newValue: string | number | boolean) {
    const rowData = this.state.rowData;
    if (measureId && groupId) {
      const comparator = (tmp: RowData) => tmp.groupId === groupId && tmp.measureId === measureId;
      const index = _.findIndex(rowData, comparator);
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = newValue;
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;
        this.setState({ rowData: newRowData }, () => {
          this.gridApi.applyTransaction({
            update: [measureData],
          });
          this.handleValueChange();

          const rowNode = this.gridApi.getRowNode(getId(groupId, measureId));
          const column = this.columnApi.getColumn(field);
          this.gridApi.refreshCells({
            force: true,
            columns: !isNil(column) ? [column] : [],
            rowNodes: rowNode ? [rowNode] : [],
          });
        });
      }
    }
  }

  refreshField(field: string) {
    const column = this.columnApi.getColumn(field);

    this.gridApi.refreshCells({
      force: true,
      columns: !isNil(column) ? [column] : [],
    });
  }

  updateCalculatedField = (
    measureId: string,
    extraData: { [s: string]: BaseOrNull },
    { groupId, field, rowData }: UpdateCalculatedFieldsArgs
  ) => {
    function getMeasure(m: string): number {
      const rowEntry = rowData.find((item: RowData) => item.groupId === groupId && item.measureId === m);
      if (!rowEntry) {
        return NaN;
      }
      return parseFloat(rowEntry.extraData[field] as string);
    }

    let value = extraData[field];
    if (measureId === 'eff_aur') {
      const allMeasures = rowData.filter((item: RowData) => {
        return item.groupId === groupId && (item.measureId || '').length > 1;
      });
      const valueObject = allMeasures.reduce((a: Record<string, any>, b: RowData) => {
        a[b.measureId || ''] = b.extraData[field];
        return a;
      }, {});
      const event =
        this.props.events && this.props.events.find((e: PriceEvent) => e.ccpriceevent === valueObject['event']);

      if (event) {
        try {
          value = evaluate(event.expression, valueObject);
        } catch (e) {
          console.error(event.expression); // eslint-disable-line no-console
        }
      }
      const corpexcl = getMeasure('corpexcl');
      const cccurp = getMeasure('cccurp');
      const corpaddoff = getMeasure('corpaddoff');
      const eo = getMeasure('eo');
      const addoff = getMeasure('addoff');
      const ccdiscountpct = getMeasure('ccdiscountpct');

      const CEX = isNaN(corpexcl) ? 0 : corpexcl;
      const A = cccurp * (1 - CEX);
      const AEO = isNaN(addoff) ? 0 : addoff;
      const CEAO = isNaN(corpaddoff) ? 0 : corpaddoff;
      const EventPrice = _.isNaN(value) ? cccurp : value;
      const FinalEventPrice = isNaN(eo) ? EventPrice : eo;
      const B = (FinalEventPrice as number) * (1 - AEO) * (1 - CEAO);
      const washpct = isNaN(ccdiscountpct) ? 0 : ccdiscountpct;

      value = Math.min(A, B) * (1 - washpct);
    }
    return value;
  };

  updateCalculatedFields = (args: UpdateCalculatedFieldsArgs) => {
    const calcMeasures = ['eff_aur'];
    // TODO: get index from measure + modifed row?
    return _.map(calcMeasures, (measureId) => {
      const index = _.findIndex(args.rowData, (tmp: RowData) => _.isEqual(tmp.path, args.path.concat(measureId)));
      const measureData = _.cloneDeep(args.rowData[index]);
      measureData.extraData[args.field] =
        this.updateCalculatedField(measureId, measureData.extraData, args) || measureData.extraData[args.field];
      args.rowData[index] = measureData;
      return measureData;
    });
  };

  onCellValueChanged = (event: FlowSheetCellValueChangedEvent) => {
    const data = event.data;
    const rowData = this.state.rowData;
    const field = event.colDef.field || '';
    if (rowData && data.measureId && data.groupId) {
      const index = _.findIndex(rowData, (tmp: RowData) => _.isEqual(tmp.path, data.path));
      if (index >= 0) {
        const measureData = _.cloneDeep(rowData[index]);
        measureData.extraData[field] = data[field];
        const newRowData = rowData.slice(0);
        newRowData[index] = measureData;
        const calcedUpdates = this.updateCalculatedFields({
          groupId: data.groupId,
          field,
          rowData: newRowData,
          path: data.path.slice(0, -1),
        });
        this.setState({ rowData: newRowData }, () => {
          this.gridApi.applyTransaction({
            update: [measureData].concat(calcedUpdates),
          });

          this.handleValueChange();
          this.refreshField(field);
        });
      }
    }
  };

  handleValueChange = () => {
    const diff = this.getDiff();
    if (!diff.length) {
      return;
    }

    if (this.props.onValueChange) {
      this.props.onValueChange(diff);
    } else if (this.props.submitPayload) {
      this.props.submitPayload(diff, false);
    }
  };

  doesExternalFilterPass(node: IRowNode): boolean {
    return !node.data.hidden;
  }

  getColumnMenuItems = (params: GetMainMenuItemsParams) => {
    return params.defaultItems.filter((item: string) => item !== 'autoSizeAll');
  };

  renderPopover = (params: { id: string | undefined; parentId: string | undefined }) => {
    const { onItemClicked } = this.props;
    if (onItemClicked) {
      onItemClicked(params);
    }
  };

  getAdornments = () => {
    return this.props.adornments;
  };

  render() {
    const frameworkComponents = this.state.frameworkComponents;
    return (
      <React.Fragment>
        <ExtendedDataGrid
          className={classes(styles.dataGridStyle, AgGridHeaderBreakClass, 'double-header')}
          loaded={this.props.loaded}
          data={this.state.rowData}
          singleClickEdit={true}
          columnDefs={[]} // we interact directly with api for columns here
          frameworkComponents={frameworkComponents}
          onGridReady={this.onGridReady}
          exportOptions={{
            fileName: this.GRID_EXPORT_NAME,
            processCellOverride: ({ node, column }: ProcessCellForExportParams) => {
              if (node && node.parent) {
                if (node.parent.id === 'ROOT_NODE_ID' && column.getId() === 'ag-Grid-AutoColumn') {
                  const returnName: string = node.data.name;
                  const [description] = returnName.split(':');
                  // verify description is available and if not display error in cell text since data is not present
                  const descriptionFound = !isEmpty(description);
                  return descriptionFound ? returnName : `[ERROR: NO DESCRIPTION DATA] - ${returnName}`;
                }

                if (column.isPinnedLeft()) {
                  return node.data.name;
                }
              }
            },
          }}
          extraAgGridProps={{
            // 'excludeChildrenWhenTreeDataFiltering' makes children of groups have to go through the external filter call separately
            // see SUP-24
            excludeChildrenWhenTreeDataFiltering: true,
            defaultColDef: {
              editable: this.isEditable,
              cellEditorSelector: this.cellEditorSelector,
              cellStyle: {
                padding: 0,
                margin: 0,
                height: 'inherit',
              },
              cellRenderer: 'flowSheetRenderer',
              cellRendererParams: {
                editable: () => this.props.editable,
              },
              width: this.props.columnWidth ? this.props.columnWidth : 130,
              lockPosition: true,
              resizable: true,
              sortable: false
            },
            // using getRowHeight instead oof just setting rowHeight, to support configurable group row height
            getRowHeight: (params: { data: any }) =>
              getGridRowHeight(params.data, this.props.rowHeight, this.props.groupRowHeight),
            headerHeight: FLOWSHEET_HEADER_HEIGHT,
            onCellValueChanged: this.onCellValueChanged,
            getRowId: (params) => params.data.id,
            context: this.state.context,
            treeData: true,
            groupDefaultExpanded: 1,
            animateRows: true,
            getDataPath: (data: RowData) => data.path,
            suppressScrollOnNewData: true,
            stopEditingWhenCellsLoseFocus: true,
            isExternalFilterPresent: () => true,
            doesExternalFilterPass: this.doesExternalFilterPass,
            getMainMenuItems: this.getColumnMenuItems,
            autoGroupColumnDef: {
              headerName: 'Name',
              field: 'name',
              width: 300,
              pinned: 'left',
              lockPinned: true,
              wrapText: true,
              sortable: true,
              cellRendererParams: {
                suppressCount: true,
                innerRenderer: 'headerCellRenderer',
                onHeaderClick: this.renderPopover,
                getAdornments: this.getAdornments,
              },
              cellStyle: {
                lineHeight: 'unset',
              },
              resizable: true,
              comparator: sortingComparator,
            },
            processCellFromClipboard: (params) => {
              const nodeData = params.node?.data;
              const inputType = nodeData.inputType;

              // If input type is not number, accept the paste as is
              if (inputType !== 'integer') {
                return params.value;
              }

              // If input type is number, process the value
              if (inputType === 'integer') {
                // Strip non-digit and non-period characters from the pasted value
                const cleanedValue = params.value.replace(/[^0-9.]/g, '');
                let parsedValue = parseFloat(cleanedValue);

                // If the parsed value is not a number, return null
                if (isNaN(parsedValue)) {
                  return null;
                }

                // Check if the original value ends with a percent symbol or percent renderer (percents can copy two different ways)
                if (params.value.trim().endsWith('%')) {
                  parsedValue = parsedValue / 100;
                }

                return parsedValue;
              }

              // return the original value if no conditions are met
              return params.value;
            },
          }}
        />
      </React.Fragment>
    );
  }
}

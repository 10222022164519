import { reduce, partial, noop, isNil, get, isArray, concat, isString } from 'lodash';
import { mapValues } from 'lodash/fp';
import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { InputCharacterWhitelist } from 'src/common-ui/components/Inputs/InputGeneric/InputGeneric';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getAvailableColors } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { API_URL_PREFIX } from 'src/utils/Domain/Constants';
import { StylePreviewData } from 'src/components/StylePreview/StylePreview.types';
import serviceContainer from 'src/ServiceContainer';
import { ConfigApiV2 } from 'src/services/configuration/codecs/confdefnView';

type HasParams = {
  params?: {
    [s: string]: string | string[];
  };
};

// FIXME: These processApiParams should just call processApiParamsValueGet
// with the getValue built from what it currently does. I don't want to mess with it
// without time though. - <3 Mark

export function processApiParamsValueGet<T extends HasParams>(api: T, getValue: (k: string) => string | null): T {
  return {
    ...api,
    params: mapValues((value) => {
      try {
        return JSON.parse(value as string);
      } catch (e) {
        if (isString(value)) {
          if (value.match(/(")(.*)(")/)) {
            return value.replace(/(")(.*)(")/, '$2');
          }
        }
        const valueArr = isArray(value) ? value : [value];
        return reduce(
          valueArr,
          (acc, v) => {
            const val = getValue(v);
            if (!isNil(val)) {
              return concat(acc, val);
            } else {
              return acc;
            }
          },
          [] as string[]
        );
      }
    }, api.params),
  };
}

export function processApiParams<T extends HasParams>(api: T, scope: Record<string, any>): T {
  return {
    ...api,
    params: mapValues((value) => {
      try {
        return JSON.parse(value as string);
      } catch (e) {
        if (isString(value)) {
          if (value.match(/(")(.*)(")/)) {
            return value.replace(/(")(.*)(")/, '$2');
          }
        }
        if (scope) {
          const valueArr = isArray(value) ? value : [value];
          return reduce(
            valueArr,
            (acc, v) => {
              if (!isNil(scope[v])) {
                return concat(acc, scope[v]);
              } else if (!isNil(scope.data) && !isNil(scope.data[v])) {
                return concat(acc, scope.data[v]);
              } else {
                return acc;
              }
            },
            [] as string[]
          );
        }

        return '';
      }
    }, api.params),
  };
}

export function getClientHandler(clientHandler: string, params: BasicPivotItem[]) {
  switch (clientHandler) {
    case 'getAvailableColors':
      return partial(getAvailableColors, params);
    default:
      return noop;
  }
}

export function getUrl(apiConfig: ClientDataApi) {
  return reduce(
    apiConfig.params,
    (url: string, value: string, key: string) => {
      const encodedValue = encodeURIComponent(value);
      return url.replace(`:${key}`, encodedValue);
    },
    apiConfig.url
  );
}

export function getViewProm(uiApiConfig: ConfigApiV2) {
  return serviceContainer.tenantConfigClient
    .getTenantViewDefn<any>({
      defnId: uiApiConfig.defnId || '',
    })
    .then((resp) => {
      return resp;
    });
}

export function getSwatchUrl(colorid: string) {
  const encodedColorId: string = encodeURIComponent(colorid);
  return encodeURI(`${API_URL_PREFIX}/api/swatch/bg?attributeId=CCColor&value=`) + encodedColorId;
}

export function generateStylePreview(style: ListViewable | undefined): StylePreviewData {
  if (isNil(style)) {
    return {
      id: '',
      description: '',
      starRating: 0,
      imgUri: '',
      style: '',
    };
  }

  const { id, name, stars, imageUri } = style;
  return {
    id,
    description: name,
    starRating: stars || 0,
    imgUri: imageUri || '',
    style: id,
  };
}

export function getWhitelistType(type: string) {
  return get(InputCharacterWhitelist, type, InputCharacterWhitelist.alphaNumeric);
}

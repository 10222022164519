import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IRowNode, IHeaderParams } from '@ag-grid-community/core';
import { CustomHeaderProps } from '@ag-grid-community/react';
import { ValidSizesEditorStyles as styles } from './ValidSizesEditor';
import { Tooltip, Popper } from '@material-ui/core';
import { EditableGridHeaderEditorBaseProps } from './TextValidationHeaderEditor';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { getValidValues } from '../StyleEditSection.client';
import { getUrl } from '../../StyleEdit.utils';
import { first, isEmpty, isNil } from 'lodash';
import { classes, style } from 'typestyle';
import { selectedItemsOptionsMatch } from './ValidValuesHeaderEditor';
import {
  MISMATCHED_OPTIONS_TEXT,
  ROW_SELECTION_REQUIRED_TEXT,
  ROW_SELECTIONS_UPDATING_TEXT,
} from 'src/utils/Domain/Constants';
import ServiceContainer from 'src/ServiceContainer';
import massEditstyles from 'src/components/MassEdit/MassEdit.styles';
import { isNotGroupNode } from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';

const validSizesHeaderInput = style({
  textAlign: 'center',
  caretColor: 'transparent',
  cursor: 'pointer',
});

interface ValidSizesHeaderEditorParams extends IHeaderParams, CustomHeaderProps, EditableGridHeaderEditorBaseProps {
  dataConfig?: ClientDataApi;
  options?: string[];
  selectedOptions: string[];
  cursorStyle?: string;
  dataIndex: string;
  getDataConfig: (row: IRowNode, configuredApi: ClientDataApi) => ClientDataApi;
  unprocessedDataConfig: ClientDataApi;
}

// eslint-disable-next-line react/display-name
export const ValidSizesHeaderEditor = (props: ValidSizesHeaderEditorParams) => {
  const { column, unprocessedDataConfig, getDataConfig, getSelectedItems, getCellsUpdating, onApplyEdit } = props;
  const logger = ServiceContainer.loggingService;
  const popperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [headerSelections, setHeaderSelections] = useState<string[]>([]);
  const [selectedItemsDataConfigs, setSelectedItemsDataConfigs] = useState<ClientDataApi[]>([]);
  const [dataConfigParamsMatch, setDataConfigParamsMatch] = useState<boolean>(false);
  const [validSizes, setValidSizes] = useState<string[]>([]);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);

  // Set anchor element on click, which will trigger the popperOpen
  const handleButtonClick = () => {
    setPopperOpen(true);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setPopperOpen(false);
        const items = getSelectedItems().filter(isNotGroupNode);
        const selections = [...headerSelections]; // Use ref to get the latest selections
        onApplyEdit(selections, items, column.getColDef());
      }
    },
    [column, headerSelections, getSelectedItems, onApplyEdit]
  );
  const handleClickOutsideRef = useRef(handleClickOutside);

  useEffect(() => {
    handleClickOutsideRef.current = handleClickOutside;
  }, [handleClickOutside]);

  useEffect(() => {
    const selectedDataConfigs = getSelectedItems().map((si) => getDataConfig(si, unprocessedDataConfig));
    const paramsMatch = selectedItemsOptionsMatch(selectedDataConfigs);

    setSelectedItemsDataConfigs(selectedDataConfigs);
    setDataConfigParamsMatch(paramsMatch);
  }, [getDataConfig, getSelectedItems, unprocessedDataConfig]);

  useEffect(() => {
    if (isEmpty(selectedItemsDataConfigs) || !dataConfigParamsMatch) {
      return;
    }

    const dataApi = first(selectedItemsDataConfigs);
    const url = !isNil(dataApi) ? getUrl(dataApi) : '';

    getValidValues(url, false, false, false)
      .then((validValues: { value: string }[]) => {
        setValidSizes(validValues.map((i) => i.value));
        setHeaderSelections(validValues.map((i) => i.value));
      })
      .catch((err) => logger.error(err));
  }, [selectedItemsDataConfigs, dataConfigParamsMatch, logger]);

  const onClickOption = useCallback(
    (optionClicked: string) => {
      const options = validSizes;
      let newSelections = [...headerSelections];
      if (options && options.length > 0) {
        newSelections = newSelections.filter((selection) => options.indexOf(selection) !== -1);
      }
      if (newSelections.indexOf(optionClicked) === -1) {
        newSelections.push(optionClicked);
      } else {
        newSelections = newSelections.filter((selection) => selection !== optionClicked);
      }
      setHeaderSelections(newSelections);
    },
    [headerSelections, validSizes]
  );

  // Handle mousedown event so that click away applies
  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      handleClickOutsideRef.current(e);
    };
    if (popperOpen) {
      document.addEventListener('mousedown', clickHandler);
    }
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [popperOpen]);
  const selections = headerSelections;
  const noItems = getSelectedItems().length === 0 || !dataConfigParamsMatch;

  const isUpdating = getCellsUpdating().length > 0;
  let title = '';
  if (noItems) {
    title = ROW_SELECTION_REQUIRED_TEXT;
  } else if (isUpdating) {
    title = ROW_SELECTIONS_UPDATING_TEXT;
  } else if (!dataConfigParamsMatch) {
    title = MISMATCHED_OPTIONS_TEXT;
  }

  return (
    <>
      <Tooltip title={title}>
        <input
          ref={inputRef}
          type="text"
          className={classes(massEditstyles.massEditInput, validSizesHeaderInput)}
          disabled={noItems}
          placeholder="Select Sizes"
          onClick={handleButtonClick}
        />
      </Tooltip>
      <Popper
        id={popperOpen ? 'transitions-popper' : undefined}
        open={popperOpen}
        anchorEl={inputRef.current}
        ref={popperRef}
        transition
      >
        <div ref={popperRef} className={styles.validsizesContainer}>
          {validSizes.map((option) => {
            const className =
              selections.indexOf(option) !== -1 ? `${styles.validsize}` : `${styles.validsize} ${styles.unchecked}`;
            return (
              <div
                className={classes(className)}
                style={{ cursor: props.cursorStyle ?? 'pointer' }}
                key={option}
                onClick={() => onClickOption(option)}
              >
                {option}
              </div>
            );
          })}
        </div>
      </Popper>
    </>
  );
};

import { style } from 'typestyle';
import { highlight, faintBorderColor, mediumBorderColor, fadedNeutral, primaryTextColor } from '../../theme';

const FiltersView = style({
  overflow: 'auto',
  position: 'relative',
  width: '100%',
  maxWidth: '400px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '20px',
  $nest: {
    '.tab-clear': {
      marginTop: 9,
    },
    '.main-action-container': {
      marginBottom: '1rem',
    },
    'button.apply': {
      backgroundColor: highlight,
    },
    'button.filter-button': {
      alignItems: 'baseline',
      backgroundColor: mediumBorderColor,
      display: 'flex',
      cursor: 'not-allowed',
      $nest: {
        '&.active': {
          backgroundColor: highlight,
          cursor: 'pointer',
        },
      },
    },
    'button.clear': {
      float: 'left',
    },
    'button.submit': {
      float: 'right',
    },
    'span.select-all': {
      fontStyle: 'italic',
      color: highlight,
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      padding: '0.4rem 1.2rem',
      color: 'white',
      $nest: {
        '> i': {
          marginRight: 5,
        },
      },
    },
    '.search': {
      display: 'flex',
      justifyContent: 'space-around',
      flex: 1,
      marginRight: '0.5rem',
      alignItems: 'center',
      padding: '0.2rem 0.5rem',
      border: '1px solid ' + mediumBorderColor,
      borderRadius: 4,
      fontSize: '0.8rem',
      color: fadedNeutral,
    },
    '.title-bar': {
      display: 'flex',
      alignItems: 'center',
      $nest: {
        '.title': {
          fontSize: '1.2rem',
          color: primaryTextColor,
          textTransform: 'uppercase',
        },
      },
    },
    '.filter-section': {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      maxHeight: '300px',
      height: '100%',
      marginTop: '10px',
      overflow: 'hidden',
      $nest: {
        '&:first-child': {
          marginTop: '21px',
        },
        '.sort-dir, .expander': {
          color: fadedNeutral,
        },
        'i + i': {
          marginLeft: '1rem',
        },
        '.pt-2': {
          overflow: 'auto',
        },
      },
    },
    '.section-title': {
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    '.tabs': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
      borderTop: '2px solid ' + mediumBorderColor,
      backgroundColor: mediumBorderColor,
    },
    '.tab': {
      backgroundColor: faintBorderColor,
      color: mediumBorderColor,
      minWidth: '100px',
      height: 40,
      padding: 5,
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      cursor: 'pointer',
      $nest: {
        '&.selected': {
          backgroundColor: 'white',
          color: highlight,
        },
        '.tab-text': {
          paddingTop: 5,
          paddingLeft: 5,
        },
      },
    },
    '.filter-item': {
      width: '100%',
      height: 25,
      display: 'flex',
      padding: 2,
      fontSize: '0.8rem',
      alignItems: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      paddingLeft: 15,
      $nest: {
        '&:hover': {
          backgroundColor: faintBorderColor,
        },
        'input, label': {
          cursor: 'pointer',
        },
        input: {
          paddingRight: 10,
        },
        label: {
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          marginBottom: 0,
          $nest: {
            '&.disabled': {
              color: mediumBorderColor,
            },
          },
        },
      },
    },
    '.empty': {
      fontStyle: 'italic',
      color: mediumBorderColor,
      fontSize: '0.8rem',
    },
    '.group-container': {
      padding: '0 0.5rem',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '&:last-child': {
          marginBottom: '3rem',
        },
      },
    },
  },
});

const clickableArea = style({
  cursor: 'pointer',
});

const FlexAroundContainer = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
});

const sectionControls = style({
  display: 'flex',
  width: '40px',
  color: highlight,
  justifyContent: 'flex-end',
  cursor: 'pointer',
  paddingTop: 2,
});

const sectionOpenControls = style({
  display: 'flex',
  width: '60px',
  color: highlight,
  justifyContent: 'flex-end',
});

const InputContainer = style({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 'calc(100% - 20px)',
  borderRadius: '5px',
  border: '1px solid ' + primaryTextColor,
  padding: '5px',
});

const searchInput = style({
  border: 'none',
  flex: 1,
  outline: 'none',
  marginLeft: '10px',
});

const separator = style({
  height: '10px',
  width: '100%',
  borderTop: '1px solid ' + mediumBorderColor,
  margin: 0,
});

const filterCheckbox = style({
  width: '20px',
});

export default {
  clickableArea,
  FlexAroundContainer,
  FiltersView,
  sectionControls,
  sectionOpenControls,
  InputContainer,
  searchInput,
  separator,
  filterCheckbox,
};

import * as React from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { IconCellRendererStyles } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/IconCellRenderer';
import { classes, style } from 'typestyle';
import { ConfigurableGridConfigItem } from '../ConfigurableGrid/ConfigurableGrid.types';
import Renderer, { colorsFromClasses } from 'src/utils/Domain/Renderer';
import { isEmpty } from 'lodash';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { pickDataByMask } from 'src/utils/Component/AgGrid/AgConfigParse';

// overriding the text centering here, as it likely isn't needed
export const IconPopoverStyleOverride = style({
  textAlign: 'left',
  $nest: {
    span: {
      marginLeft: 15,
    },
    // '.icon-with-popover-trigger': {
    //   marginRight: 15
    // }
  },
});
export interface Params
  extends ICellRendererParams,
  Pick<StyleDetailsPopoverProps, 'onItemClicked'>,
  Pick<ConfigurableGridConfigItem, 'rendererParams' | 'classes'> {
  dataQa?: string;
  icon: string;
}

export default class IconWithPopoverTrigger extends React.Component<Params> {
  constructor(props: Params) {
    super(props);
  }

  handleClick = () => {
    const { data, onItemClicked } = this.props;

    if (onItemClicked) {
      onItemClicked(data);
    }
  };

  render() {
    const { value, icon, dataQa, rendererParams } = this.props;
    let renderedValue: JSX.Element | string = value;
    let fontColor;

    let iconClass = `fa fa-${icon}`;
    if (iconClass.startsWith('far') || iconClass.startsWith('fas') || iconClass.startsWith('fa')) {
      iconClass = icon;
    }
    if (rendererParams) {
      const renderValues = pickDataByMask(
        this.props.data,
        this.props.rendererParams,
        this.props.api,
        this.props.node
      ) as BasicPivotItem;
      renderedValue = Renderer.styledTemplate(renderValues, rendererParams.mask, this.props.classes || []);
    }
    const matchedColor = colorsFromClasses(this.props.classes || []);
    if (!isEmpty(matchedColor)) {
      fontColor = matchedColor.map((str) => str.replace('color_', ''))[0];
    }

    return (
      <div
        data-qa={dataQa}
        className={classes(IconCellRendererStyles.container, IconPopoverStyleOverride)}
        onClick={this.handleClick}
      >
        <i className={`icon-with-popover-trigger ${iconClass}`} style={{ color: `${fontColor}` ?? 'inherit' }} />
        {renderedValue ? renderedValue : undefined}
      </div>
    );
  }
}

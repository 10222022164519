import { Tooltip, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ValidValuesCache } from 'src/services/validValuesCache';
import { isUndefined, uniq } from 'lodash';
import { ROW_SELECTION_REQUIRED_TEXT, ROW_SELECTIONS_UPDATING_TEXT } from 'src/utils/Domain/Constants';
import { isNotGroupNode } from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';
import { EditableGridHeaderEditorBaseProps } from './TextValidationHeaderEditor';

interface Props extends ICellRendererParams, EditableGridHeaderEditorBaseProps {
  availableSelections: string[];
  groupCheckbox?: boolean;
  validValuesCache: ValidValuesCache;
  columnField: string;
}

const CheckboxHeaderEditor = ({
  availableSelections,
  groupCheckbox,
  getSelectedItems,
  getCellsUpdating,
  column,
  onApplyEdit,
  columnField,
  api,
}: Props) => {
  const items = getSelectedItems();
  const noItems = getSelectedItems().length === 0;
  const itemsUpdating = getCellsUpdating().length > 0;
  const disabled = noItems || itemsUpdating;
  const [multiOptionCounts, setMultiOptionCounts] = useState({});
  const [singleOptions, setSingleOptions] = useState<boolean | undefined>(false);
  const [toggle, setToggle] = useState({});

  const countOptions = () => {
    if (groupCheckbox) {
      const optionCounts = availableSelections.reduce((counts, option) => {
        counts[option] = 0;
        return counts;
      }, {});
      // Count occurrences of each option
      items.forEach((item) => {
        const grades: string[] = item.data[columnField] || [];
        grades.forEach((grade) => {
          if (availableSelections.includes(grade)) {
            optionCounts[grade]++;
          }
        });
      });
      setMultiOptionCounts(optionCounts);
    } else {
      const allTrue = items
        .filter(isNotGroupNode)
        .map((n) => api.getValue(column!, n))
        // WARNING: `!!` here coerced to truthy value
        .every((value) => !!value);
      const allFalse = items
        .filter(isNotGroupNode)
        .map((n) => api.getValue(column!, n))
        // WARNING: `!` here coerced to falsy
        .every((value) => !value);
      setSingleOptions(allTrue ? true : allFalse ? false : undefined);
    }
  };

  useEffect(() => {
    if (!disabled) {
      countOptions();
    }
  }, [items, toggle]);

  const toggleMultiCheckboxes = (sel: string, checked: boolean) => {
    items.forEach((item) => {
      let currentSelection: string[] = item.data[columnField];

      if (checked && !currentSelection.includes(sel)) {
        currentSelection.push(sel);
      } else if (!checked) {
        currentSelection = currentSelection.filter((selection) => selection !== sel);
      }
      onApplyEdit(uniq(currentSelection), [item], column?.getColDef());

      setToggle({ ...toggle, [sel]: checked });
    });
  };

  const toggleSingleSelection = (checked: boolean) => {
    onApplyEdit(checked, items, column?.getColDef());
    setSingleOptions(checked);
  };

  let element: JSX.Element[] | undefined;
  if (groupCheckbox) {
    element = availableSelections.map((sel, _idx, arr) => {
      const width = `${(1 / arr.length) * 100}%`;
      const cursor = disabled ? 'not-allowed' : 'pointer';
      const isChecked = multiOptionCounts[sel] === items.length && items.length !== 0;
      const isIndeterminate = multiOptionCounts[sel] < items.length && multiOptionCounts[sel] > 0;
      const text = noItems ? ROW_SELECTION_REQUIRED_TEXT : itemsUpdating ? ROW_SELECTIONS_UPDATING_TEXT : '';
      return (
        <span key={sel} className={`vv-cell-checkbox`} style={{ width, cursor }}>
          <Tooltip title={text} disableFocusListener={disabled} disableHoverListener={disabled}>
            <Checkbox
              checked={!isUndefined(toggle[sel]) ? toggle[sel] : isChecked}
              indeterminate={isIndeterminate}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleOutlineIcon />}
              onChange={(event) => toggleMultiCheckboxes(sel, event.target.checked)}
              disabled={disabled}
              disableRipple={!disabled}
            />
          </Tooltip>
        </span>
      );
    });
  } else {
    const text = noItems ? ROW_SELECTION_REQUIRED_TEXT : itemsUpdating ? ROW_SELECTIONS_UPDATING_TEXT : '';
    const cursor = disabled ? 'not-allowed' : 'pointer';
    return (
      <span className={`vv-cell-checkbox`} style={{ cursor, width: '100%', justifyContent: 'center' }}>
        <Tooltip title={text} disableFocusListener={disabled} disableHoverListener={disabled}>
          <Checkbox
            checked={singleOptions}
            indeterminate={singleOptions === undefined}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            onChange={(event) => {
              toggleSingleSelection(event.target.checked);
            }}
            disabled={disabled}
            disableRipple={!disabled}
          />
        </Tooltip>
      </span>
    );
  }
  return <div style={{ width: '100%', display: 'flex' }}>{element}</div>;
};

export default CheckboxHeaderEditor;

import React from 'react';
import * as AgGrid from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
  ColDef,
  CellClassParams,
  ValueSetterParams,
  ValueFormatterParams,
  CellRendererSelectorResult,
  CellEditorSelectorResult,
} from '@ag-grid-community/core';
import { AxiosPromise } from 'axios';
import Axios from 'src/services/axios';
import { mapValues, isArray, isEqual, size, merge, isEmpty, isUndefined, sortBy, memoize, hasIn } from 'lodash';
import { find, get, isNil, map, partial, without, toString } from 'lodash/fp';
import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import {
  ColorHeaderParams,
  ColorHeaderRenderer,
  SwatchSelector,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { default as NonFrameWorkToS5Renderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/NonFrameWorkToS5Renderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import { default as RangePickerEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor.container';
import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { IconCellRenderer } from './Renderers/IconCellRenderer';
import TooltipRenderer from './Renderers/TooltipRenderer';
import { PopoverSelect } from './Editors/PopoverSelect';
import { classes, style } from 'typestyle';
import { toast } from 'react-toastify';
import handleViewport from 'react-in-viewport';

import * as StyleEditSectionStyles from './StyleEditSection.styles';
import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import CheckboxCellRenderer from './Renderers/Checkbox';
import { processApiParams, getUrl, getClientHandler, getViewProm } from '../StyleEdit.utils';
import StyleEditAddColorButton from 'src/components/AddColorButton/AddColorButton';
import { Dialog, DialogTitle, DialogContent, Icon } from '@material-ui/core';
import { getAllColors } from '../../AssortmentAdd/Assortment.client';
import { updateLifecycleParams, removeStyleColor, getExistingColorsNotInAsst } from './StyleEditSection.client';
import { updateStyleItem } from '../StyleEdit.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import Overlay from 'src/common-ui/components/Overlay/Overlay';
import Renderer from 'src/utils/Domain/Renderer';
import {
  ADJUSTED_APS,
  CC_COLOR,
  SPECIAL_STORE_GROUP,
  RANGE_CODE,
  VALID_SIZES,
  LOCKED_AFTER_COLOR_SUBMIT,
  COLOR_SUBMITTED_ATTR,
  FUNDED,
} from 'src/utils/Domain/Constants';
import ValidValuesRenderer from './Renderers/ValidValuesRenderer';
import ModalLinkRenderer from './Renderers/ModalLinkRenderer';

import { default as ReactSelect } from 'react-select';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { AssortmentClient } from '../../Assortment.client';
import { getRangeLists } from 'src/dao/scopeClient';
import IntegerEditor from './Editors/IntegerEditor/IntegerEditor';
import * as globalMath from 'mathjs';
import { executeCalculation, importDateFunctions, getVarsFromCalcString } from 'src/utils/LibraryUtils/MathUtils';
import { noop } from 'react-select/lib/utils';
import { arrayStringToArray } from 'src/utils/Primitive/String';
import {
  TextValidationEditor,
  PENDING_VALIDATION_VALUE,
  PendingCellInfo,
  viewDefnWhitelistToNarrowedCharacterWhitelist,
} from './Editors/TextValidationEditor';
import {
  DependentData,
  StyleEditSectionState,
  StyleEditSectionProps,
  GridTitleProps,
  NotInLifecycleRendererProps,
  StyleEditConfigColumn,
  ColorHeaderConfigColumn,
  MultiRangeEditors,
  AssortmentRulesResponse,
  TransposedColDef,
} from './StyleEditSection.types';
import ClearValueCellRenderer from './Renderers/ClearValue';
import {
  ExcelExportParams,
  ProcessCellForExportParams,
  CsvExportParams,
  GridApi,
  ColumnApi,
  RowHeightParams,
  RowClassParams,
  IRowNode,
  ExcelCell,
} from '@ag-grid-community/core';
import { StyleEditSectionHeaderGrid } from './StyleEditSectionHeaderGrid';
import { getRowHeight, setGridRowHeights } from './StyleEditSection.utils';
import { AsyncValidationErrorMessage } from 'src/components/ConfigurableGrid/EditableGrid/EditableGrid.subcomponents';
import { maybeReturnNestData } from 'src/utils/Http/NestedDatas';
import LocalPivotServiceContext from 'src/components/StylePreview/PivotServiceContext';
import ServiceContainer from 'src/ServiceContainer';
import { createId } from '@paralleldrive/cuid2';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, buffer } from 'rxjs/operators';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { Option } from 'src/components/ConfigurableGrid/utils/ActionModal';
import { z } from 'zod';

const NOT_FOUND = '__DATA__NOT__FOUND__';
const DEFAULT_COLDEF = {
  width: 200,
};
interface getDataFromKeyParams {
  api?: GridApi;
  colDef?: ColDef;
}
function getDataFromKey(params: getDataFromKeyParams, key: string) {
  let returnObj;

  if (!isNil(params.api) && !isNil(params.api.getRowNode(key))) {
    const rowNode = params.api.getRowNode(key);
    if (!isNil(rowNode) && params.colDef?.field) {
      returnObj = {
        rowNodeFound: true,
        data: params.api.getValue(params.colDef.field, rowNode),
      };
    }
  } else {
    returnObj = {
      rowNodeFound: false,
      data: undefined,
    };
  }
  return returnObj;
}

const StyleEditGridTitle = (props: GridTitleProps) => {
  const { title } = props;

  return (
    <div className={StyleEditSectionStyles.sectionGridTitle}>
      <div className={'leftTitleContainer'}>
        <span>{title}</span>
      </div>
    </div>
  );
};

const NotInLifecycleRenderer = (props: NotInLifecycleRendererProps) => {
  return <div className={StyleEditSectionStyles.notInLifecycle}>{props.text}</div>;
};

type ObserverObject = {
  dataApi: ClientDataApi;
};

type Item = {
  styleColorId: string;
  update: any;
};

export class StyleEditSection extends React.Component<StyleEditSectionProps, StyleEditSectionState> {
  static contextType = LocalPivotServiceContext;
  context!: React.ContextType<typeof LocalPivotServiceContext>;
  private _isMounted: boolean; // FIXME: is this necessary?
  savedCalcData: Record<string, any> = {};
  nonFrameworkComponents: any;
  frameworkComponents: any;
  allGridComponents: any;
  dataQueue!: AxiosPromise<any>[];
  // gridApi, columnApi and headerApi need to initialize as undef
  // because in some rare cases the grid will call functions with api = null
  // and we need to guard against those cases crashing the view
  gridApi: AgGrid.GridApi | undefined = undefined;
  columnApi: AgGrid.ColumnApi | undefined = undefined;
  headerGridApi: AgGrid.GridApi | undefined = undefined;
  headerGridColumnApi: AgGrid.ColumnApi | undefined = undefined;
  math = globalMath.create(globalMath.all) as globalMath.MathJsStatic;
  componentId = createId();

  // Rx-js updating
  updateItemSubject: Subject<Item> | undefined;
  debounceUpdateItem$: Observable<Item> | undefined;
  updateItem$: Observable<Item[]> | undefined;
  updateItem$Subscriber: Subscription | undefined;

  // single observed property can contain multiple observers
  observers: { [s: string]: { [s: string]: { [s: string]: ObserverObject } } } = {};
  stickySectionElement: any;

  constructor(props: StyleEditSectionProps) {
    super(props);
    this._isMounted = false;
    this.nonFrameworkComponents = {
      colorHeaderRenderer: ColorHeaderRenderer,
      nonFrameWorkToS5Renderer: NonFrameWorkToS5Renderer,
    };
    this.frameworkComponents = {
      iconCellRenderer: IconCellRenderer,
      imageCellRenderer: ImageCellRenderer,
      validValuesEditor: ValidValuesEditor,
      validValuesRenderer: ValidValuesRenderer,
      rangePickerRenderer: RangePickerRenderer,
      rangePickerEditor: RangePickerEditor,
      checkboxCellRenderer: CheckboxCellRenderer,
      popoverSelectEditor: PopoverSelect,
      validSizesRenderer: ValidSizesRenderer,
      notInLifecycle: NotInLifecycleRenderer,
      integerEditor: IntegerEditor,
      textValidationEditor: TextValidationEditor,
      clearValueRenderer: ClearValueCellRenderer,
      tooltipRenderer: TooltipRenderer,
      modalLinkRenderer: ModalLinkRenderer,
    };
    this.allGridComponents = {
      ...this.nonFrameworkComponents,
      ...this.frameworkComponents,
    };
    this.observers = {};
    this.state = {
      transposedDefs: null,
      colDefs: null,
      rowData: null,
      transposedData: null,
      disabledIds: [],
      addColorModalOpen: false,
      dependentData: null,
      isLoading: null,
      isAddingColor: false,
      submittedStyleColors: [],
      confirmationModalProps: {
        isOpen: false,
        descriptionText: '',
      },
      mergedRangeList: undefined,
    };
  }

  getComponentId = () => {
    return this.props.componentIdOverride ? this.props.componentIdOverride : this.componentId;
  };

  componentDidMount() {
    this.getSectionDataAndConfig();

    // Using an rx-js stream, collect updates that occur during a 20ms window and send them all at once
    // Fixes things like lifecycle updates needing to do updates at once
    this.updateItemSubject = new Subject();
    this.debounceUpdateItem$ = this.updateItemSubject.pipe(debounceTime(20));
    this.updateItem$ = this.updateItemSubject.pipe(buffer(this.debounceUpdateItem$));
    this.updateItem$Subscriber = this.updateItem$.subscribe((result) => {
      let styleColorId = '';
      const update = result.reduce((prevVal, newVal) => {
        styleColorId = newVal.styleColorId;
        return merge(prevVal, newVal.update);
      }, {}) as { [s: string]: any };

      updateLifecycleParams(styleColorId, update)
        .then(() => {
          this.context.clearPivotCache();
          this.props.refreshSections(this.getComponentId());
        })
        .catch((e) => {
          toast.error('An error occured updating your lifecycle parameters');
          ServiceContainer.loggingService.error(
            'An error occured updating the lifecycle parameters in Style edit lifecylce paramters',
            e.stack
          );
        });
    });
  }

  componentDidUpdate(prevProps: StyleEditSectionProps) {
    if (!isEqual(this.props.sortedStyleColorIds, prevProps.sortedStyleColorIds)) {
      if (!isNil(this.columnApi)) {
        this.reorderAndFilterColumns(this.columnApi);
      }
      if (!isNil(this.headerGridColumnApi)) {
        this.reorderAndFilterColumns(this.headerGridColumnApi);
      }

      // only want to refresh on update in multisection since data is not updated
      if (!isNil(this.props.multiSectionData)) {
        if (!isNil(this.gridApi)) {
          this.gridApi.refreshCells();
        }
        if (!isNil(this.headerGridApi)) {
          this.headerGridApi.refreshCells();
        }
      }
    }
    if (!isEqual(this.props.headerData, prevProps.headerData)) {
      if (this.gridApi && this.headerGridApi) {
        this.gridApi.redrawRows();
        this.headerGridApi.redrawRows();
        setGridRowHeights(this.state.colDefs, this.gridApi);
      }
    }

    if (!isNil(prevProps.styleId) && !isNil(this.props.styleId) && prevProps.styleId !== this.props.styleId) {
      this.getSectionDataAndConfig();
    }
    const isCurrent = this.props.lastEditedSection === this.getComponentId();
    if (
      !isNil(prevProps.multiSectionData) &&
      !isNil(this.props.multiSectionData) &&
      !isEqual(prevProps.multiSectionData.data, this.props.multiSectionData.data)
    ) {
      /* //TODO add support for multisection redecoration
      const isMultiSectionListData = this.props.multiSectionData?.config.dataApi?.isListData;
      if (isCurrent && this.props.multiSectionData?.config.isListData && isMultiSectionListData === true) {
        this.redecorateRowData();
      } else if (!isCurrent) {
        this.handleMultiSection();
      }*/
      this.handleMultiSection();
    } else if (this.props.currentRefreshCount > prevProps.currentRefreshCount) {
      const prevData = prevProps.sectionData;
      const curData = this.props.sectionData;
      if (isCurrent && !isNil(prevData) && isEqual(prevData.dataApi, curData?.dataApi) && curData?.dataApi.isListData) {
        this.redecorateRowData();
      } else if (!isCurrent) {
        this.getSectionDataAndConfig();
      }
    }

    if (prevProps.expanded && !this.props.expanded) {
      // because sections never unmount anymore, this code repeats
      // what would happen on unmount, but instead when the section is un-expanded
      if (this.props.getHeaderElement) {
        this.props.getHeaderElement(undefined);
      }
      // we need to wipe the apis as the underlying grids are now destroyed on collapse (we don't unmount no more)
      this.gridApi = undefined;
      this.columnApi = undefined;
    }
  }

  componentWillUnmount() {
    if (this.props.getHeaderElement) {
      this.props.getHeaderElement(undefined);
    }
    this._isMounted = false;
    if (this.updateItem$Subscriber) {
      this.updateItem$Subscriber.unsubscribe();
    }
  }

  getExportOptions = () => {
    const { sectionData, multiSectionData } = this.props;
    const title = isNil(multiSectionData) ? (sectionData ? sectionData.text : '') : multiSectionData.text;
    const additionalExcelExports = {
      customHeader: title,
      excelRendererObj: Renderer.getAllExcelRenderers(),
    };
    return additionalExcelExports;
  };

  onCsvExport = () => {
    if (isNil(this.gridApi)) {
      // this shouldn't happen
      throw new Error('csv export called, but no gridapi was found');
    }
    let csvOptions: CsvExportParams = {
      processCellCallback: (params: ProcessCellForExportParams) => {
        const { column, node, value } = params;
        const colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[] = this.state.colDefs;
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === (node?.id || null);
        });
        const colInfo = colDefs[rowIndex];
        const cellRenderer = colInfo.renderer;
        const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
        const renderFn = renderer ? Renderer[renderer] : undefined;

        return column.getColId() !== 'headerText' && renderFn ? renderFn(value) : value;
      },
      processHeaderCallback: (params: AgGrid.ProcessHeaderForExportParams) => {
        if (!this.state.rowData) return;
        const { column } = params;
        const id = column.getDefinition().headerName;
        const val: BasicPivotItem = this.state.rowData.find((data: BasicPivotItem) => {
          return data.id === id;
        });
        return get('attribute:cccolor:name', val) || '';
      },
    };

    const exportOptions = this.getExportOptions();
    csvOptions = {
      ...csvOptions,
      prependContent: exportOptions.customHeader + '\n',
    };

    this.gridApi.exportDataAsCsv(csvOptions);
  };

  onExcelExport = () => {
    if (isNil(this.gridApi)) {
      // this shouldn't happen
      throw new Error('xls export called, but no gridapi was found');
    }
    const exportOptions = this.getExportOptions();
    let excelOptions: ExcelExportParams = {
      sheetName: 'S5 Assortment Export',
      processCellCallback: (params: ProcessCellForExportParams) => {
        const { column, node, value } = params;
        const colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[] = this.state.colDefs;
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === (node?.id || null);
        });
        const colInfo = colDefs[rowIndex];
        const cellRenderer = colInfo.renderer;
        const renderer = typeof cellRenderer === 'string' ? cellRenderer : '';
        const renderFn = renderer ? Renderer[renderer] : undefined;

        if (exportOptions && exportOptions.excelRendererObj && renderer !== '') {
          const excelFormat = exportOptions.excelRendererObj[renderer];
          if (excelFormat) {
            return value;
          }
        }
        return column.getColId() !== 'headerText' && renderFn ? renderFn(value) : value;
      },
      processHeaderCallback: (params: AgGrid.ProcessHeaderForExportParams) => {
        if (!this.state.rowData) return;
        const { column } = params;
        const id = column.getDefinition().headerName;
        const val: BasicPivotItem = this.state.rowData.find((data: BasicPivotItem) => {
          return data.id === id;
        });
        return get('attribute:cccolor:name', val) || '';
      },
    };

    const header: ExcelCell[][] = new Array(1);
    header.push([
      {
        data: {
          type: 'String',
          value: exportOptions.customHeader,
        },
        mergeAcross: 2,
      },
    ]);
    excelOptions = {
      ...excelOptions,
      // @ts-ignore unsure how to fix
      customHeader: header,
      // fileName: (fileName && fileName + ' at ' + moment().format('YYYY-MM-DD hh|mmA')),
    };
    this.gridApi.exportDataAsExcel(excelOptions);
  };

  getSectionDataAndConfig() {
    this._isMounted = true;
    const { multiSectionData } = this.props;

    if (isNil(multiSectionData)) {
      this.handleStandardSection();
      // standard section makes and async call, at the end of which, loading is set to false
      // set it to true here to keep the component to toggle the overlay on, and it'll get toggled off
      // when the async inside handleStandardSection() returns
      this.setState({
        isLoading: this.props.sectionData ? this.props.sectionData.dataApi : null,
      });
    } else {
      this.handleMultiSection();
    }
  }

  redecorateRowData = async () => {
    const rowData = this.state.rowData;
    const sectionData = this.props.multiSectionData?.config ?? this.props.sectionData;
    if (isNil(rowData) || isNil(sectionData) || !sectionData.dataApi.isListData) return;
    const newRowData = await ServiceContainer.pivotService.redecorate({
      coordinates: rowData,
      defnId: sectionData.dataApi.defnId,
      nestData: false,
      aggBy: [sectionData.dataApi.params.aggBy],
    });
    this.transposeRowsAndColumns(
      newRowData,
      this.state.colDefs,
      this.state.actions,
      this.state.multiRangeEditors!,
      this.state.dependentData
    );
  };

  handleStandardSection() {
    if (this.state.isLoading && this.props.sectionData && isEqual(this.state.isLoading, this.props.sectionData.dataApi))
      return;
    const { sectionData } = this.props;
    if (sectionData) {
      const configApi = sectionData.configApi;
      const dataApi = sectionData.dataApi;
      const dependentsApi = sectionData.dependentsApi;
      let queue: any[] = [];

      let dataProm: Promise<any>;
      if (dataApi.isListData) {
        dataProm = this.context.listData(dataApi.defnId, AppType.Assortment, dataApi.params).then((resp) => {
          return resp.flat;
        });
      } else {
        const dataUrl = getUrl(dataApi);
        dataProm = Axios.get(dataUrl).then((response) => {
          let rowData = response.data ? response.data : response;
          if (!isArray(rowData)) {
            rowData = rowData.data;
          }
          return rowData;
        });
      }

      dataProm = dataProm.then((data) => {
        return this.regulateData(data);
      });

      queue = [dataProm, getViewProm(configApi), getRangeLists()];

      if (dependentsApi) {
        queue = [...queue, Axios.get(dependentsApi.url)];
      }

      Promise.all(queue).then((response: any) => {
        if (!this._isMounted) {
          return;
        }

        const { daysRangeListExtended: daysRangeList, daysPastRangeList } = response[2];
        const mergedRangeList = {
          start_date: merge(daysRangeList.start_date, daysPastRangeList.start_date),
          end_date: merge(daysRangeList.end_date, daysPastRangeList.end_date),
        };
        importDateFunctions(this.math, mergedRangeList);
        this.setState({
          mergedRangeList,
        });

        let rowData = response[0];
        if (!isArray(rowData)) {
          rowData = rowData.data;
        }

        const config = maybeReturnNestData(response[1]) as any;

        this.transposeRowsAndColumns(
          rowData,
          config.columns,
          config.actions,
          config.multiRangeEditors,
          dependentsApi ? response[3].data.data : null
        );

        if (this.props.onSectionDataLoaded) {
          this.props.onSectionDataLoaded();
        }
      });
    }
  }

  handleMultiSection() {
    const { multiSectionData } = this.props;

    if (!isNil(multiSectionData)) {
      const { data: rowData, config } = multiSectionData;
      const modified = this.regulateData(rowData);

      if (isNil(modified) || isNil(config)) {
        return;
      }

      this.transposeRowsAndColumns(modified, config.columns, config.actions, config.multiRangeEditors);

      if (this.props.onSectionDataLoaded) {
        this.props.onSectionDataLoaded();
      }
    }
  }

  // handles filling in blank stylecolor data
  // to match the amount of stylecolor data for the style
  regulateData(data: any) {
    // currently only want to run this for 'Store Eligibility' which is the only with multiSectionData at this time
    if (isNil(this.props.multiSectionData)) {
      return data;
    }

    const { sortedStyleColorIds } = this.props;
    const modified = sortedStyleColorIds.map((styleColorId) => {
      const rowDataIndex = data.findIndex((item: any) => {
        return styleColorId === item.id || styleColorId === item.product;
      });

      return rowDataIndex < 0
        ? { id: styleColorId, [NOT_FOUND]: NOT_FOUND } // fill in with known empty data
        : data[rowDataIndex];
    });

    return modified;
  }

  addColor = async () => {
    const { existingStyleColors, showOnlyPreexist, parentId } = this.props;
    const { actions } = this.state;
    if (!isNil(actions?.addColorFetch)) {
      const listDataConfig = {
        defnId: actions?.addColorFetch.defnId,
        params: {
          topMembers: parentId,
        },
      };
      const colorList = await ServiceContainer.pivotService
        .listData(listDataConfig.defnId, undefined, listDataConfig.params)
        .then((colors) => {
          const retZod = z.array(z.object({ value: z.string(), existing: z.number() }));
          return retZod.parse(colors.tree).map((i) => ({
            value: i.value,
            existing: i.existing,
          }));
        });
      this.setState({
        availableColorsToAdd: colorList,
        selectedNewColor: colorList[0].value,
        addColorModalOpen: true,
      });
    } else {
      let colorList = getExistingColorsNotInAsst(existingStyleColors).map((i) => ({
        value: i,
        existing: 1,
      }));
      if (!showOnlyPreexist) {
        const nonExistingColors = (await getAllColors(parentId)).map((color) => {
          return {
            value: color,
            existing: 0,
          };
        });
        colorList = nonExistingColors.concat(colorList);
      }
      this.setState({
        availableColorsToAdd: colorList,
        selectedNewColor: colorList[0].value,
        addColorModalOpen: true,
      });
    }
  };

  removeColor(styleColorId: string) {
    const { sortedStyleColorIds, refresh } = this.props;
    const refreshCompanionView = sortedStyleColorIds.length === 1; // removing last style color from style

    return removeStyleColor(styleColorId)
      .then((resp: any) => {
        // for some reason the error handler is not invoked, so checking here for success
        const expectedErrorMsg = 'StyleColor is not removable from Assortment';
        if (!resp.data.success && resp.data.message === expectedErrorMsg) {
          toast.error(`This style color has already been published \ and cannot be removed from Assortment.`, {
            position: toast.POSITION.TOP_LEFT,
          });
        }

        if (refresh) {
          refresh(refreshCompanionView);
        }
      })
      .catch((err) => {
        toast.error(`An unexpected error occured while attempting to remove a color from the assortment`, {
          position: toast.POSITION.TOP_LEFT,
        });
        ServiceContainer.loggingService.error(
          `An error occured removing ${styleColorId} from the assortment`,
          err.stack
        );
      });
  }

  reorderAndFilterColumns(columnApi: AgGrid.ColumnApi) {
    if (isNil(columnApi) || !this.state.rowData || isNil(columnApi.getColumns())) {
      return;
    }

    const { sortedStyleColorIds } = this.props;
    const { rowData } = this.state;
    const allIds = rowData.map((data: any) => data.id ?? data.product).filter((item) => item !== undefined);
    const columns = columnApi.getColumns();
    // hide all columns then show filtered columns | Also check if column count in grid is same or larger than new columns
    if (!isNil(columns) && columns.length - 1 >= sortedStyleColorIds.length) {
      columnApi.setColumnsVisible(allIds, false);
      columnApi.setColumnsVisible(sortedStyleColorIds, true);
      columnApi.moveColumns(sortedStyleColorIds, 0);
    }
  }
  doesExternalFilterPass(node: IRowNode): boolean {
    return !node.data.hidden;
  }
  private setDisabledId = (id: string): void => {
    const refreshCols = () => {
      if (this.gridApi) {
        this.gridApi.refreshCells({
          columns: [id],
          force: true,
        });
      }
    };

    if (!this.state.disabledIds.includes(id)) {
      this.setState(
        (prevState) => ({
          disabledIds: prevState.disabledIds.concat([id]),
        }),
        refreshCols
      );
    }
  };

  private removeDisabledId = (id: string): void => {
    const refreshCols = () => {
      if (this.gridApi) {
        this.gridApi.refreshCells({
          columns: [id],
          force: true,
        });
      }
    };

    if (this.state.disabledIds.includes(id)) {
      this.setState(
        (prevState) => ({
          disabledIds: without([id], prevState.disabledIds),
        }),
        refreshCols
      );
    }
  };

  private columnDisabled = (id: string): boolean => {
    return this.state.disabledIds.indexOf(id) >= 0;
  };

  private setDisabledIds(
    colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[],
    styleColor: BasicPivotItem,
    styleColorId: string
  ) {
    colDefs.forEach((col: StyleEditConfigColumn) => {
      if (col.controlsColumnDisable) {
        const disableColumn = col.controlColumnDisableValue === styleColor[col.dataIndex];
        if (disableColumn) {
          this.setDisabledId(styleColorId);
        } else {
          this.removeDisabledId(styleColorId);
        }
      }
    });
  }

  createColumn = (colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[], styleColor: BasicPivotItem): ColDef => {
    const inlineEditors = ['validSizes', 'checkbox'];
    const styleColorId = styleColor.id || styleColor.product;

    const numRows = colDefs.length - 1; // 1 less because title
    function getNotInLifecycleText(numRow: number, rowIndex: number): string {
      let totalRows = numRow;
      if (totalRows % 2 === 0) {
        totalRows = totalRows - 1;
      }
      const tempNo = (totalRows - 3) / 2;
      const firstIndex = tempNo,
        secondIndex = tempNo + 1,
        thirdIndex = tempNo + 2;
      if (rowIndex === firstIndex) {
        return 'NOT IN';
      }
      if (rowIndex === secondIndex) {
        return 'LIFECYCLE';
      }
      if (rowIndex === thirdIndex) {
        return 'RANGE';
      }
      return '';
    }

    const notInLifecycleRange = (colInfo: StyleEditConfigColumn): boolean => {
      return styleColor[NOT_FOUND] && colInfo.dataIndex !== 'color_header';
    };

    const isInitialFundedFloorset = (colInfo: StyleEditConfigColumn): boolean => {
      return colInfo.dataIndex === FUNDED && styleColor['isFirstFloorset'];
    };

    const isEditable = (params: AgGrid.ICellEditorParams | AgGrid.ICellRendererParams) => {
      const rowIndex = colDefs.findIndex((def) => {
        return def.dataIndex === params.node?.id;
      });
      const colInfo = colDefs[rowIndex];
      if (colInfo == null) {
        return false;
      }

      if (colInfo) {
        if (!isNil(colInfo.editableByCalc)) {
          const getDataFn = (partial(getDataFromKey, [params]) as unknown) as any;
          const calcEditable = !!executeCalculation(this.math, colInfo.editableByCalc, getDataFn(params));
          return colInfo.editable && calcEditable;
        } else if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
          return colInfo.editable && this.state.submittedStyleColors.indexOf(styleColorId) < 0;
        } else if (isInitialFundedFloorset(colInfo)) {
          return false;
        }

        return colInfo.editable;
      } else {
        return false;
      }
    };

    const isColumnDisabled = (colInfo: StyleEditConfigColumn) => {
      if (isNil(colInfo.renderer) || colInfo.renderer === 'color_header') {
        return false;
      }

      if (isInitialFundedFloorset(colInfo)) {
        return true;
      }

      if (isNil(colInfo.controlsColumnDisable)) {
        return this.columnDisabled(styleColorId);
      }

      // if prop exists and it's value is true (should be for funded only),
      // don't disable otherwise check if styleColorId is disabled
      return colInfo.controlsColumnDisable ? false : this.columnDisabled(styleColorId);
    };

    // check for and set disabled columns on load
    this.setDisabledIds(colDefs, styleColor, styleColorId);

    return {
      headerName: styleColorId,
      field: styleColorId,
      cellStyle: { borderRight: '1px solid #dee1e6' },
      cellClassRules: {
        [StyleEditSectionStyles.editableCell]: (params: CellClassParams) => {
          const rowIndex = colDefs.findIndex((def) => {
            return def.dataIndex === params.node.id;
          });
          const colInfo = colDefs[rowIndex];
          if (isNil(colInfo) || notInLifecycleRange(colInfo) || isInitialFundedFloorset(colInfo)) {
            return false;
          }
          if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
            return colInfo.editable && this.state.submittedStyleColors.indexOf(styleColorId) < 0;
          }
          let calcEditValid = true;
          if (!isNil(colInfo.editableByCalc)) {
            const getDataFn = (partial(getDataFromKey, [params]) as unknown) as any;
            calcEditValid = !!executeCalculation(this.math, colInfo.editableByCalc, getDataFn);
          }

          return calcEditValid && colInfo.editable && !this.columnDisabled(styleColorId);
        },
        [StyleEditSectionStyles.cellDisabled]: (params: CellClassParams) => {
          const rowIndex = colDefs.findIndex((def) => {
            return def.dataIndex === params.node.id;
          });
          const colInfo = colDefs[rowIndex];
          if (isNil(colInfo)) {
            return false;
          }

          return isColumnDisabled(colInfo);
        },
        [StyleEditSectionStyles.notInLifecycle]: (params: CellClassParams) => {
          const rowIndex = colDefs.findIndex((def) => {
            return def.dataIndex === params.node.id;
          });
          const colInfo = colDefs[rowIndex];
          if (isNil(colInfo)) {
            return false;
          }

          return notInLifecycleRange(colInfo);
        },
      },
      suppressKeyboardEvent: () => false, // Note: if any popover components are configured for style edit, this will cause issues
      onCellValueChanged: (params: ValueSetterParams) => {
        this.handleCellValueChange(styleColorId, params, colDefs);
      },
      valueGetter: (params: AgGrid.ValueGetterParams) => {
        const { data } = params;
        const calculation = data.calculation;
        const getDataFn = partial(getDataFromKey, [params]);
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.node?.id;
        });
        const colInfo = colDefs[rowIndex];
        const colValue = params.data[styleColorId];

        if (
          colInfo.dataIndex !== 'color_header' &&
          (isNil(colInfo.editable) || colInfo.editable === false) &&
          isNil(colValue)
        ) {
          return null;
        }

        if (calculation) {
          if (params.data.onlyCalcOnParamChange) {
            // If dependent params of calculation have changed from original, process calcs
            let paramChangedCalc = false;
            const vars = getVarsFromCalcString(this.math, calculation);
            vars.forEach((v: string) => {
              const varValue = getDataFn(v)?.data;
              const columnField = params.colDef.field;
              if (columnField) {
                if (Object.keys(this.savedCalcData).indexOf(columnField) === -1) {
                  this.savedCalcData[columnField] = {};
                }
                if (Object.keys(this.savedCalcData[columnField]).indexOf(v) === -1) {
                  this.savedCalcData[columnField][v] = varValue;
                }
                if (!isEqual(varValue, this.savedCalcData[columnField][v])) {
                  paramChangedCalc = true;
                }
              }
            });
            if (!paramChangedCalc) {
              return params.data[styleColorId];
            }
          }
          // @ts-ignore - this would not work with a condition check
          const newValue = executeCalculation(this.math, calculation, getDataFn);
          return newValue;
        } else {
          const value = params.data[styleColorId];
          const isEditing = !isNil(params.api) && !isEmpty(params.api.getEditingCells());

          if (!isNil(colInfo) && !isNil(value) && colInfo.renderer === 'percent') {
            const parsedValue = parseFloat(value);
            // if editing, show non-decimal value (0.11 => 11) otherwise percent renderer will format properly
            return parsedValue;
          }

          // If value is undefined or comes back as string "undefined", make it an empty string
          if (isNil(value) || Number.isNaN(value) || toString(value).trim() === 'undefined') {
            return '';
          }

          return value;
        }
      },
      valueSetter: (params: ValueSetterParams) => {
        this.handleSetValue(styleColorId, this.state.dependentData, params);
        return true;
      },
      editable: (params) => {
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.node.id;
        });
        let isSubmitLocked = false;
        const colInfo = colDefs[rowIndex];
        if (isNil(colInfo) || notInLifecycleRange(colInfo) || isInitialFundedFloorset(colInfo)) {
          return false;
        }
        if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
          isSubmitLocked = this.state.submittedStyleColors.indexOf(styleColorId) >= 0;
        }
        let calcEditValid = true;
        if (!isNil(colInfo.editableByCalc)) {
          const getDataFn = (partial(getDataFromKey, [params]) as unknown) as any;
          calcEditValid = !!executeCalculation(this.math, colInfo.editableByCalc, getDataFn);
        }
        const canEdit = !isNil(colInfo.editable) ? colInfo.editable : false;
        const isDisabled = isColumnDisabled(colInfo);
        const isInlineEditor = colInfo.renderer && inlineEditors.indexOf(colInfo.renderer) >= 0;

        return canEdit && calcEditValid && !isDisabled && !isInlineEditor && !isSubmitLocked;
      },
      cellEditorSelector: (params: AgGrid.ICellEditorParams): CellEditorSelectorResult => {
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.node.id;
        });
        const colInfo = colDefs[rowIndex];
        if (colInfo == null) {
          return (null as unknown) as CellEditorSelectorResult;
        }
        const inputParams = colInfo.inputParams;

        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'multiRangeEditors':
            params.colDef.cellEditorPopup = true;

            const { disableOverlap } = colInfo;
            const { multiRangeEditors } = this.state;
            const multiRangeDataIndices =
              !isNil(multiRangeEditors) && !isNil(colInfo.inputComponent)
                ? multiRangeEditors[colInfo.inputComponent]
                : {};
            const checkboxIndices = !isNil(multiRangeEditors) ? multiRangeEditors.checkboxEditor : undefined;
            const col = colInfo.dataIndex;
            return {
              component: 'rangePickerEditor',
              params: {
                values: map('value', colInfo.options),
                nodeKey: params.data.dataKey,
                makeModal: true,
                multiRangeDataIndices,
                disableOverlap,
                startWithPlanCurrent: true,
                checkboxIndices,
                lifecycleData: styleColor,
                updateLifecycleData: updateLifecycleParams,
                col,
              },
            };
          case 'popoverSelect': {
            params.colDef.cellEditorPopup = true;

            const { text: title, inputComponent } = colInfo;
            const processedDataApi = processApiParams(colInfo.dataApi, styleColor);

            return {
              component: 'popoverSelectEditor',
              params: {
                title,
                inputComponent,
                dataApi: processedDataApi,
                makeModal: inputComponent === 'ssgSelect',
              },
            };
          }
          case 'validValues':
          case 'validValuesMulti': {
            params.colDef.cellEditorPopup = true;
            const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            let processedDataApi;
            let clientHandler;

            if (isNil(colInfo.dataApi.clientHandler)) {
              const field = params.colDef.field;
              if (!isUndefined(colInfo.dataApi.params) && colInfo.observes && params.node.parent && field) {
                const rowData = params.node.parent.allLeafChildren.find(
                  (row: IRowNode) => row.data.dataKey === colInfo.observes
                );
                const rowId = rowData?.data[field];

                // The entire createColumn() function is created within a closer, with the assignment of
                // 'styleColor' at the top.
                // In the specific case of a column that observes another column, the styleColor becomes
                // out of date after an edit (because the column hasn't been recreated yet),
                // and therefore doesn't have the latest data.  An attempt was made to get the latest data from
                // this.state.rowData but that was also inexplicably stale,
                // so here we glue on the latest data from the transposed grid, and generate
                // a processed Api from that
                const latestStyleColorData = {
                  ...styleColor,
                  [colInfo.observes]: rowId,
                };
                processedDataApi = processApiParams(colInfo.dataApi, latestStyleColorData);
              } else {
                processedDataApi = processApiParams(colInfo.dataApi, styleColor);
              }
            } else if (colInfo.dataApi.clientHandlerParams) {
              const clientHandlerParams = this.props[colInfo.dataApi.clientHandlerParams];
              clientHandler = getClientHandler(colInfo.dataApi.clientHandler, clientHandlerParams);
            }
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedDataApi,
                clientHandler,
                dataQa: isNil(multiSelect) ? 'select-style-edit-section' : 'select-multi-style-edit-section',
                multiSelect,
                asCsv: colInfo.asCsv,
                ignoreCache: colInfo.ignoreCache,
                allowEmptyOption,
                includeCurrent: colInfo.includeCurrent,
              },
            };
          }
          case 'validValuesNoServer': {
            const { dependentData } = this.state;
            return {
              component: 'validValuesEditor',
              params: {
                options: !isNil(dependentData) ? Object.keys(dependentData) : [],
                dataQa: 'select-no-server-editor',
              },
            };
          }
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'usMoney':
          case 'thousand':
          case 'usMoneyRounded':
          case 'usMoneyNoCents':
            return {
              component: 'nonFrameWorkToS5Renderer',
              params: {
                inputType: 'percent',
              },
            };
          case 'percent':
          case 'integer':
            const percent = colInfo.renderer === 'percent';
            return {
              component: 'integerEditor',
              params: {
                inputParams: { ...inputParams, percent },
              },
            };
          case 'textValidator':
          case 'textValidatorAsync': {
            params.colDef.cellEditorPopup = true;

            const whitelist = viewDefnWhitelistToNarrowedCharacterWhitelist(inputParams.whitelist);

            return {
              component: 'textValidationEditor',
              params: {
                validateAsync: colInfo.inputType === 'textValidatorAsync',
                ...inputParams,
                whitelist,
                onValidated: this.handlePendingCellUpdate.bind(this), // will be invoked in promise context, so need to set context
              },
            };
          }
          default:
            return {
              component: 'agTextCellEditor',
            };
        }
      },
      cellRendererSelector: (params: AgGrid.ICellRendererParams): CellRendererSelectorResult => {
        const rowIndex = colDefs.findIndex((def) => {
          return def.dataIndex === params.data.dataKey;
        });
        const colInfo = colDefs[rowIndex];
        if (colInfo == null) {
          return (null as unknown) as CellRendererSelectorResult;
        }

        if (notInLifecycleRange(colInfo)) {
          return {
            component: 'notInLifecycle',
            params: {
              text: getNotInLifecycleText(numRows, rowIndex - 1),
            },
          };
        }

        switch (colInfo.renderer) {
          case 'image':
            return {
              component: 'imageCellRenderer',
              params: {
                showEditButton: true,
              },
            };
          case 'icon':
            return {
              component: 'iconCellRenderer',
              params: {
                icon: colInfo.rendererIcon,
              },
            };
          case 'csv':
            return {
              component: (params: { value: unknown }) => {
                let contents: unknown;
                if (isArray(params.value)) {
                  contents = params.value.join(', ');
                } else {
                  contents = params.value;
                }
                return <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>{contents}</div>;
              },
            };
          case 'color_header':
            const infoAsColorHeader = colInfo as ColorHeaderConfigColumn;
            const isRemovable = get([styleColorId, 'isremovable'], this.props.headerData) === true;
            const colorHeadParams: ColorHeaderParams = {
              $selector: () => {
                return mapValues(infoAsColorHeader.$selector, (value) => {
                  // the `isNil` check below is where colors that are in `headerData` are filtered out in a section that
                  // that doesn't appear in the current `StyleEditSection`
                  if (this.props.headerData && !isNil(this.props.headerData[styleColorId])) {
                    const itemData = this.props.headerData[styleColorId];
                    const itemDataValue = itemData[value] ? itemData[value] : undefined;
                    return itemDataValue;
                  } else {
                    styleColor[value];
                  }
                }) as SwatchSelector;
              },
              id: styleColorId,
              isRemovable, // handles enabling/disabling the 'Remove' button
              displayRemove: infoAsColorHeader.displayRemove, // handles rendering the 'Remove' button
              removeClicked: () => {
                this.setState({
                  confirmationModalProps: {
                    isOpen: true,
                    descriptionText: `Remove StyleColor ${styleColor.description}?`,
                    onConfirm: () => {
                      this.removeColor(styleColorId).then(() => this.closeConfirmationModal());
                    },
                    onCancel: () => {
                      this.closeConfirmationModal();
                    },
                  },
                });
              },
            };
            return {
              component: 'colorHeaderRenderer',
              params: colorHeadParams,
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
            };
          case 'validSizes':
            const { dependentData } = this.state;
            if (!params.api) {
              // this block attempts to catch rare async errors that can occur when the section is
              // mounted and unmounted rapidly, causing a throw on params.api
              // if no api is found, dump the sections
              // see INT-1241
              this.props.refreshSections(null);
              return {
                component: 'nonFrameWorkToS5Renderer',
                params: {
                  renderer: colInfo.renderer,
                },
              };
            }
            if (colInfo.dataApi) {
              // allow component to manage own data
              const dataParams = colInfo.dataApi.params;
              const transposedData: Record<string, unknown> = {};
              if (dataParams != null) {
                Object.values(dataParams).forEach((valKey) => {
                  const rowNode = params.api.getRowNode(valKey);
                  if (rowNode != null) {
                    transposedData[valKey] = rowNode.data[styleColorId];
                  }
                });
              }
              const validSizesDataApi = processApiParams(colInfo.dataApi, transposedData);
              return {
                component: 'validSizesRenderer',
                params: {
                  selectedOptions: params.data[styleColorId],
                  options: [],
                  dataApi: validSizesDataApi,
                  editable: isEditable(params),
                },
              };
            } else {
              const rangeCode = params.api.getRowNode(RANGE_CODE)?.data[styleColorId];
              const dependentSizes = !isNil(dependentData) && rangeCode ? dependentData[rangeCode] : null;
              const options = !isNil(dependentSizes) ? dependentSizes : params.data[styleColorId];
              return {
                component: 'validSizesRenderer',
                params: {
                  options,
                  editable: isEditable(params),
                },
              };
            }
          case 'validValuesMultiLine':
            return {
              component: 'validSizesRenderer',
              params: {
                editable: false,
                cursorStyle: 'cursor',
              },
            };
          case 'validValuesRenderer':
            const dataConfig = processApiParams(colInfo.dataApi, styleColor);
            return {
              component: 'validValuesRenderer',
              params: {
                dataConfig,
              },
            };
          case 'clearValue':
            return {
              component: 'clearValueRenderer',
              params: {
                isDataTransposed: true,
              },
            };
          case 'modalLink':
            return {
              component: 'modalLinkRenderer',
              params: {
                displayValue: 'View Similar Items',
                isDataTransposed: true,
                modalParams: colInfo.inputParams,
                getValue: this.getOriginalValue,
              },
            };
          case 'tooltipRenderer':
            return {
              component: 'tooltipRenderer',
            };
          default:
            return {
              component: 'nonFrameWorkToS5Renderer',
              params: {
                renderer: colInfo.renderer,
              },
            };
        }
      },
    };
  };
  closeConfirmationModal = () => {
    this.setState({
      confirmationModalProps: {
        isOpen: false,
        descriptionText: '',
      },
    });
  };
  createRow = (rowData: any, colDef: any): TransposedColDef => {
    // setup observers if applicable
    const observed = get('observes', colDef); // get observed props from current colDef
    const observer = get('dataIndex', colDef);
    if (!isNil(observer) && !isNil(observed)) {
      if (isNil(this.observers[observed])) {
        this.observers[observed] = {
          [observer]: { dataApi: colDef.dataApi },
        };
      } else {
        const existingObservers = this.observers[observed];
        this.observers[observed] = {
          ...existingObservers,
          [observer]: { dataApi: colDef.dataApi },
        };
      }
    }

    const data = isArray(rowData) ? rowData : [rowData];
    return data.reduce(
      (acc: Record<string, any>, dataItem: any) => {
        const id = dataItem.id || dataItem.product;
        const modifiedRecord = {
          [id]: dataItem[colDef.dataIndex],
          ...acc,
        };
        return modifiedRecord;
      },
      {
        headerText: colDef.text,
        dataKey: colDef.dataIndex,
        calculation: colDef.calculation,
        valueTests: colDef.valueTests,
        onlyCalcOnParamChange: colDef.onlyCalcOnParamChange,
        hidden: colDef.hidden,
      }
    );
  };

  getOriginalValue = (id: string, attrName: string) => {
    const { rowData } = this.state;
    return get(
      attrName,
      find((row) => row.id === id || row.product === id, rowData)
    );
  };

  transposeRowsAndColumns(
    rowData: any,
    colDefs: any,
    actions: any,
    multiRangeEditors: MultiRangeEditors,
    dependents: any = null
  ) {
    const newTransposed: TransposedColDef[] = colDefs.map(partial(this.createRow, [rowData]));
    const sortedRowData = sortBy(rowData, (d) => {
      const sortIndex = this.props.sortedStyleColorIds.indexOf(d.id ?? d.product);
      // We use 1000 for ids not in sorted to push the "hidden" columns to the end just to ensure consistent placement.
      return sortIndex >= 0 ? sortIndex : 1000;
    });

    const headerCol: TransposedColDef[] = [
      {
        headerName: '',
        field: 'headerText',
        pinned: 'left',
      },
    ];
    const rowDataCols = sortedRowData.map(partial(this.createColumn, [colDefs])) as TransposedColDef[];
    const newColData = headerCol.concat(rowDataCols);
    // added the above assertion because the type wasn't recognizing that pinned was "left" and not just a string
    const createdStyleColors = rowData
      .filter((i: BasicPivotItem) => !isNil(i[COLOR_SUBMITTED_ATTR]) && i[COLOR_SUBMITTED_ATTR] !== 'Undefined')
      .map((i: BasicPivotItem) => i.id ?? i.product);

    this.setState({
      submittedStyleColors: createdStyleColors,
      rowData,
      colDefs,
      actions,
      transposedData: newTransposed,
      transposedDefs: newColData,
      dependentData: dependents,
      isLoading: null,
      multiRangeEditors,
    });
  }

  handlePendingCellUpdate(value: string, pendingCellInfo: PendingCellInfo) {
    if (!isNil(pendingCellInfo.validation) && !pendingCellInfo.validation.isValid) {
      const { invalidValue, initialValue, invalidLevel } = pendingCellInfo.validation;
      const initial = isNil(initialValue) || isEmpty(initialValue) ? 'Empty' : initialValue;
      const invalidDescription = !isNil(invalidLevel)
        ? `"A value at the style color level was expected, but a ${invalidLevel} was received."`
        : undefined;
      const message = (
        <AsyncValidationErrorMessage
          initial={`"${initial}"`}
          invalidValue={`"${invalidValue}"`}
          invalidDescription={invalidDescription}
        />
      );
      toast.error(message, {
        autoClose: false,
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  handleCellValueChange = async (
    styleColorId: string,
    params: ValueSetterParams,
    colDefs: (StyleEditConfigColumn | ColorHeaderConfigColumn)[]
  ) => {
    const { colDef, data, api: gridApi, oldValue } = params;
    const newValue = params.newValue;
    const { field } = colDef; // store field (dataKey) of modified column to know which data field to modify
    const { dataKey } = data;
    const observers = this.observers[dataKey]; // get dataKey(s) of row to clear
    const rowIndex = colDefs.findIndex((def) => {
      return def.dataIndex === params.node?.id;
    });
    const colInfo = colDefs[rowIndex];
    const { inputParams, inputType } = colInfo;
    // no need to update; validSizes updates won't pass this check, so always update validSizes
    if (isEqual(oldValue, newValue)) {
      return;
    } else if ((dataKey as string).toLowerCase().indexOf('validsizes') >= 0) {
      // FIXME: This is a mess, however, the server does not currently return arrays as arrays
      // so until that's fixed, we've got to do it this way :/
      if (isEqual(arrayStringToArray(oldValue), arrayStringToArray(newValue))) {
        return;
      }
    } else if (
      colInfo &&
      field &&
      colInfo.inputType === 'textValidatorAsync' &&
      newValue === PENDING_VALIDATION_VALUE
    ) {
      return; // skip posting unvalidated values
    }

    // clear dependentProp fields
    if (!isNil(observers) && !isNil(field)) {
      const styleColor: BasicPivotItem = {} as any;
      if (gridApi) {
        gridApi.forEachNode((rowNode: IRowNode<any>) => {
          styleColor[rowNode.data.dataKey] = rowNode.data[field];
        });
        gridApi.forEachNode(async (rowNode: IRowNode<any>) => {
          const observer = observers[rowNode.data.dataKey];

          if (isNil(observer)) {
            return;
          }

          if (observer.dataApi) {
            const api = observer.dataApi as any;
            const url = getUrl(processApiParams(api, styleColor));
            Axios.get(url).then((resp) => {
              if (resp.data && resp.data.data) {
                const respData = resp.data.data;
                const updatedValue =
                  typeof respData[params.newValue] === 'object'
                    ? respData[params.newValue][0]
                    : respData[params.newValue];
                rowNode.setDataValue(field, updatedValue);
              }
            });
          }
        });
      }
    }

    if (!isNil(this.state.actions) && !isNil(this.state.actions.patch)) {
      const { actions } = this.state;
      const { patch } = actions;

      switch (patch.clientHandler) {
        case 'planningParams':
        case 'lifecycleParams':
        case 'adjustments': {
          let updatedValue: string | null;
          if (isNil(newValue)) {
            updatedValue = '';
          } else if ((isNaN(newValue) || newValue === '') && inputType === 'integer' && inputParams?.nullable) {
            updatedValue = null;
          } else if (newValue.weekNo) {
            updatedValue = newValue.weekNo;
          } else {
            updatedValue = newValue;
          }

          if (isNil(colInfo.inputType) || colInfo.inputType !== 'multiRangeEditors') {
            // Some attributes in lifecycle params were trying to post with the attribute: attatched
            const key = dataKey.split(':')[1] || dataKey.split(':')[0];
            await updateLifecycleParams(styleColorId, { [key]: updatedValue })
              .then(() => {
                this.context.clearPivotCache();
                this.props.refreshSections(this.getComponentId());
              })
              .catch((e) => {
                toast.error('An error occured updating your lifecycle parameters');
                ServiceContainer.loggingService.error(
                  'An error occured updating the lifecycle parameters in Style edit lifecylce paramters',
                  e.stack
                );
              });
            break;
          }

          // need to calculate necessary fields to post
          // @ts-ignore
          const getDataFn = (partial(getDataFromKey, [params]) as unknown) as any; // REVERT THIS
          const calculatedFields = colDefs
            .filter((def) => !def.editable && def.calculation)
            .map((def) => {
              return {
                dataIndex: def.dataIndex,
                calculation: def.calculation,
                valueTests: def.valueTests,
              };
            });

          const updates = {};
          if (gridApi) {
            calculatedFields.forEach(({ dataIndex, calculation }) => {
              if (calculation) {
                const calculated = executeCalculation(this.math, calculation, getDataFn);
                updates[dataIndex] = calculated;
              }
            });
          }

          const update = {
            [dataKey]: updatedValue,
            ...updates,
          };

          // Send update to rx-js stream
          this.updateItemSubject && this.updateItemSubject.next({ styleColorId, update });
          break;
        }
        case 'colorOptions': {
          const strippedDataKey = dataKey
            .replace('attribute:', '')
            .replace(':id', '')
            .replace(':name', '');
          const updatedValue = {
            parent: [this.props.styleId],
            id: styleColorId,
            [strippedDataKey]: newValue,
          };

          // need to send cccolorid when cccolor is changed
          // FIXME: This shouldn't be done manually, however, dependents api is returning blank atm.
          if (dataKey === CC_COLOR) {
            updatedValue['cccolorid'] = size(newValue) > 0 ? newValue.split(' ')[0] : '';
          }

          await updateStyleItem(updatedValue)
            .then(() => {
              this.context.clearPivotCache();
              this.props.refreshSections(this.getComponentId());
            })
            .catch((e) => {
              toast.error('An error occured updating your style');
              ServiceContainer.loggingService.error(
                'An error occured updating the style edit in styleeditsection',
                e.stack
              );
            });

          break;
        }
        case 'rangingParams': {
          const { propagateSelection, multiSectionIndex, multiSectionData, multiSectionLabel } = this.props;

          // handle forward propagation of data and sending update to server
          if (!isNil(propagateSelection) && !isNil(multiSectionIndex) && multiSectionData) {
            const selection = {
              id: field,
              dataIndex: dataKey,
              newValue,
              oldValue,
            };

            // determine fields to calculate from config (exclude ssg)
            const toCalculate: string[] = multiSectionData.config.propagatingIndices.filter(
              (dataIndex: string) => dataIndex !== SPECIAL_STORE_GROUP
            );
            if (toCalculate.indexOf(dataKey) >= 0) {
              // if fields are blank, wait to calculate storeCount until fields are autofilled
              if (newValue.length === 0) {
                await propagateSelection(multiSectionIndex, selection);
              } else if (toCalculate.indexOf(dataKey) >= 0 && !isNil(this.gridApi) && multiSectionLabel && field) {
                const postData = toCalculate.map((dataIndex) => {
                  if (isNil(this.gridApi)) {
                    throw new Error('Grid api should always exists');
                  }
                  const rowNode = this.gridApi.getRowNode(dataIndex);
                  const rowNodeData = rowNode?.data[field];
                  return { [dataIndex]: rowNodeData };
                });
                await AssortmentClient.calculateStoreCount(styleColorId, multiSectionLabel, postData).then(
                  (calculatedStoreCount) => {
                    return propagateSelection(multiSectionIndex, selection, calculatedStoreCount);
                  }
                );
              }
            } else {
              await propagateSelection(multiSectionIndex, selection);
            }
          }

          break;
        }
        default:
          noop();
      }
    }
    if (colInfo.shouldRefresh === 'full') {
      this.props.refresh();
    } else if (colInfo.shouldRefresh === 'sections') {
      this.props.refreshSections(null);
    } else if (colInfo.refreshSectionAfterEdit) {
      this.getSectionDataAndConfig();
    }
  };

  handleSetValue = (styleColorId: string, dependentData: DependentData | null, params: ValueSetterParams) => {
    const { data, newValue, api, colDef } = params;

    if (data.dataKey === SPECIAL_STORE_GROUP && !isNil(this.gridApi)) {
      //this is prevent bug when user open SSG modal and not selecte any SSG
      if (!newValue) {
        data[styleColorId] = null;
        return true;
      }
      // need to get and update store_count too when updating ssg
      const columnKey = colDef.field;
      const storeCountNode = this.gridApi.getRowNode('store_count');
      // update ssg as usual
      if (isArray(newValue)) {
        data[styleColorId] = newValue;
        if (storeCountNode && columnKey) {
          storeCountNode?.setDataValue(columnKey, null);
          this.gridApi.refreshCells();
        }
        return true;
      }
      data[styleColorId] = newValue.id;
      if (storeCountNode && columnKey) {
        storeCountNode.setDataValue(columnKey, newValue.store_count ? newValue.store_count : 0);
        this.gridApi.refreshCells();
      }
      return true;
    }

    if (data.dataKey !== RANGE_CODE) {
      data[styleColorId] = newValue;
      return true;
    }

    if (api) {
      const rowNodeSizes = api.getRowNode(VALID_SIZES);
      // since getRowNode now returns '| undefined' we need a check for this (v26 typing update)
      if (!isNil(rowNodeSizes)) {
        if (newValue == null) {
          data[styleColorId] = newValue;
          rowNodeSizes.data[styleColorId] = [];
          return true;
        }
        const newValRange = newValue.value ? newValue.value : newValue;
        const newSizeRanges = !isNil(dependentData) ? [...dependentData[newValRange]] : [];

        // Set valid sizes and size range
        rowNodeSizes.data[styleColorId] = newSizeRanges;
        data[styleColorId] = newValRange;
        return true;
      } else return false;
    } else {
      return false;
    }
  };

  handleHeaderGridReady = (headerGridApi: GridApi, headerGridColumnApi: ColumnApi) => {
    this.headerGridApi = headerGridApi;
    this.headerGridColumnApi = headerGridColumnApi;
    this.reorderAndFilterColumns(this.headerGridColumnApi);
    setGridRowHeights(this.state.colDefs, this.headerGridApi);
  };

  fetchAssortmentRules = (): Promise<AssortmentRulesResponse> => {
    return Promise.all([
      Axios.get('/api/assortment/default/lifecycleParams?appName=Assortment'),
      Axios.get('/api/assortment/default/rangingParams?appName=Assortment'),
    ]).then((resp) => {
      return {
        lifecycleParams: resp[0].data.data,
        rangingParams: resp[1].data.data,
      };
    });
  };

  renderAddColorModal = () => {
    const { addColor, existingStyleColors, showOnlyPreexist } = this.props;
    const { availableColorsToAdd, isAddingColor, addColorModalOpen, rowData, selectedNewColor } = this.state;
    const existingNotInAsst = getExistingColorsNotInAsst(existingStyleColors);

    if (isNil(availableColorsToAdd) || isNil(existingStyleColors)) {
      return null;
    }
    const allColors = availableColorsToAdd;
    const availColors = allColors;
    return (
      <Dialog
        open={addColorModalOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            // Handle dialog close for reasons other than backdrop click
            this.setState({
              addColorModalOpen: false,
            });
          }
        }}
      >
        <DialogTitle>Add New Color</DialogTitle>
        <DialogContent>
          <div className={''} style={{ width: 200 }}>
            <Overlay type="loading" visible={isAddingColor} fitParent={true} />
            <ReactSelect
              className={style({ width: '100%', minWidth: '100%' })}
              options={availColors
                .map((color) => {
                  const isExisting = color.existing > 0;
                  return {
                    value: color.value,
                    label: `${isExisting ? '*' : ''} ${color.value}`,
                    isExisting,
                  };
                })
                .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))}
              onChange={(event) => {
                if (event && !isArray(event)) {
                  this.setState({
                    selectedNewColor: event.value,
                  });
                }
              }}
              blurInputOnSelect={true}
              menuIsOpen={true}
              styles={{
                menu: (props) => ({
                  ...props,
                  position: 'relative',
                }),
                option: (props, state) => {
                  return {
                    ...props,
                    color: state.data.isExisting ? 'blue' : 'black',
                  };
                },
              }}
            />
            <div className={StyleEditSectionStyles.checkboxContainer}>
              <button
                className={StyleEditSectionStyles.iconButtonCancel}
                onClick={() =>
                  this.setState({
                    addColorModalOpen: false,
                  })
                }
              >
                <Icon className="fal fa-fw fa-2x fa-times" />
              </button>
              <button
                className={StyleEditSectionStyles.iconButtonSave}
                onClick={async () => {
                  if (rowData == null || rowData.length <= 0) {
                    this.setState({
                      addColorModalOpen: false,
                    });
                    return;
                  }
                  this.setState({
                    isAddingColor: true,
                  });
                  if (addColor && selectedNewColor) {
                    // update assortment rules
                    await this.fetchAssortmentRules();
                    await addColor(
                      selectedNewColor,
                      rowData.find((r: BasicPivotItem) => r.id == this.props.sortedStyleColorIds[0])
                    );
                  }
                  this.setState({
                    addColorModalOpen: false,
                    isAddingColor: false,
                  });
                  this.props.refreshSections(this.getComponentId());
                  this.getSectionDataAndConfig();
                }}
              >
                <Icon className="fal fa-2x fa-check" />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  // We need to memoize this due to generating a two new objects rather based on the
  // row data (resulting in new pointers every render). The saner approach would be to actually *store* the splits in state after
  // transpose.
  memoGenRowData = memoize((transposedData: TransposedColDef[] | null) => {
    const pinnedData = transposedData ? [transposedData[0]] : undefined;
    const rawData = transposedData ? transposedData.slice(1) : undefined;
    return {
      pinnedData,
      rawData,
    };
  });

  //#region AgGridOptions
  // We create AgGridOptions at class level to ensure pointers don't change triggering unnecessary ag-grid render cycles

  getRowId = (params: AgGrid.GetRowIdParams) => {
    return params.data.dataKey;
  };
  getRowHeight = (params: RowHeightParams<any, any>): number => {
    if (this.state.colDefs == null) return StyleEditSectionStyles.defaultRowHeight;
    const def = this.state.colDefs.find((i: any) => i.dataIndex === params.data.dataKey);
    return getRowHeight(def);
  };

  isExternalFilterPresent = () => true;

  getContextMenuItems = () => {
    return [
      'expandAll',
      'contractAll',
      'copy',
      'resetColumns',
      {
        name: 'CSV Export',
        action: this.onCsvExport,
      },
      {
        name: 'Excel Export',
        action: this.onExcelExport,
      },
    ];
  };

  onGridReady = (params: AgGrid.GridReadyEvent) => {
    if (params.api && params.columnApi) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.reorderAndFilterColumns(params.columnApi);
      setGridRowHeights(this.state.colDefs, this.gridApi);

      const columnDefs = this.state.transposedDefs;
      const { pinnedData } = this.memoGenRowData(this.state.transposedData);
      const headerGrid = (
        <StyleEditSectionHeaderGrid
          frameworkComponents={this.frameworkComponents}
          nonFrameworkComponents={this.nonFrameworkComponents}
          rowData={pinnedData}
          columnDefs={columnDefs}
          onHeaderGridReady={this.handleHeaderGridReady}
        />
      );

      if (headerGrid && this.props.getHeaderElement) {
        this.props.getHeaderElement(headerGrid);
      }
    }
  };

  onCellEditingStopped = (event: AgGrid.CellEditingStoppedEvent) => {
    const { colDef } = event;
    colDef.cellEditorPopup = false;
  };

  rowClassRules = {
    [StyleEditSectionStyles.editableRow]: (params: RowClassParams<any>) => {
      return params.data.dataKey === ADJUSTED_APS;
    },
  };

  //#endregion
  render() {
    const { isLoading, transposedData } = this.state;
    if (!this.props.expanded) {
      // in the mounted but in un-expanded state, renderer nothing to save resources
      // note: this needs to be a real element, in order for the inViewport HOC to detect it's existence
      return <div></div>;
    }
    if (isLoading) {
      return <Overlay type="loading" visible={true} fitParent={true} qaKey="StyleEditSectionOverlay" />;
    }
    if (this.props.inViewport === false && this.props.enterCount === 0) {
      // this is a guard for MultiSection, in order to keep a large number of sections from rendering all at once
      // is uses a HOC to check if the component is in the viewport, otherwise we just render a blank space for it
      return <div style={{ height: '300px', width: '1px' }}></div>;
    }

    const { multiSectionData } = this.props;
    const { actions } = this.state;
    let addColorButton;

    if (!isNil(actions)) {
      if (actions.addColor) {
        addColorButton = <StyleEditAddColorButton onClick={this.addColor} />;
      }
    }

    const columnDefs = this.state.transposedDefs;
    const { rawData } = this.memoGenRowData(transposedData);

    return (
      <React.Fragment>
        {!isNil(multiSectionData) && <StyleEditGridTitle title={multiSectionData.text} />}
        <div className={classes('ag-theme-material', StyleEditSectionStyles.sectionGrid)}>
          <ConfirmationModal {...this.state.confirmationModalProps} />
          <AgGridReact
            rowData={rawData}
            columnDefs={columnDefs}
            defaultColDef={DEFAULT_COLDEF}
            stopEditingWhenCellsLoseFocus={true}
            singleClickEdit={true}
            components={this.allGridComponents}
            headerHeight={0}
            suppressBrowserResizeObserver={true}
            getRowId={this.getRowId}
            getRowHeight={this.getRowHeight}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            getContextMenuItems={this.getContextMenuItems}
            onGridReady={this.onGridReady}
            rowClassRules={this.rowClassRules}
            domLayout={'autoHeight'}
            onCellEditingStopped={this.onCellEditingStopped}
            processCellFromClipboard={(params) => {
              const nodeData = params.node?.data;
              const inputType = nodeData.inputType;

              // If input type is not number, accept the paste as is
              if (inputType !== 'integer') {
                return params.value;
              }

              // If input type is number, process the value
              if (inputType === 'integer') {
                // Strip non-digit and non-period characters from the pasted value
                const cleanedValue = params.value.replace(/[^0-9.]/g, '');
                let parsedValue = parseFloat(cleanedValue);

                // If the parsed value is not a number, return null
                if (isNaN(parsedValue)) {
                  return null;
                }

                // Check if the original value ends with a percent symbol or percent renderer (percents can copy two different ways)
                if (params.value.trim().endsWith('%')) {
                  parsedValue = parsedValue / 100;
                }

                return parsedValue;
              }

              // return the original value if no conditions are met
              return params.value;
            }}
          />
          {addColorButton}
          {this.renderAddColorModal()}
        </div>
      </React.Fragment>
    );
  }
}
export const ViewPortSensitiveStyleEditSection = handleViewport(StyleEditSection, {
  threshold: 0,
});

import * as React from 'react';
import { isNil, noop } from 'lodash/fp';
import styles from './CartCard.styles';
import Grid from '@material-ui/core/Grid';
import { ColorSwatchItemProps } from '../ColorSwatch/ColorSwatchItem';
import { Typography } from '@material-ui/core';
import ColorSwatch from '../ColorSwatch/ColorSwatch';
import InputGeneric, { InputCharacterWhitelist } from '../Inputs/InputGeneric/InputGeneric';
import { InputGenericProps } from '../Inputs/InputGeneric/InputGeneric';
import { resolvePath } from 'src/cdn';
import noImagePath from 'src/common-ui/images/noimage.jpg';

import { style } from 'typestyle';

type CartStyleItem = {
  id: string;
};
type BasicColorSwatchItem = { id: string; swatchPath: string; colorId: string };
export interface Props {
  id: string;
  description: string;
  imgSrc: string;
  swatches?: BasicColorSwatchItem[];
  editable: boolean;
  valid: boolean;
  noImageUrl: string;
  onImageLoad?: ((event: React.SyntheticEvent<HTMLImageElement>) => void) | undefined;
  onChangeId: (text: string | number) => void;
  onChangeDescription: (text: string | number) => void;
  onColorEdit: (item: CartStyleItem) => void;
  onDuplicateColor: (item: CartStyleItem) => void;
  onColorRemove: (item: CartStyleItem) => void;
  getSwatchUrl: (colorId: string) => string;
  canAddNewStyles: boolean;
}
const noImage = resolvePath(noImagePath);
export class CartCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
    this.handleStyleRemoveClick = this.handleStyleRemoveClick.bind(this);
    this.handleColorEditClick = this.handleColorEditClick.bind(this);
  }

  buildSwatches(items: BasicColorSwatchItem[] | null) {
    // copied from HistoryStylePane
    let swatches: ColorSwatchItemProps[] = [];
    if (items === null) {
      return swatches;
    }
    swatches = items.map((item) => {
      return {
        id: item.id,
        colorId: item.colorId,
        noImageUrl: '',
        swatchPath: '',
      };
    });

    return swatches;
  }

  colorSwatch(
    values: ColorSwatchItemProps[],
    onChange: (id: string, position: number) => void,
    selected?: number,
    className?: string
  ) {
    const swatchVals = values.map((value) => {
      return {
        swatchPath: value.swatchPath,
        id: value.id,
        colorId: value.colorId,
        selected: false,
        noImageUrl: '',
      };
    });

    return (
      <ColorSwatch
        noImageUrl={this.props.noImageUrl}
        className={className}
        onChangeColor={onChange}
        selected={-1}
        colors={swatchVals}
        getSwatchUrl={this.props.getSwatchUrl}
        renderTooltip={true}
      />
    );
  }

  handleColorEditClick() {
    if (!isNil(this.props.onColorEdit)) {
      this.props.onColorEdit({
        id: this.props.id,
      });
    }
  }

  handleDuplicateClick() {
    if (!isNil(this.props.onDuplicateColor)) {
      this.props.onDuplicateColor({
        id: this.props.id,
      });
    }
  }
  handleStyleRemoveClick() {
    if (!isNil(this.props.onColorRemove)) {
      this.props.onColorRemove({
        id: this.props.id,
      });
    }
  }

  render() {
    const {
      id,
      description,
      swatches,
      imgSrc,
      onChangeId,
      onChangeDescription,
      editable,
      canAddNewStyles,
    } = this.props;

    let swatchJsx;
    const finalSrc = imgSrc;
    const idEditProps: InputGenericProps = {
      autoFocus: false,
      onBlur: onChangeId,
      value: id,
      editable,
      valid: this.props.valid,
      className: style({
        fontSize: '0.9rem',
      }),
      whitelist: InputCharacterWhitelist.alphaNumericSeparators,
      maxLength: 250,
    };

    const descriptionEditProps: InputGenericProps = {
      autoFocus: false,
      onBlur: onChangeDescription,
      value: description,
      editable,
      valid: true,
      className: style({
        fontWeight: 600,
        height: 30,
      }),
      whitelist: InputCharacterWhitelist.alphaNumericSpacesSeparators,
      maxLength: 250,
    };

    if (swatches) {
      swatchJsx = (
        <div className={styles.swatchContainerActual} data-qa="cart-card-swatches">
          {this.colorSwatch(this.buildSwatches(swatches), noop, undefined, styles.swatches)}
        </div>
      );
    }

    return (
      <Grid container={true} spacing={2} className={styles.card} data-qa-component={'CartCard'} data-qa-key={id}>
        <Grid item={true} xs={7} className={styles.gridLeft}>
          <div className={styles.imageContainer} data-qa="cart-card-image">
            <img style={{ width: '100%' }} src={finalSrc ? finalSrc : noImage} />
          </div>
          <div className={styles.titleColumn}>
            <div className={styles.nameContainer} data-qa="cart-card-text-inputs">
              <InputGeneric {...descriptionEditProps} />
              <InputGeneric {...idEditProps} />
            </div>
            <div className={styles.swatchContainer}>
              <span className={styles.linkButton} onClick={this.handleColorEditClick} data-qa="edit-color-btn">
                EDIT COLORS:
              </span>
              {swatchJsx}
            </div>
          </div>
        </Grid>
        <Grid container={true} item={true} xs={5} spacing={2} className={styles.gridRight}>
          <Grid item={true} xs={2} className={styles.centerContents} />
          <Grid item={true} xs={3} className={styles.centerContents}>
            <Typography variant="h6">{swatches ? swatches.length : undefined}</Typography>
          </Grid>
          <Grid item={true} xs={6} className={styles.linkColumn}>
            {this.props.children}
            <span className={styles.linkButton} onClick={this.handleStyleRemoveClick} data-qa="remove-style-btn">
              REMOVE
            </span>
            {canAddNewStyles && (
              <span
                className={styles.duplicateButton}
                onClick={this.handleDuplicateClick}
                data-qa="duplicate-style-btn"
              >
                DUPLICATE
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

import * as React from 'react';
import { isNaN, isNil, isString, round } from 'lodash';
import { classes } from 'typestyle';
import coalesce from 'src/utils/Functions/Coalesce';
import { arrowlessNumberInputStyle, inputStyle } from './IntegerEditor.styles';
import { IntegerEditorProps, IntegerEditorState } from './IntegerEditor.types';
import { formatInput, isNumberValid, processStateValue } from './IntegerEditor.utils';

export default class IntegerEditor extends React.Component<IntegerEditorProps, IntegerEditorState> {
  constructor(props: IntegerEditorProps) {
    super(props);

    const { inputParams, passedInt, value } = props;
    const nullable = inputParams ? inputParams.nullable : undefined;
    const processedInt = coalesce(passedInt, value, null);
    const isValid = isNumberValid(processedInt, inputParams) && processedInt !== '';
    let parsedInt = isValid ? processedInt : nullable ? null : 0;

    // 'process' percentage value after running number validations
    if (!isNil(parsedInt) && this.props.inputParams?.percent) {
      parsedInt = round(parsedInt * 100, 2);
    }

    this.state = {
      int: parsedInt,
    };
  }

  getValue() {
    const { int } = this.state;
    const { inputParams, passedInt, value } = this.props;
    const currentValue = passedInt ?? value;
    return processStateValue(int, currentValue, inputParams);
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedInput = formatInput(value); // potential output formats (2, NaN for empty strings, '2.')
    const setToNull = !isString(formattedInput) && isNaN(formattedInput);
    this.setState({
      int: setToNull ? null : formattedInput,
    });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.props.stopEditing();
    }
  };

  render() {
    const { int } = this.state;
    const value = int === null ? '' : int;
    const position = this.props.regularPosition ? 'inherit' : 'absolute';

    return (
      <input
        type="text"
        value={value}
        onChange={this.onChange}
        onKeyDown={this.handleKeyDown}
        style={{ position }}
        className={classes(inputStyle, arrowlessNumberInputStyle)}
        autoFocus={true}
      />
    );
  }
}

import { resolvePath } from 'src/cdn';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { AppType } from 'src/services/configuration/codecs/bindings.types';

export const noImage = resolvePath(noImagePath);

export const API_URL_PREFIX = '/asst';

export const TOP_DOWN_IDENTIFIER = 'TD_';
export const FLOWSTATUSPROP = 's5flowstatus';
export const ASSORTMENT = AppType.Assortment;
export const TOP_DOWN = AppType.TDAnalysis;
export const CONFIGURATOR = AppType.Configurator;
export const SLSR_BAND = 'slsr_band';
export const PERFORMANCE = 'ccperf';

// Tab ID's
export const HINDSIGHTING = 'Hindsighting';
export const ASSORTMENT_STRATEGY = 'AssortmentStrategy';
export const ASSORTMENT_BUILD = 'AssortmentBuild';
export const ASSORTMENT_ANALYSIS = 'AssortmentAnalysis';
export const RECONCILIATION = 'AnalysisAndRecon';
export const REPORTING = 'Reporting';
export const ALLOCATION = 'Allocation';

// Tab Paths's
export const HINDSIGHTING_CONTEXT = 'hindsighting';
export const ASSORTMENT_CONTEXT = 'assortment';
export const ASSORTMENT_STRATEGY_PATH = 'assortment-strategy';
export const ASSORTMENT_BUILD_PATH = 'assortment-build';
export const ASSORTMENT_ANALYSIS_PATH = 'assortment-analysis';
export const RECONCILIATION_PATH = 'reconciliation';
export const REPORTING_PATH = 'reporting';
export const ALLOCATION_PATH = 'allocation';

// Attribute
export const CC_COLOR = 'attribute:cccolor:id';
export const CC_COLOR_ID = 'attribute:cccolorid:id';
export const IMG_URI = 'attribute:img:id';
export const DEPARTMENT_ID = 'member:department:id';
export const STYLE_ID = 'member:style:id';
export const STYLE_NAME = 'member:style:name';
export const STYLE_DESCRIPTION = 'member:style:description';
export const STYLE_COLOR_DESCRIPTION = 'member:stylecolor:description';
export const STYLE_COLOR_ID = 'member:stylecolor:id';
export const STYLE_COLOR_NAME = 'member:stylecolor:name';
export const CLASS_ID = 'member:class:id';
export const CLASS_NAME = 'member:class:name';
export const SUBCLASS_ID = 'member:subclass:id';
export const SUBCLASS_NAME = 'member:subclass:name';
export const EXP_POWERDRIVER_ID = 'level:subclass:id'; // express powerdriver attribute, special mapping
export const STYLE_COLOR_IS_REMOVABLE = 'attribute:isremovable:id';
export const TIME = 'time';
export const FUNDED = 'isfunded';
export const SPECIAL_STORE_GROUP = 'ssg';
export const STORE_COUNT = 'store_count';
export const COLOR_HEADER = 'color_header';
export const ADJUSTED_APS = 'adjaps';
export const SLSR = 'slsr';
export const AGG_DEPARTMENT = 'levels:department';
export const SWATCHES = 'swatchids';
export const ATTRIBUTE = 'attribute';
export const ID = 'id';
export const NAME = 'name';
export const DESCRIPTION = 'description';
export const lookBackPeriodProp = 'ytd_key';
export const XTYPE_UNIQUE = 'unique';
export const RANGE_CODE = 'ccrangecode';
export const VALID_SIZES = 'attribute:validsizes:id';
export const IS_PUBLISHED = 'dc_publish';
export const USERADJ = 'dc_useradj';
export const ONORDERREVISION = 'dc_finrev';
export const SLSUOVERRIDE = 'weekadjslsu';
export const FINAL_QTY = 'dc_finalqty';
export const LAST_PUBLISHED = 'dc_last_pub';
export const FINALSLSU = 'slsu';
export const ATTR_GRADE = 'attribute:grade:id';
export const ATTR_CLIMATE = 'attribute:strclimate:id';
export const ATTR_MENSCAPACITY = 'attribute:strmenscapacity:id';
export const ATTR_WOMENSCAPACITY = 'attribute:strwomenscapacity:id';
export const ATTR_FUNDED = 'attribute:isfunded:id';
export const ATTR_SSG = 'attribute:ssg:id';
export const IS_LOCKED = 'is_locked';

export const PUBLISHED_TEXT = 'PUBLISHED';
export const UNPUBLISHED_TEXT = 'PLANNED';
export const PARTIAL_PUBLISHED_TEXT = 'PARTIAL';
export const NO_RECEIPTS_TEXT = 'NO RECEIPTS PLANNED';

// Grid defaults
export const externalGridSearchFields = [
  'name',
  'description',
  STYLE_NAME,
  STYLE_COLOR_NAME,
  STYLE_DESCRIPTION,
  STYLE_COLOR_DESCRIPTION,
];

const ADD_EXISTING_STYLE_KEY = 'add-existing';
const ADD_EXISTING_STYLECOLOR_KEY = 'add-existing-stylecolors';
const ADD_SIMILAR_STYLE_KEY = 'add-similar';
const ADD_SIMILAR_STYLECOLOR_KEY = 'add-similar-stylecolors';
const SELECT_STYLES_PATH = '/bottom-up/assortment-build/select-styles';

export const ADD_EXISTING_PATH = `${SELECT_STYLES_PATH}/${ADD_EXISTING_STYLE_KEY}`;
export const ADD_EXISTING_STYLECOLOR_PATH = `${SELECT_STYLES_PATH}/${ADD_EXISTING_STYLECOLOR_KEY}`;
export const ADD_SIMILAR_STYLECOLOR_PATH = `${SELECT_STYLES_PATH}/${ADD_SIMILAR_STYLECOLOR_KEY}`;
export const ADD_SIMILAR_PATH = `${SELECT_STYLES_PATH}/${ADD_SIMILAR_STYLE_KEY}`;

// Dimension
export const DIMENSION_PRODUCT_LIFE = 'prodlife';
export const DIMENSION_PRODUCT = 'product';
export const DIMENSION_CLUSTER = 'cluster';

// Important Levels
// Bottom Levels
export const LEVEL_MERCHCAT = 'level:merchcat';
export const LEVEL_GRADE = 'level:grade';
export const LEVEL_WEEK = 'level:week';
export const LEVEL_STORE = 'level:store';
export const LEVEL_STYLE = 'level:style';
export const LEVEL_STYLE_COLOR = 'level:stylecolor';

// TOP LEVELS
export const LEVEL_PROD_ROOT = 'level:prodrootlevel';

export const LEVEL_MONTH = 'level:month';

export const STYLE_REVIEW = 'styleReview';
export const STYLE_COLOR_REVIEW = 'styleColorReview';

export const ISASSORTMENT = 'attribute:isassortment:id';
export const CCCOLORID = 'attribute:cccolor:id';

// TODO: Move this to a config
export const reorderGroups = [
  {
    id: PERFORMANCE,
    order: ['1', '2', '3', '4', '5'],
    nameMap: {
      '1': 'Top 20%',
      '2': 'Above Average',
      '3': 'Average',
      '4': 'Below Average',
      '5': 'Bottom 20%',
    },
  },
  {
    // period characters interfere with some of the grouping
    // but they get remapped into the name here
    // the special sorting for shopzilla is also done here
    id: 'attribute:sty_shopzilla_rating:name',
    order: ['ShopZilla 4\u20245 to 5', 'ShopZilla 4 to 4\u20245', 'ShopZilla 3\u20245 to 4', 'ShopZilla 0 to 1'],
    nameMap: {
      'ShopZilla 4\u20245 to 5': 'ShopZilla 4.5 to 5',
      'ShopZilla 4 to 4\u20245': 'ShopZilla 4 to 4.5',
      'ShopZilla 3\u20245 to 4': 'ShopZilla 3.5 to 4',
      'ShopZilla 0 to 1': 'ShopZilla 0 to 1',
    },
  },
  {
    // period characters interfere with some of the grouping
    // but they get remapped into the name here
    // the special sorting for shopzilla is also done here
    id: 'attribute:slsrnk:name',
    order: [1, '15', 2, '25', 3, '35', 4, '45', 5],
    nameMap: {
      [1]: '1',
      ['15']: '1.5',
      [2]: '2',
      ['25']: '2.5',
      [3]: '3',
      ['35']: '3.5',
      [4]: '4',
      ['45']: '4.5',
      [5]: '5',
    },
  },
];

export const FLOW_VALUES = ['NEW', 'FLOW', 'LOF'];
export const TYLY_VALUES = ['TY', 'LY'];
export const FLOORSET_VALUES = ['SELECTED', 'PREVIOUS', 'LAST YEAR'];

export const keys = {
  enter: 13,
  space: 32,
};

export const isReserved = (dataIndex: string) => {
  return (
    dataIndex.toLocaleLowerCase() === 'member:style:id' || dataIndex.toLocaleLowerCase() === 'member:stylecolor:id'
  );
};

// Companion View

export const COMPANION_EXPANDED_WIDTH = 300;
export const COMPANION_COLLAPSED_WIDTH = 100;

export const STYLE_SUBMITTED_ATTR = 'attribute:ccstylecreatedate:name';
export const LOCKED_AFTER_STYLE_SUBMIT = [
  'name',
  'description',
  'member:style:name',
  'member:style:description',
  'attribute:ccsizerange:name',
  'attribute:ccsizerange:id',
];

export const COLOR_SUBMITTED_ATTR = 'attribute:ccstylecolorcreatedate:name';
export const LOCKED_AFTER_COLOR_SUBMIT = [
  'attribute:cccolor:id',
  'attribute:cccolor:name',
  'attribute:cccolorid:id',
  'attribute:cccolorid:name',
];

// Ag Grid Editors
export const ALLOW_ENTER_EDITORS = ['text'];
export const BLOCK_ENTER_EDITORS = [
  'lifecycleParameters',
  'salesAdjustment',
  'popoverSelect',
  'receiptsAdjCalculator',
  'tabbedReceiptsAdjCalculator',
  'validValues',
  'textValidator',
  'multiRangeEditors',
];
export const EDITORS_TO_IGNORE_CHANGE_DETECTION = [
  'lifecycleParameters',
  'salesAdjustment',
  'receiptsAdjCalculator',
  'configurableDataModal',
];
export const POPOVER_BLOCK_CODES = ['Enter', 'Tab'];
export const ARRAY_EDITOR_COLUMN_ID_PREFIX = 'array_editor';

export const CROPPED_IMG_MAX_WIDTH = 300;
export const CROPPED_IMG_MAX_HEIGHT = 450;

export const CANCELMESSAGE = 'Data updated: Fetching new pivot results...';
export const ASSORTMENT_BUILD_FILTER_WARNING = 'Location filters do not apply on this view';
export const ASSORTMENT_BUILD_FILTER_ALL_WARNING = 'All filters do not apply on this view';
export const CACHED_DATA_EDITING_DISABLED_WARNING =
  'Editing is disabled while live data is loading. Cached data shown.';

export const UNINITIALIZED = '_empty_';

export const FAVORITE_EXCLUDE_KEYS = ['actions', 'main', 'massEditConfig', 'salesAdjustment', 'subheaderDropdowns'];

// mfp stuff dumped here
// dimension constants
export const PRODUCT = 'product';
export const LOCATION = 'location';
export const METRICS = 'metrics';
export const REVISIONS = 'revisions';
export const PRODLIFE = 'prodlife';

// version types
export const VARIANCE_VERSION = 'VarianceVersion';
export const SINGLE_VERSION = 'SingleVersion';
export const PERCENT_TO_TOTAL_VERSION = 'PercentToTotal';
export const PERCENT_TO_GRAND_TOTAL_VERSION = 'PercentToGrandTotal';
export type VERSION_TYPES =
  | typeof VARIANCE_VERSION
  | typeof SINGLE_VERSION
  | typeof PERCENT_TO_TOTAL_VERSION
  | typeof PERCENT_TO_GRAND_TOTAL_VERSION;
export const VERSION_TYPE_LIST = [
  VARIANCE_VERSION,
  SINGLE_VERSION,
  PERCENT_TO_TOTAL_VERSION,
  PERCENT_TO_GRAND_TOTAL_VERSION,
];

// scope type constants
export const SCOPETYPE_ACTUALS = 'actuals';
export const SCOPETYPE_PLAN = 'plan';
export const SCOPETYPE_SUBMITTED = 'submitted';
export const SCOPETYPE_APPROVED = 'approved';
export const SCOPETYPE_WORKING = 'working';

export const DEFAULT_DIMENSIONS = [PRODUCT, LOCATION, TIME] as const;

// revision constants
// TODO: move this to config or generate it
export const WP = 'wp';
export const OP = 'op';
export const RP = 'rp';
export const LP = 'lp';

// perspective constants
export const BOTTOM_UP = 'bottom_up';
export const MIDDLE_OUT = 'middle_out';
export const TOP_DOWN_MFP = 'top_down';
export const GLOBAL_DOWN = 'global_down';
// export const PERSPECTIVE_PATHS = [BOTTOM_UP, MIDDLE_OUT, TOP_DOWN, GLOBAL_DOWN] as const;

// export type PerspectivePaths = typeof BOTTOM_UP | typeof MIDDLE_OUT | typeof TOP_DOWN | typeof GLOBAL_DOWN;

// configurator constants
export const AVAILABLE = 'available';

// rows cols
export const ROWS = 'rows' as const;
export const COLUMNS = 'columns' as const;

// renderer
export const DEFAULT_PERCENT_FORMAT = '0.00%';

export const ROW_SELECTION_REQUIRED_TEXT = 'Select grid items for mass column edit';
export const ROW_SELECTIONS_UPDATING_TEXT = 'Some selections are still updating';
export const MISMATCHED_OPTIONS_TEXT = 'Selected rows options do not match, unable to mass column edit';

import { AvailableMembers, ScopeMemberInfo } from 'src/services/Scope.client';
import { get } from 'lodash';
import { RequiredDimension, Space } from 'src/space';
import { DimString } from 'src/state/scope/Scope.types';
import { PRODUCT, LOCATION, TIME } from '../Domain/Constants';
import { SettingsState } from 'src/state/settings/settings.slice';

export interface HierarchyValueGetter {
  // note that sometimes this isn't actually defined
  data: {
    space: RequiredDimension;
  };
  column: { getColId: () => RequiredDimension | string };
  // params.context is a special ag-grid object that can be added to the
  // <AgGridReact> as a prop
  // it needs to be on the component for this function to work correctly
  context: {
    availableMembers: Space<readonly ScopeMemberInfo[]>;
    entriesByKey: SettingsState['entriesByKey'];
  };
}

export const getMemberFromValue = (params: HierarchyValueGetter): string => {
  // groupedRows can come through here without any data
  if (!params.data) {
    return '';
  }
  const availableMembers = params.context.availableMembers;
  const colId = params.column.getColId();
  let name = '';

  const memberId = get(params.data.space, colId) as RequiredDimension | string;
  // const memberIdd = Lens.fromPath<HierarchyValueGetter>()(['data','space']).get(params);
  const memberSpace = colId;
  if (availableMembers && memberSpace && availableMembers[memberSpace]) {
    const member = availableMembers[memberSpace].find((member: ScopeMemberInfo) => member.id === memberId);
    name = member ? member.name : name;
  }

  return name || memberId || '';
};

export const getMemberFromValueNoAg = (colId: string, availableMembers: any, space: any): string => {
  let name = '';
  // get inferes a string type of string | number
  const memberId = get(space, colId) as RequiredDimension | string;
  const memberSpace = colId as keyof AvailableMembers['space'];
  if (availableMembers && memberSpace) {
    const member = availableMembers[memberSpace].find((member: ScopeMemberInfo) => member.id === memberId);
    name = member ? member.name : name;
  }

  return name || memberId || '';
};

export const isDefaultDimension = (dim: string | string[]): dim is DimString => {
  return dim === PRODUCT || dim === LOCATION || dim === TIME;
};

import { TenantConfig } from './bindings.types';
import { FlowStatus, zPrintSize, Placeholder } from './general';
import { Perspective } from './literals';
import { z } from 'zod';
import { SectionView } from './confdefnComponents';
import { filterInvalidItem, formatAndLogLocal, validateAtLevel } from './confdefnFilter';
import { concat, isEmpty, isNil, map } from 'lodash';
import { FabType, zClientDataApi, zConfigApi, zDataApi } from 'confdefnView';
import { BaseDefnsComponentProps } from 'confdefnComponentProps';

// Confdefn tab and section structure validators

const Section = z.object({
  id: z.string(),
  name: z.string(),
  icon: z.string(),
  pathSlot: z.string(),
  inPerspectives: z.array(Perspective),
  tooltip: z.string().optional(),
  /** Available views to display under section in LeftNav */
  views: z.array(SectionView),
});

export const LeftNavSection = Section.merge(
  z.object({
    defaultView: z.string().optional(),
    disabled: z.boolean().optional(),
    hidden: z.boolean().optional(),
  })
);

const DisabledTab = z.object({
  id: z.string(),
  name: z.string(),
  pathSlot: z.string(),
  disabled: z.literal(true),
  inPerspectives: z.array(Perspective),
});

const zContextButton = z.object({
  text: z.string().optional(),
  tooltip: z.string().optional(),
  inPerspectives: z
    .array(z.string())
    .optional()
    .default([]),
});

const zEditStyleButton = zContextButton.merge(
  z.object({
    path: z.string(),
  })
);

const zCartButton = zContextButton.merge(
  z.object({
    iconSrc: z
      .string()
      .optional()
      .default('far fa-cart-arrow-down'),
    text: z
      .string()
      .optional()
      .default('Cart'),
  })
);

// using `.and` instead because zDataApi is union type and `.merge` doesn't exist on that type
const zPlanBtn = zContextButton.and(zDataApi);

const zContextMfpModule = z.object({
  id: z.string(),
  pathSlot: z.string(),
  name: z.string(),
  siloId: z.string(),
  onlyIndirectMfpScoping: z
    .boolean()
    .optional()
    .default(false),
});

const zContextBookmarks = z.object({
  tabs: z.array(z.string()),
});

const zQuickRecon = BaseDefnsComponentProps('').merge(
  z.object({
    configApi: zConfigApi,
  })
);
const zQuickReconButton = zContextButton.merge(zQuickRecon);

export interface ContextMfpModule extends z.infer<typeof zContextMfpModule> {}
export interface ContextBookmarks extends z.infer<typeof zContextBookmarks> {}
export interface ContextButton extends z.infer<typeof zContextButton> {}
export interface EditStyleButton extends z.infer<typeof zEditStyleButton> {}
export interface CartButton extends z.infer<typeof zCartButton> {}
export interface QuickReconButton extends z.infer<typeof zQuickReconButton> {}

/* TODO: if this continues to grow, should we consider top level props mfp/asst?
{
  mfp: { module: ... },
  asst: { tabs: ..., undoBtn: ...}
}
*/
const zContext = z.object({
  tabs: z.array(z.string()),
  undoBtn: zContextButton.optional(),
  editStyleBtn: zEditStyleButton.optional(),
  cartBtn: zCartButton.optional(),
  planBtn: zPlanBtn.optional(),
  quickReconBtn: zQuickReconButton.optional(),
  mfpModule: zContextMfpModule.optional(),
  bookmarks: zContextBookmarks.optional(),
});

const zAppContext = z.record(zContext);

export interface Context extends z.infer<typeof zContext> {}
export interface AppContext extends z.infer<typeof zAppContext> {}

export const EnabledTab = z
  .object({
    id: z.string(),
    /** Determines if tab is enabled in the UI */
    disabled: z.literal(false),
    /** Selected section after tab loads */
    defaultSection: z.string(),
    /** Dash separated value used to generate the url of the selected tab  */
    pathSlot: z.string(),
    /** Sections to render in the LeftNav component */
    leftNavSections: z.array(LeftNavSection),
    /** Name of tab */
    name: z.string(),
    /** Perspectives of a tab */
    inPerspectives: z.array(Perspective),
  })
  .merge(
    z.object({
      hidden: z.boolean().optional(),
      flowStatusOverride: FlowStatus.optional(),
    })
  );
export const isEnabledTab = (tab: z.infer<typeof ConfDefnTab>): tab is z.infer<typeof EnabledTab> => {
  return !tab.disabled;
};

export const ConfDefnTab = z.union([EnabledTab, DisabledTab]);

const ScopeSelectorType = z.enum(['AssortmentScopeSelector', 'MfpScopeSelector']);
const ScopeWorkflow = z.enum(['in-season', 'pre-season']);
const HeaderbarType = z.enum(['AssortmentHeaderbar', 'MfpHeaderbar']);
export const DependentScopeType = z.enum(['MfpScope']);

const zDependentScopes = z.object({
  type: DependentScopeType,
});

// TODO: see about combining, using z.union on cart/worklist makes discriminatedUnion unhappy
export const zCartFabType = z.object({
  fabType: z.literal(FabType.cart),
  fabTooltip: z.string().optional(),
});
export const zWorklistFabType = z.object({
  fabType: z.literal(FabType.worklist),
  fabTooltip: z.string().optional(),
});

export const zFabTypePlanning = z.object({
  fabType: z.literal(FabType.planning),
  fabTooltip: z.string().optional(),
  dataApi: zPlanBtn.optional(),
  defnId: z.string().optional(),
});

export const zFabTypeButtonModal = z.object({
  fabType: z.literal(FabType.buttonModal),
  fabTooltip: z.string().optional(),
  configApi: zConfigApi.optional(),
});

export const zPostTextModalFab = z.object({
  fabType: z.literal(FabType.postTextModal),
  fabTooltip: z.string().optional(),
  text: z.string(),
  dataApi: zClientDataApi.optional(),
});

export const zFabTypePivot = z.object({
  fabType: z.literal(FabType.pivot),
  fabTooltip: z.string().optional(),
  fabIcon: z.string(),
  dataApi: zPlanBtn,
});

export interface CartFabType extends z.infer<typeof zCartFabType> {}
export interface WorklistFabType extends z.infer<typeof zWorklistFabType> {}
export interface FabTypePivot extends z.infer<typeof zFabTypePivot> {}
export interface FabTypePlanning extends z.infer<typeof zFabTypePlanning> {}
export interface FabTypeButtonModal extends z.infer<typeof zFabTypeButtonModal> {}
export interface PostTextModalFab extends z.infer<typeof zPostTextModalFab> {}

export const AssortmentConfDefn = z.object({
  /** The type of project the configs are setup for */
  id: z.union([z.literal('Assortment'), z.literal('Configurator'), z.literal('_empty_')]),
  /** The selected tab after login and perspective selection */
  defaultTab: z.string(),
  canAddNewStyles: z
    .boolean()
    .optional()
    .default(true),
  showBookmarks: z
    .boolean()
    .optional()
    .default(false),
  /** Values shown for flowStatus and initial selections  */
  flowStatus: FlowStatus.default({ values: [], defaultSelection: [] }),
  /** Placeholder config for changing placeholder text, etc. */
  placeholder: Placeholder.default({
    text: 'PLACEHOLDER',
  }),
  /** i.e. YTD, QTD, MTD, etc. */
  lookBackPeriods: z.array(z.string()).default([]),
  /** Available Perspectives */
  perspectives: z.array(Perspective),
  perspective: z
    .object({
      scopeConfig: z.object({
        type: ScopeSelectorType,
        workflow: ScopeWorkflow.optional(),
        dependentScopes: z
          .array(zDependentScopes)
          .default([])
          .optional(),
      }),
      headerbar: z.object({
        type: HeaderbarType,
      }),
    })
    .default({
      scopeConfig: {
        type: 'AssortmentScopeSelector',
        dependentScopes: [],
      },
      headerbar: {
        type: 'AssortmentHeaderbar',
      },
    })
    .optional(),
  /** For Filters/Style Pane to map tabs to a predetermined application context */
  context: zAppContext.default({
    assortment: {
      tabs: [],
    },
    hindsighting: {
      tabs: [],
    },
    allocation: {
      tabs: [],
    },
  }),
  /**
    Tabs to render in TopNavBar, containing Section and View configurations.
    Reporting Tab can actually be component itself so LeftNavSection is necessary in union
  */
  tabs: z.array(ConfDefnTab),
  /** Available print dimensions */
  printSizes: z.array(zPrintSize).default([
    { width: 8.5, height: 11 },
    { width: 11, height: 8.5 },
    { width: 17, height: 11 },
    { width: 16.54, height: 23.39, name: 'A2' },
    { width: 11.7, height: 16.5, name: 'A3' },
    { width: 8.3, height: 11.7, name: 'A4' },
  ]),
  /** For views like configuration */
  disableScope: z
    .boolean()
    .optional()
    .default(false),
  disableFilters: z
    .boolean()
    .optional()
    .default(false),
  fabTypes: z.record(
    z.string(),
    z.discriminatedUnion('fabType', [
      zFabTypePlanning,
      zFabTypePivot,
      zPostTextModalFab,
      zFabTypeButtonModal,
      zCartFabType,
      zWorklistFabType,
    ])
  ),
});

export interface UIConfDefn extends z.infer<typeof AssortmentConfDefn> {}
/**
 * Validate each level of the confdefn tree, filtering out invalids items.
 * An invalid item at a higher level removes that level and its children.
 *
 * Current levels:
 * - UIConfDefn level (minus the tabs)
 * - Tabs level (minus the sections)
 * - Sections level (minus the views)
 * - Views level (all views)
 *
 * @param {UIConfDefn} input - the unvalidated confdefn
 *
 * @returns {TenantConfig} a validated/filtered confdefn
 */
export function validateConfDefn(input: UIConfDefn, loggingService: any, alwaysThrowOnInvalid = false): TenantConfig {
  let errors: string[] = [];

  // Require valid top level letting error propagate to catch if invalid
  const uiConfDefnLevel = validateAtLevel(AssortmentConfDefn, input, {
    childLevel: 'tabs',
    throwOnInvalid: alwaysThrowOnInvalid || true,
  });

  // Validate at each level (tab, section, view)
  const childLevels = map(input.tabs, (tab, tabIndex) => {
    // validate disabled tab
    if (tab.disabled) {
      const validatedDisabledTab = validateAtLevel(DisabledTab, tab, { throwOnInvalid: alwaysThrowOnInvalid });
      if (isNil(validatedDisabledTab)) {
        const error = `Invalid tab item [${tab.id}] detected at position [${tabIndex}] in 'tabs' array.`;
        errors = concat(errors, error);
        return;
      }

      return validatedDisabledTab;
    }

    // validate enabled tab and reinsert tab children after validation
    const validatedTabLevel = validateAtLevel(EnabledTab, tab, {
      childLevel: 'leftNavSections',
      throwOnInvalid: alwaysThrowOnInvalid,
    });
    if (isNil(validatedTabLevel)) {
      const error = `Invalid tab item [${tab.id}] detected at position [${tabIndex}] in 'tabs' array.`;
      errors = concat(errors, error);
      return;
    }

    const validatedTabLevelWithChildren = {
      ...validatedTabLevel,
      leftNavSections: tab.leftNavSections,
    };

    // validate all sections in tab
    const validatedSectionLevels = map(validatedTabLevelWithChildren.leftNavSections, (section, sectionIndex) => {
      // validate section and reinsert section children after validation
      const validatedSectionLevel = validateAtLevel(LeftNavSection, section, {
        childLevel: 'views',
        throwOnInvalid: alwaysThrowOnInvalid,
      });
      if (isNil(validatedSectionLevel)) {
        const error = `Invalid section item [${section.id}] detected in 'leftNavSections' array at tab position [${tabIndex}] -> leftNavSections position [${sectionIndex}].`;
        errors = concat(errors, error);
        return;
      }

      const validatedSectionLevelWithChildren = {
        ...validatedSectionLevel,
        views: section.views,
      };

      // validate all views in section
      const validatedViewLevels = map(validatedSectionLevelWithChildren.views, (view, viewIndex) => {
        const validatedViewLevel = validateAtLevel(SectionView, view, { throwOnInvalid: alwaysThrowOnInvalid });
        if (isNil(validatedViewLevel)) {
          const error = `Invalid view item [${view.id}] detected in 'views' array at tabs position [${tabIndex}] -> leftNavSections position [${sectionIndex}] -> views position [${viewIndex}].`;
          errors = concat(errors, error);
          view.errorView = true;
          return view;
        }

        return validatedViewLevel;
      }).filter(filterInvalidItem);

      return {
        ...validatedSectionLevel,
        views: validatedViewLevels,
      };
    }).filter(filterInvalidItem); // filter out invalid (undefined) section(s)

    return {
      ...validatedTabLevel,
      leftNavSections: validatedSectionLevels,
    };
  }).filter((tab) => !isNil(tab)); // filter out invalid (undefined) tab(s)

  if (!isEmpty(errors)) {
    const errorMessage = 'INVALID UI CONFDEFN ITEMS DETECTED:';
    // true skips the logging to browser console
    loggingService.warn(errorMessage, errors.join('\n'), true);
    formatAndLogLocal(errorMessage, errors);
  }

  // validated/filtered result
  return {
    ...uiConfDefnLevel,
    tabs: childLevels,
  };
}

import { createSelector } from '@reduxjs/toolkit';
import { findIndex, isEmpty, isNil } from 'lodash';
import { AppState } from 'src/store';
import { parseFloorsetDropdownData } from '../ConfigurableGrid/utils/ConfigurableGrid.utils';
import {
  SelectorSubheaderDropdownProps,
  getFloorsetData,
  getSelectedFloorset,
} from '../ConfigurableGrid/ConfigurableGrid.selectors';
import { SubheaderDropdowns } from 'src/services/configuration/codecs/viewdefns/viewdefn';

export function getConfigureSelections(state: AppState) {
  return state.subheader.configureSelections;
}

export const getTopMemberConfig = (_state: AppState, { topMember }: SubheaderDropdowns) => {
  return topMember;
};

export const getTopMemberDropdownProps = createSelector(
  getFloorsetData,
  getSelectedFloorset,
  getTopMemberConfig,
  (floorsets, selectedFloorset, topMemberConfig) => {
    if (isEmpty(floorsets)) {
      return;
    }

    const options = parseFloorsetDropdownData(floorsets);
    const selection = isNil(selectedFloorset) ? 0 : findIndex(options, (option) => option.id === selectedFloorset);
    const label = topMemberConfig?.label || 'Floorset';
    const floorsetDropdownProps: SelectorSubheaderDropdownProps = {
      label,
      defaultSelection: 0,
      selection,
      options,
    };

    return floorsetDropdownProps;
  }
);

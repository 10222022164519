import { ofType } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppEpic } from 'src/store';
import { inputIsNotNullOrUndefined } from 'src/utils/Functions/epicsFunctions';
import { setActivePage } from 'src/pages/NavigationShell/NavigationShell.slice';
import { setPossibleBreadcrumbs } from './Subheader.slice';
import { get } from 'lodash';

export const setBreadcrumbs: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => {
      const breadcrumbs: string | null = get(
        state$,
        'value.perspective.activePageConf.componentProps.defns.subheader.breadcrumbs',
        null
      );
      return breadcrumbs;
    }),
    // @ts-ignore
    filter(inputIsNotNullOrUndefined),
    mergeMap((possibleBreadcrumbs: string[]) => {
      return of(setPossibleBreadcrumbs(possibleBreadcrumbs));
    })
  );
};

export const clearActiveBreadcrumb: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(setActivePage.type),
    map(() => {
      const breadcrumbs: string | null = get(
        state$,
        'value.perspective.activePageConf.componentProps.defns.subheader.breadcrumbs',
        null
      );
      return breadcrumbs;
    }),
    // @ts-ignore
    filter(inputIsNotNullOrUndefined),
    mergeMap((possibleBreadcrumbs: string[]) => {
      return of(setPossibleBreadcrumbs(possibleBreadcrumbs));
    })
  );
};

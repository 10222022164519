import {
  EditableCallbackParams,
  ValueFormatterParams,
  SuppressKeyboardEventParams,
  ValueSetterParams,
  ValueGetterParams,
  CellClassParams,
  CellRendererSelectorResult,
  CellEditorSelectorResult,
  CellStyle,
  ValueGetterFunc,
} from '@ag-grid-community/core';
import {
  ColGroupDef,
  ColDef,
  CellClickedEvent,
  GridApi,
  GridReadyEvent,
  CellEditingStartedEvent,
  ColumnApi,
  CellValueChangedEvent,
  ICellRendererParams,
  ICellEditorParams,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  GetDetailRowDataParams,
  IRowNode,
  GetGroupRowAggParams,
} from '@ag-grid-community/core';

import { CompanionListView } from 'src/common-ui/index';
import { Props as CompanionProps, ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';
import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps, MenuItemDefWithId } from 'src/common-ui/components/DataGrid/DataGrid';
import {
  mapValues,
  findIndex,
  isNil,
  parseInt,
  isEmpty,
  last,
  has,
  hasIn,
  flow,
  forEach,
  get,
  isEqual,
  concat,
  slice,
  omitBy,
  isBoolean,
  every,
  some,
  flatten,
  reduce,
  sortBy,
  assign,
  uniqBy,
  pick,
  defer,
  omit,
  debounce,
  isUndefined,
  isObject,
} from 'lodash';
import React from 'react';
import { resolvePath } from 'src/cdn';

import { Overlay } from 'src/common-ui/index';
import {
  STYLE_ID,
  STYLE_COLOR_ID,
  LOCKED_AFTER_STYLE_SUBMIT,
  LOCKED_AFTER_COLOR_SUBMIT,
  STYLE_SUBMITTED_ATTR,
  COLOR_SUBMITTED_ATTR,
  IS_PUBLISHED,
  PUBLISHED_TEXT,
  UNPUBLISHED_TEXT,
  ATTR_GRADE,
  ATTR_CLIMATE,
  ATTR_MENSCAPACITY,
  ATTR_WOMENSCAPACITY,
  ATTR_SSG,
  ATTR_FUNDED,
  USERADJ,
  ONORDERREVISION,
  SLSUOVERRIDE,
  PARTIAL_PUBLISHED_TEXT,
  POPOVER_BLOCK_CODES,
  BLOCK_ENTER_EDITORS,
  STORE_COUNT,
  ASSORTMENT,
} from 'src/utils/Domain/Constants';
import {
  companionStyles,
  gridListPairStyle,
  styles,
  extraRowContainerStyles,
  listPairStyle,
  gridContainerStyle,
  editableCell,
  headerCheckbox,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import { ReceiptGridState, ReceiptGridProps } from './ReceiptGrid.types';
import { ConfigurableGridConfigItem } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { RangePickerEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/RangePickerEditor';
import TabbedReceiptsAdjCalculator from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TabbedReceiptsAdjCalculator';
import ValidValuesEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesEditor';
import CheckboxCellRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/Checkbox';
import { ColorHeaderRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ColorHeaderRenderer';
import { IconCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/IconCellRenderer';
import { ImageCellRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ImageCellRenderer';
import { RangePickerRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/RangePickerRenderer';
import { ValidSizesRenderer } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidSizesRenderer';
import Axios from 'src/services/axios';
import { map, reduce as reduceFP, isNumber, partial, isArray } from 'lodash/fp';
import { processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import Subheader from 'src/components/Subheader/Subheader.container';
import { SubheaderActionButtonProps, SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import { create, all } from 'mathjs';
import * as globalMath from 'mathjs';
import { executeCalculation, importDateFunctions } from 'src/utils/LibraryUtils/MathUtils';
import { AgFlatResult } from 'src/utils/Component/AgGrid/AgDataFormat';
import ArrowValueRenderer, {
  ArrowValueRendererProps,
  ARROWDIRECTIONS,
} from 'src/components/ArrowValueRenderer/ArrowValueRenderer';
import CustomGroupHeader from 'src/components/ConfigurableGrid/utils/CustomGroupHeader';
import LifecycleParametersEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/LifecycleParametersEditor';
import SalesAdjustmentEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/SalesAdjustmentEditor';
import ServiceContainer from 'src/ServiceContainer';
import { style } from 'typestyle';
import { simpleByField } from 'src/utils/Pivot/Sort';
import Renderer from 'src/utils/Domain/Renderer';
import coalesce from 'src/utils/Functions/Coalesce';
import {
  TextValidationEditor,
  PendingCellInfo,
  viewDefnWhitelistToNarrowedCharacterWhitelist,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import ValidValuesRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/ValidValuesRenderer';
import { getMergedRangeLists } from 'src/dao/scopeClient';
import IntegerEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor/IntegerEditor';
import { parseObservers, ObservableGridProps } from 'src/utils/Component/ObservervableGridProps';
import TooltipRenderer from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Renderers/TooltipRenderer';
import {
  calculateColumnWidth,
  resetAsyncValidationData,
} from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import noImagePath from 'src/common-ui/images/noimage.jpg';
import { BasicPivotItem, TreePivot } from 'src/worker/pivotWorker.types';
import { SeverityRenderer } from '../SeverityRenderer/SeverityRenderer';
import { StarPercentRenderer } from '../StarPercentRenderer/StarPercentRenderer';
import { StatusIconRenderer } from '../StatusIconRenderer/StatusIconRenderer';
import ConfigurableDataModal from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ConfigurableDataModal/ConfigurableDataModal';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import HeatmapRenderer from '../HeatmapRenderer/HeatmapRenderer';
import CheckboxHeaderRenderer, { CheckboxHeaderRendererProps } from '../CheckboxHeaderRenderer/CheckboxHeaderRenderer';
import { BasicItem as PivotBasicItem } from 'src/worker/pivotWorker.types';
import ValidValuesCheckBoxEditor from '../ValidValuesCheckboxEditor/ValidValuesCheckboxEditor';
import {
  ValidValuesCheckBoxEditorHeader,
  ValidValuesCheckBoxEditorHeaderProps,
} from '../ValidValuesCheckboxEditor/ValidValuesCheckboxEditorHeader';
import { logError } from 'src/services/loggingService';
import { FabType } from '../higherOrder/withFab';
import { CoarseEditPayload, GranularEditPayloadItem } from 'src/dao/pivotClient';
import { SelectorSubheaderDropdownProps } from 'src/components/ConfigurableGrid/ConfigurableGrid.selectors';
import { ConfigurableGridGroupBySelection } from 'src/components/ConfigurableGrid/ConfigurableGrid.slice';
import SplitButton from 'src/common-ui/components/SplitButton/SplitButton';
import { WorklistService } from 'src/services/Worklist/Worklist.service';
import { getValidMembers } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.client';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ImageRendererWithHover from 'src/components/ImageRendererWithHover/ImageRendererWithHover';
import { makeValidValuesCache } from 'src/services/validValuesCache';
import { DetailGridRenderer } from './DetailGridRenderer';
import service from 'src/ServiceContainer';
import { FirstDataRenderedEvent } from '@ag-grid-community/core';
import { PivotServiceCache, RequestEndpoint } from 'src/services/pivotServiceCache';
import { Button } from '@material-ui/core';
import IconWithPopoverTrigger from '../IconWithPopoverTrigger/IconWithPopoverTrigger';
import { TopAttribute } from 'src/services/configuration/codecs/viewdefns/general';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import { MIN_COL_WIDTH } from '../ListGridPair/ListGridPair.styles';

const noImage = resolvePath(noImagePath);

type FrameworkComponents = any;
type NonFrameworkComponents = any;
type PostValue = any;
interface MassColumnUpdateParams {
  value: string;
  dataIndex: string;
  nodes: IRowNode[];
}

type AsyncValidationErrorProps = {
  initial: string;
  invalidValue: string;
  invalidDescription?: string;
};

export const AsyncValidationErrorMessage = (props: AsyncValidationErrorProps) => {
  return (
    <div>
      <p>{`${props.invalidValue} is not available for use.`}</p>
      {props.invalidDescription && <p>{`${props.invalidDescription}`}</p>}
      <p>{`The value was reset to ${props.initial}.`}</p>
      <p>{`Please try with a different value.`}</p>
    </div>
  );
};

class ServerSideHandler implements IServerSideDatasource {
  rows: Record<string, unknown>[] = [];

  setRows = (newRows: Record<string, unknown>[]) => {
    let idIncr = 0;
    newRows.forEach((row) => {
      row._id = idIncr++;
      row.isGroup = false;
    });
    this.rows = newRows;
  };

  getRows = (params: IServerSideGetRowsParams) => {
    // params.successCallback(slice(this.rows, params.request.startRow, params.request.endRow), this.rows.length);
    if (params.request.groupKeys.length == params.request.rowGroupCols.length) {
      const groupRows = uniqBy(
        this.rows.map((row) => {
          const keysToFilter = params.request.rowGroupCols
            .map((colDef) => colDef.id)
            .slice(0, params.request.groupKeys.length + 1);
          const rowFields = pick(row, keysToFilter.concat('sizeattribute'));
          return {
            ...row,
            _id: Object.values(rowFields).join(),
            isGroup: true,
          };
        }),
        (r) => Object.values(r).join()
      );
      // changed in ag-grid v31, this is most likely broken,
      // as the interface changed slightly to split group/rowData
      params.success({
        rowData: groupRows,
        rowCount: groupRows.length,
      });
    } else {
      const groupRows = uniqBy(
        this.rows.map((row) => {
          const keysToFilter = params.request.rowGroupCols
            .map((colDef) => colDef.id)
            .slice(0, params.request.groupKeys.length + 1);
          const rowFields = pick(row, keysToFilter);
          return {
            ...rowFields,
            _id: Object.values(rowFields).join(),
            isGroup: true,
          };
        }),
        (r) => Object.values(r).join()
      );
      // changed in ag-grid v31, this is most likely broken,
      // as the interface changed slightly to split group/rowData
      params.success({
        rowData: groupRows,
        rowCount: groupRows.length,
      });
    }
  };

  destroy = () => {
    this.rows = [];
  };
}

const nonFrameworkComponents: NonFrameworkComponents = {
  colorHeaderRenderer: ColorHeaderRenderer,
};

const frameworkComponents: FrameworkComponents = {
  imageCellRenderer: ImageCellRenderer,
  validValuesEditor: ValidValuesEditor,
  validValuesRenderer: ValidValuesRenderer,
  rangePickerRenderer: RangePickerRenderer,
  rangePickerEditor: RangePickerEditor,
  checkboxCellRenderer: CheckboxCellRenderer,
  receiptsAdjCalculator: TabbedReceiptsAdjCalculator,
  customGroupHeader: CustomGroupHeader,
  lifecycleParametersEditor: LifecycleParametersEditor,
  salesAdjustmentEditor: SalesAdjustmentEditor,
  validSizesRenderer: ValidSizesRenderer,
  iconCellRenderer: IconCellRenderer,
  textValidationEditor: TextValidationEditor,
  integerEditor: IntegerEditor,
  tooltipRenderer: TooltipRenderer,
  severityRenderer: SeverityRenderer,
  starPercentRenderer: StarPercentRenderer,
  statusIconRenderer: StatusIconRenderer,
  configurableDataModal: ConfigurableDataModal,
  arrowValueRenderer: ArrowValueRenderer,
  heatmapRenderer: HeatmapRenderer,
  gridHeaderCheckbox: CheckboxHeaderRenderer,
  validValuesCheckbox: ValidValuesCheckBoxEditor,
  validValuesCheckboxHeader: ValidValuesCheckBoxEditorHeader,
  imageRendererWithHover: ImageRendererWithHover,
  masterDetail: DetailGridRenderer,
  iconWithPopoverTrigger: IconWithPopoverTrigger,
};

export class ConfigurableGrid extends React.Component<ReceiptGridProps, ReceiptGridState> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  nonFrameworkComponents: NonFrameworkComponents;
  frameworkComponents: FrameworkComponents;
  selectPopupLeft!: number;
  selectPopupTop!: number;
  observers: ObservableGridProps = {};
  allowEnterList: string | undefined[] = [];
  math = create(all) as globalMath.MathJsStatic;
  serverSource = new ServerSideHandler();
  detailCache = new PivotServiceCache(100);
  /**
   * This object stores the ordered values any valid values checkbox editor headers (i.e. store vol. tier)
   */
  vvHeaderMap: Record<string, string[]> = {};

  constructor(props: ReceiptGridProps) {
    super(props);
    this.state = {
      gridRdyCnt: 0,
      selectedIndex: 0,
      companionCollapsed: false,
      companionSortDirection: 'desc',
      companionSortField: undefined,
      activeStyleColor: '',
      styleColorsEdited: [],
      treeColumnDefinition: undefined,
      massEditGridProcessing: false,
      notificationModal: false,
      actionType: undefined,
      selectedItems: [],
      validValuesCache: makeValidValuesCache(),
      rowEdits: [],
    };

    const aggregateColumn = (args: math.MathNode[], _mathjs: math.MathJsStatic, scope: { [s: string]: any }) => {
      const expressionField = args.map((arg) => (arg.name ? arg.name : ''));

      if (expressionField.length < 1 || isNil(this.gridApi)) {
        return 0;
      }

      const field = expressionField[0];
      const colDef = this.props.columnDefs.find((col) => col.dataIndex === field);
      let aggType = get(colDef, 'aggregator', 'sum');
      if (aggType === 'eval') {
        console.warn("We don't currently support eval aggs in other aggs. Falling back to sum."); // eslint-disable-line no-console
        aggType = 'sum';
      }
      const fieldAggregation = this.math[aggType](scope[field]);

      return fieldAggregation;
    };

    // register AGG method with mathjs
    (aggregateColumn as any).rawArgs = true;
    this.math.import({ AGG: aggregateColumn }, { override: true });
    if (this.props.data) {
      this.serverSource.setRows(this.props.data);
    }
  }

  async componentDidMount() {
    // setup math functions with date math handlers
    const mergedRangeList = await getMergedRangeLists();
    try {
      importDateFunctions(this.math, mergedRangeList);
    } catch (error) {
      toast.error('An error occurred loading the grid functionality');
      // @ts-ignore
      ServiceContainer.loggingService.error(`An error occurred importing date functions for the grid`, error.stack);
    }
  }

  componentDidUpdate(prevProps: ReceiptGridProps) {
    if (!isEqual(prevProps.favoritesList, this.props.favoritesList)) {
      const activeFavorite = this.props.favoritesList.find((x) => x.active === true);
      if (
        activeFavorite &&
        activeFavorite.jsonBlob &&
        activeFavorite.jsonBlob.companionData &&
        activeFavorite.jsonBlob.groupBySelection &&
        this.props.groupByDropdownProps
      ) {
        const compData = activeFavorite.jsonBlob.companionData;
        const nextSelectedIndex = activeFavorite.jsonBlob.groupBySelection || 0;
        const dropdownData: ConfigurableGridGroupBySelection = {
          selectedIndex: nextSelectedIndex,
          option: this.props.groupByDropdownProps.options[nextSelectedIndex],
        };

        this.setState(
          {
            companionSortField: compData.companionSortField,
            companionSortDirection: compData.companionSortDirection,
            companionCollapsed: compData.companionCollapsed,
          },
          () => {
            const selectedIndex = this.props.groupByDropdownProps?.selection;
            if (nextSelectedIndex !== selectedIndex) {
              this.props.setGroupBySelection(dropdownData);
            }
          }
        );
      } else {
        this.setState({
          companionSortField: this.props.defaultCompanionSortField,
          companionSortDirection: 'desc',
          companionCollapsed: false,
        });
      }
    }
    if (!isEqual(prevProps.data, this.props.data)) {
      this.state.validValuesCache.clear();
      this.serverSource.setRows(this.props.data);
    }
  }

  onUpdateConfig = (config: any) => {
    if (config.isDefault) {
      this.setState({
        companionSortDirection: 'desc',
        companionCollapsed: false,
        companionSortField: this.props.defaultCompanionSortField,
      });
    }

    this.props.onUpdateConfig(config);

    if (!isNil(this.props.groupByDropdownProps)) {
      this.props.setGroupBySelection({
        selectedIndex: config.groupBySelection,
        option: this.props.groupByDropdownProps.options[config.groupBySelection],
      });
    }
  };

  onFabClick = () => {
    switch (this.props.fab?.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlePendingCellUpdate(value: string, pendingCellInfo: PendingCellInfo) {
    // noop
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkAllBoxes = (dataIndex: string, checked: boolean) => {
    // noop
  };

  _getVal = (key: string, node: IRowNode, api: GridApi | null | undefined) => {
    if (isNil(api) || isNil(node.data)) return null;
    return coalesce(api.getValue(key, node), node.data[key], null);
  };

  _getPreviousWeekNode = (
    params: Pick<CellValueChangedEvent, 'node' | 'api'> | Pick<ValueGetterParams, 'node' | 'api'>
  ) => {
    if (params.api == null || isNil(params.node)) return;
    // TODO: these should not be hardcode...
    const stylecolorsize = this._getVal('product', params.node, params.api);
    const time = this._getVal('time', params.node, params.api);
    const productNodes: IRowNode[] = [];
    params.api.forEachNode((rowNode: IRowNode) => {
      if (rowNode.group !== true && this._getVal('product', rowNode, params.api) === stylecolorsize) {
        productNodes.push(rowNode);
      }
    });
    const rowNode = sortBy(productNodes, (rowNode) => this._getVal('time', rowNode, params.api)).find(
      (_row, index, allRows) => {
        if (index < allRows.length - 1) {
          return this._getVal('time', allRows[index + 1], params.api) === time;
        } else {
          return false;
        }
      }
    );
    return rowNode;
  };

  createColumnDef = (colInfo: ConfigurableGridConfigItem) => {
    // setup observers if applicable
    this.observers = parseObservers(this.observers, colInfo);
    const floorset = this.getActiveFloorset();
    const tealBackgroundStyle = style({
      backgroundColor: 'rgba(220, 243, 241, .7)',
    });
    function isEditable(params: EditableCallbackParams) {
      if (isNil(params.data)) return false;
      let editable = !!colInfo.editable;
      const styleSubmitted = !isNil(params.data[STYLE_SUBMITTED_ATTR]);
      const colorSubmitted =
        !isNil(params.data[COLOR_SUBMITTED_ATTR]) && params.data[COLOR_SUBMITTED_ATTR] !== 'Undefined';

      if (params.node.aggData) {
        editable = false;
      } else if (LOCKED_AFTER_STYLE_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
        return colInfo.editable && !styleSubmitted;
      } else if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
        return colInfo.editable && !colorSubmitted;
      }
      if (params.node.allChildrenCount && params.node.allChildrenCount > 0) {
        return !!colInfo.cascadeGroupSelection;
      }
      if (colInfo.inputType === 'checkbox') {
        // the checkbox renderer handles both rendering and editing together, and does not need to be marked as editable in the editable callback
        return false;
      }

      return editable;
    }

    const valueGetter: ValueGetterFunc = (params) => {
      const calculation = colInfo.calculation;
      const field = params.colDef.field;
      const getDataFn = (key: string) => {
        const returnObj = {
          rowNodeFound: false,
          data: undefined as unknown,
        };
        returnObj.rowNodeFound = true;
        if (key.indexOf('$previous_') === 0 && !isNil(params.api)) {
          const rowNode = this._getPreviousWeekNode(params);
          if (rowNode) {
            returnObj.data = this._getVal(key.replace(/\$previous_/, ''), rowNode, params.api) || 0;
          } else {
            returnObj.data = 0;
          }
          return returnObj;
        } else {
          returnObj.data = params.node && coalesce(this._getVal(key, params.node, params.api), undefined);
          return returnObj;
        }
      };
      if (params.node && !isUndefined(params.node.group)) {
        return !isNil(params.node.data) && field ? params.node.data[field] : null;
      }
      if (calculation) {
        const newValue = executeCalculation(this.math, calculation, getDataFn);
        return newValue;
      }
      if (params.data && field && !isNil(params.data[field])) {
        return params.data[field];
      }
      if (
        params.data &&
        // @ts-ignore special s5 property
        colInfo.renderer === 'checkbox' &&
        field &&
        params.data[field] === ''
      ) {
        // special case for checkbox renderer, for if darwin sends empty string (which js treats as falsy), to avoid the falsy value getting converted to null below
        return false;
      }
      return null;
    };

    const calculatedWidth = colInfo.width || calculateColumnWidth(colInfo.dataIndex);
    let headerInfo: {
      component?: string;
      params?: ValidValuesCheckBoxEditorHeaderProps | CheckboxHeaderRendererProps;
    } = {
      component: undefined,
      params: undefined,
    };
    if (colInfo.inputType === 'checkbox') {
      headerInfo = {
        component: 'gridHeaderCheckbox',
        params: {
          onChange: this.checkAllBoxes.bind(this, colInfo.dataIndex),
          checkedStatus: 'indeterminate',
          isProcessing: this.state.massEditGridProcessing,
        },
      };
    } else if (colInfo.renderer === 'validValuesCheckbox') {
      const availableHeaders = colInfo.options ? colInfo.options.map((o) => o.text) : [];
      headerInfo = {
        component: 'validValuesCheckboxHeader',
        params: {
          availableHeaders,
          optionsApi: colInfo.dataApi,
          validValuesCache: this.state.validValuesCache,
          onHeadersFetched: (headers: string[]) => {
            this.vvHeaderMap[colInfo.dataIndex] = headers;
          },
        },
      };
    }
    const columnDef: ColDef = {
      width: calculatedWidth,
      headerName: colInfo.text,
      headerComponent: headerInfo.component,
      headerComponentParams: headerInfo.params,
      pinned: colInfo.pinned,
      field: colInfo.dataIndex,
      hide: isNil(colInfo.hidden) ? false : true,
      suppressColumnsToolPanel: isNil(colInfo.hidden) ? false : true,
      minWidth: colInfo.renderer === 'backgroundFill' ? MIN_COL_WIDTH : undefined,
      cellStyle: (params: CellClassParams): CellStyle | null => {
        if (colInfo.renderer === 'backgroundFill') {
          return {
            'background-color': params.value,
            color: 'transparent',
            padding: 0,
          };
        }
        if (!isNil(colInfo.invalidDataIndex) && !isNil(params.data) && params.data[colInfo.invalidDataIndex] === true) {
          return { border: '1px solid #ff0000' };
        }

        return null;
      },
      cellClass: colInfo.cellClass,
      cellClassRules: {
        // @ts-ignore - this expects a boolean now - double check
        [editableCell]: (params: CellClassParams) => {
          // popover is no longer configured to be editable, so need this to style icon correctly
          const editable = colInfo.dataIndex === 'popoverTrigger' || isEditable(params);
          return editable && !params.node.aggData ? editableCell : undefined;
        },
        [tealBackgroundStyle]: (params: CellClassParams) => {
          return !!colInfo.highlightColumn && !params.node.aggData ? tealBackgroundStyle : undefined;
        },
      },
      rowGroup: colInfo.rowGroup ? colInfo.rowGroup : false,
      editable: (params: EditableCallbackParams) => {
        return isEditable(params);
      },
      // Only give comparator to columns that have one
      ...(colInfo.comparator &&
        colInfo.comparator.type && {
          comparator: (valueA: string | undefined, valueB: string | undefined) => {
            let compValue: number;
            if (isNil(valueA)) compValue = -1;
            else if (isNil(valueB)) compValue = 1;
            // we know it's not null above, but the check is "lost" as the function potentially escapes scope
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            switch (colInfo.comparator!.type) {
              case 'datetime':
                const [dateA, dateB] = [[valueA], [valueB]];

                if (colInfo.comparator && colInfo.comparator.options && colInfo.comparator.options.format) {
                  const comparatorFormat = colInfo.comparator.options.format;
                  dateA.push(comparatorFormat);
                  dateB.push(comparatorFormat);
                }

                const momentA = moment(...dateA);
                const momentB = moment(...dateB);

                if (momentA.isAfter(momentB)) {
                  compValue = 1;
                } else if (momentA.isBefore(momentB)) {
                  compValue = -1;
                } else {
                  compValue = 0;
                }
                break;
              case 'number':
                const numA = Number(valueA);
                const numB = Number(valueB);

                if (numA > numB) {
                  compValue = 1;
                } else if (numB > numA) {
                  compValue = -1;
                } else {
                  compValue = 0;
                }
                break;
              default:
                compValue = 0;
            }
            return compValue;
          },
        }),
      valueGetter: valueGetter,
      valueSetter: (params: ValueSetterParams) => {
        const { newValue, data, colDef, node } = params;
        const field = colDef.field;
        if (newValue && !isEmpty(newValue.storeData)) {
          // Saving for store eligibility
          const storeDataByFloorset = Array.isArray(newValue.storeData[floorset])
            ? newValue.storeData[floorset][0]
            : newValue.storeData[floorset];
          if (storeDataByFloorset) {
            data[ATTR_GRADE] = storeDataByFloorset['grade'];
            data[ATTR_CLIMATE] = storeDataByFloorset['strclimate'];
            data[ATTR_MENSCAPACITY] = storeDataByFloorset['strmenscapacity'];
            data[ATTR_WOMENSCAPACITY] = storeDataByFloorset['strwomenscapacity'];
            data[ATTR_SSG] = storeDataByFloorset['ssg:ids']
              ? storeDataByFloorset['ssg:ids']
              : storeDataByFloorset['ssg'];
            data[ATTR_FUNDED] = storeDataByFloorset['isfunded'];
            data[STORE_COUNT] = storeDataByFloorset[STORE_COUNT];
          }

          // Saving lifecycle
          if (!isEmpty(newValue.lifecycleData)) {
            const lifecycleParsedData = {};
            Object.keys(newValue.lifecycleData).forEach((key) => {
              // Lifecycle data doesn't have attribute in the dataindex so this tries to cover the bases
              lifecycleParsedData[key] = newValue.lifecycleData[key];
              lifecycleParsedData[`attribute:${key}:id`] = newValue.lifecycleData[key];
              lifecycleParsedData[`attribute:${key}:name`] = newValue.lifecycleData[key];
            });
            node?.setData({
              ...data,
              ...lifecycleParsedData,
            });
          }
        } else if ((field === USERADJ || field === ONORDERREVISION) && newValue) {
          data[USERADJ] = newValue['userAdjRevision'];
          data[ONORDERREVISION] = newValue['onOrderRevision'];
        } else if (field === SLSUOVERRIDE && newValue) {
          data[field] = newValue;
        } else if (colInfo.inputType === 'configurableDataModal') {
          forEach(newValue, (value, key) => {
            data[key] = value;
          });
        } else if (field) {
          // if async validation, newValue will be 'PENDING'
          data[field] = newValue;

          const memberMatched = field.match(/member:([a-z]*):[a-z]*/);
          const isMemberUpdate = !isNil(memberMatched);

          if (isMemberUpdate) {
            const memberLevel = !isNil(memberMatched) ? memberMatched[1] : '';

            if (
              isObject(newValue) &&
              'label' in newValue &&
              typeof newValue.label === 'string' &&
              'value' in newValue &&
              typeof 'value' === 'string'
            ) {
              // ensure all dependentData for memberLevel is updated for dataApi lookups later
              data[`member:${memberLevel}:id`] = newValue.value;
              data[`member:${memberLevel}:name`] = newValue.label;
              data[`member:${memberLevel}:description`] = newValue.label;
            } else {
              const pendingCellInfo: PendingCellInfo = {
                id: params.node?.id || '',
                dataIndex: !isNil(params.column) ? params.column.getColId() : '',
              };

              // this method handles correctly updating async cell updates
              // it will override the 'data[field] = newValue' value set above
              this.handlePendingCellUpdate(newValue, pendingCellInfo);
            }
          }
        }

        if (!isEmpty(newValue)) {
          // Log stylecolor as editted for planning
          if (this.state.styleColorsEdited.indexOf(data.stylecolor) === -1) {
            this.setState({
              styleColorsEdited: this.state.styleColorsEdited.concat(data.stylecolor),
            });
          }
        }

        return true;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.colDef.field === 'dc_publish') {
          switch (params.value) {
            case 2:
              return 'Published';
            case 1:
              return 'Partial';
            default:
              return '';
          }
        } else if (!isNil(colInfo.renderer) && hasIn(Renderer, colInfo.renderer)) {
          const rawValue = params.value;
          if (isNil(rawValue) || Number.isNaN(rawValue)) return '';
          return Renderer.renderJustValue(rawValue, colInfo);
        } else {
          // if the value goes down this path, it ends up in the default ag-grid renderer,
          // which aproximates params.value.toString()
          return params.value;
        }
      },
      cellEditorSelector: (params: ICellEditorParams): CellEditorSelectorResult => {
        let row: IRowNode;
        if (!params || !params.data) {
          return (null as unknown) as CellEditorSelectorResult;
        }
        if (params.node == null) {
          return (null as unknown) as CellEditorSelectorResult;
        } else {
          row = params.node;
        }

        const styleColor = params.data['id'];
        // FIXME: see EAS-607
        let processedDataApi, processedConfigApi;
        if (colInfo.dataApi != null) {
          processedDataApi = processApiParams(colInfo.dataApi, row);
        }
        if (colInfo.configApi) {
          processedConfigApi = processApiParams(colInfo.configApi, row);
        }

        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'lifecycleParameters':
            params.colDef.cellEditorPopup = true;
            const headerSubtext = `
              ${params.data['name']} | ${params.data['description']}`;
            return {
              component: 'lifecycleParametersEditor',
              params: {
                dataApiLifecycle: {
                  ...colInfo.dataApiLifecycle,
                  params: {
                    appName: 'assortment',
                    product: styleColor,
                    ...get(colInfo.dataApiLifecycle, 'params', {}),
                  },
                },
                dataApiStore: {
                  ...colInfo.dataApiStore,
                  params: {
                    appName: 'assortment',
                    product: styleColor,
                    ...get(colInfo.dataApiStore, 'params', {}),
                  },
                },
                lifecycleConfig: {
                  ...colInfo.lifecycleConfig,
                  params: !isNil(colInfo.lifecycleConfig) ? { ...colInfo.lifecycleConfig.params } : {},
                },
                storeConfig: {
                  ...colInfo.storeConfig,
                  params: !isNil(colInfo.storeConfig) ? { ...colInfo.storeConfig.params } : {},
                },
                dependentsApi: {
                  ...colInfo.dependentsApi,
                },
                floorset: floorset,
                product: styleColor,
                headerSubtext,
              },
            };
          case 'salesAdjustment':
            params.colDef.cellEditorPopup = true;
            return {
              component: 'salesAdjustmentEditor',
              params: {
                dataApi: {
                  ...colInfo.dataApi,
                },
                configData: this.props.salesAdjustmentConfig,
                floorset: this.getActiveFloorset(),
                isEditable: false,
              },
            };
          case 'receiptsAdjCalculator':
            params.colDef.cellEditorPopup = true;
            return {
              component: 'receiptsAdjCalculator',
              params: {
                isEditable: false,
                dataApi: {
                  url: colInfo.dataApi.url,
                },
                floorset: this.getActiveFloorset(),
              },
            };
          case 'configurableDataModal': {
            params.colDef.cellEditorPopup = true;

            const cellDataIndex = colInfo.dataIndex;
            return {
              component: 'configurableDataModal',
              params: {
                isEditable: colInfo.editable,
                configApi: {
                  url: colInfo.configApi.url,
                },
                floorset: this.getActiveFloorset(),
                cellDataIndex,
                renderTabs: colInfo.renderModalTabs,
              },
            };
          }
          case 'validValues':
          case 'validValuesMulti': {
            params.colDef.cellEditorPopup = true;

            const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
            const dataQa = isNil(multiSelect) ? 'select-configurable-grid' : 'select-multi-configurable-grid';
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            // only return full object on member updates
            const returnSelectionObject = colInfo.dataIndex.match(/member:([a-z]*):[a-z]*/);
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedConfigApi || processedDataApi,
                dataQa,
                multiSelect,
                asCsv: colInfo.asCsv,
                postArrayAsString: colInfo.postArrayAsString,
                allowEmptyOption,
                returnSelectionObject,
                ignoreCache: colInfo.ignoreCache,
                includeCurrent: colInfo.includeCurrent,
                concatOptionValues: colInfo.concatOptionValues,
              },
            };
          }
          case 'textValidator':
          case 'textValidatorAsync': {
            params.colDef.cellEditorPopup = true;

            const inputParams = colInfo.inputParams;
            const whitelist = viewDefnWhitelistToNarrowedCharacterWhitelist(inputParams.whitelist);

            const pendingCellInfo: PendingCellInfo = {
              id: params.node.id || '',
              dataIndex: !isNil(params.column) ? params.column.getColId() : '',
            };
            return {
              component: 'textValidationEditor',
              params: {
                validateAsync: colInfo.inputType === 'textValidatorAsync',
                invalidDataIndex: colInfo.invalidDataIndex,
                ...inputParams,
                whitelist,
                pendingCellInfo,
                onValidated: this.handlePendingCellUpdate.bind(this), // will be invoked in promise context, so need to set context
              },
            };
          }
          case 'integer':
            const int = params.data[this.state.activeStyleColor];
            const percent = colInfo.renderer === 'percent';
            return {
              component: 'integerEditor',
              params: {
                passedInt: int,
                inputParams: { ...colInfo.inputParams, percent },
              },
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: true,
              },
            };
          default: {
            return {
              component: 'agTextCellEditor',
            };
          }
        }
      },
      cellRendererSelector: (params: ICellRendererParams): CellRendererSelectorResult => {
        let row: IRowNode;
        if (!params || params.node == null || params.data == null) {
          return (null as unknown) as CellRendererSelectorResult;
        }
        if (params.node.aggData) {
          // first group modification in ConfigurableGrid. Checkbox is only current inline-renderer/editor
          // with group modification support.
          // When cascadeGroup, allow the field to be editable, then handle the result on change.
          if (colInfo.renderer === 'checkbox') {
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: colInfo.cascadeGroupSelection,
                // This treats the field as true|false|(null|undef). In null undef case, field shows "[-]"
                allowIndeterminate: true,
              },
            };
          }
          if (colInfo.renderer === 'validValuesCheckbox') {
            const availableSelections = colInfo.options ? colInfo.options.map((c) => c.value) : [];
            // this will be true if it's grouping
            return {
              component: 'validValuesCheckbox',
              params: {
                isEditable: true,
                availableSelections,
                group: true,
                validValuesCache: this.state.validValuesCache,
              },
            };
          }
          return (null as unknown) as CellRendererSelectorResult;
        } else {
          row = params.node;
        }
        switch (colInfo.renderer) {
          case 'detailHandler': {
            return {
              component: 'agGroupCellRenderer',
            };
          }
          case 'image':
            return {
              component: 'imageCellRenderer',
            };
          case 'imageWithHover':
            return {
              component: 'imageRendererWithHover',
            };

          case 'validValuesCheckbox':
            const availableSelections = colInfo.options ? colInfo.options.map((c) => c.value) : [];
            // If SSG is present, disable checkboxes
            const ssg = row.data[ATTR_SSG];
            const isEditable = !(ssg && isArray(ssg) && ssg.length > 0);
            return {
              component: 'validValuesCheckbox',
              params: {
                isEditable,
                availableSelections,
                optionsApi: colInfo.dataApi,
                validValuesCache: this.state.validValuesCache,
              },
            };
          case 'icon':
            let value = params.value;
            value = value && value[0] && value[0].value ? value[0].value.id : value;
            let icon = colInfo.rendererIcon;

            if (params.colDef && params.colDef.field === 'attribute:cccolor:id') {
              const isLocked = params.data['is_locked'];
              if (isLocked === 1) {
                icon = colInfo.rendererIcon2 || '';
              }
            }
            if (params.colDef && params.colDef.field === 'attribute:isfunded:id') {
              if (value === 1) {
                icon = colInfo.rendererIcon2 || '';
              }
              value = undefined;
            }

            const rendererParams = {
              icon,
              value,
            };

            return {
              component: 'iconCellRenderer',
              params: rendererParams,
            };

          case 'iconWithPopoverTrigger':
            return {
              component: 'iconWithPopoverTrigger',
              params: {
                onItemClicked: (item: BasicPivotItem) => {
                  if (this.props.onItemClicked) {
                    this.props.onItemClicked(item);
                  }
                },
                icon: colInfo.rendererIcon,
                dataQa: 'StylePaneTrigger',
              },
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: true,
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
              params: colInfo,
            };
          case 'validSizes':
            return {
              component: 'validSizesRenderer',
              params: {
                dataConfig: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (_v, k) => {
                    return row[k];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          case 'validValuesRenderer':
            // FIXME: EAS-607, fix configs to be consistent so we only target dataApi and not configApi.
            const api = isNil(colInfo.configApi) ? colInfo.dataApi : colInfo.configApi;
            const dataConfig = !isNil(api) ? processApiParams(api, row) : null;
            return {
              component: 'validValuesRenderer',
              params: {
                dataConfig,
              },
            };
          case 'tooltipRenderer':
            return {
              component: 'tooltipRenderer',
            };
          case 'severityRender':
            return {
              component: 'severityRender',
            };
          case 'starPercentRenderer':
            return {
              component: 'starPercentRenderer',
            };
          case 'statusIconRenderer':
            return {
              component: 'statusIconRenderer',
            };
          default:
            return (null as unknown) as CellRendererSelectorResult;
        }
      },
    };
    return columnDef;
  };

  generateCompanionViewData = (data: BasicPivotItem[]) => {
    const { keys } = this.props;
    const companionSortField = this.state.companionSortField || '';
    const companionSortDirection = this.state.companionSortDirection;
    const removedGroupData = data.filter((dat) => {
      // Groups only have one attribute in the object: group and id
      return Object.keys(dat).length > 2;
    });
    const identityField = keys.idProp;
    const sortedData = simpleByField(removedGroupData, companionSortField, companionSortDirection);
    const compData = sortedData.map((d) => {
      const name = d[`member:${identityField}:name`] ? d[`member:${identityField}:name`] : d.name;
      const description = d[`member:${identityField}:description`]
        ? d[`member:${identityField}:description`]
        : d.description;
      return {
        id: name,
        name: description,
        'member:style:id': d['member:style:id'],
        'member:style:name': d['member:style:name'],
        'member:stylecolor:id': d['member:stylecolor:id'],
        'member:stylecolor:name': d['member:stylecolor:name'],
        stars: d['compositeattributeband'] ? parseInt(d['compositeattributeband'], 10) : 0,
        imageUri: d['attribute:img:id'],
      };
    });

    return compData;
  };

  renderCompanionView = (data: BasicPivotItem[]) => {
    const { keys, defaultCompanionSortField } = this.props;
    const { selectedIndex, companionScrollTo } = this.state;
    const compData = this.generateCompanionViewData(data);
    const identityField = keys.idProp;
    const sortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === this.state.companionSortField;
    });
    const defaultSortSelection = this.props.companionSortOptions.findIndex((option) => {
      return option.dataIndex === defaultCompanionSortField;
    });

    if (compData) {
      const companionProps: CompanionProps = {
        defaultSelection: sortSelection > -1 ? sortSelection : defaultSortSelection,
        sortOptions: this.props.companionSortOptions,
        label: 'Count',
        selectedIndex,
        className: companionStyles,
        data: compData,
        isDataloaded: this.props.dataLoaded,
        noImageUrl: noImage,
        scrollTo: companionScrollTo,
        initialSortDirection: this.state.companionSortDirection,
        isCollapsed: this.state.companionCollapsed,
        onListItemClicked: (identityValue: string) => {
          // TODO: make this logic reusable so it can be invoked during handlePendingCellUpdate if needed
          function findIndexComp(dataKey: string) {
            return compData.findIndex((datum) => {
              const fieldFound = datum[dataKey] ? datum[dataKey] : datum.name;
              return fieldFound === identityValue;
            });
          }
          let key = identityField === 'id' ? 'id' : `member:${identityField}:id`;
          let index = findIndexComp(key);

          key = identityField === 'id' ? 'name' : `member:${identityField}:name`;
          index = index === -1 ? findIndexComp(key) : index;

          const newState = {
            gridScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
          };
          this.setState(newState);
        },
        onChangeDirection: (direction) => {
          this.setState({
            companionSortDirection: direction,
          });
        },
        onSortSelection: (selection) => {
          this.setState({
            companionSortField: selection.dataIndex,
          });
        },
        onToggleCollapse: (isCollapsed) => {
          this.setState({ companionCollapsed: isCollapsed });
        },
      };
      return <CompanionListView {...companionProps} />;
    } else {
      return <div />;
    }
  };

  createGroupedColumns = (columnDefs: ConfigurableGridConfigItem[]) => {
    const groupedColDefs: ColDef | ColGroupDef[] = [];
    const colDefs = columnDefs.map(this.createColumnDef).map((col: any) => {
      col.suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        if (params.colDef.field && BLOCK_ENTER_EDITORS.includes(col.inputType)) {
          if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
            return true;
          }
        }
        return false;
      };
      return col;
    });

    // MARK FIX
    const sizeColumnIndex = findIndex(colDefs, (def) => def.renderer === 'size_array');
    // TODO: how do I get sizing info from validSizes api in this screen
    const sizingColumnDefs = ['L', 'M', 'S', 'XL', 'XS', 'XXS', 'XS'].map((size, idx, arr) =>
      this.createSizeColDefs(size, idx, arr)
    );
    const finalColumnDefs =
      sizeColumnIndex >= 0
        ? concat(slice(colDefs, 0, sizeColumnIndex), sizingColumnDefs, slice(colDefs, sizeColumnIndex + 1))
        : colDefs;

    let groupTemp: ColDef[] = [];
    function isLastInGroup(defs: ConfigurableGridConfigItem[], start: number, key: string): boolean {
      const def = defs[start];
      if (!def) return true;
      if (def.hidden || def.visible === false) {
        return isLastInGroup(defs, start + 1, key);
      }
      if (def.groupingKey != key) {
        return true;
      }
      return false;
    }
    columnDefs.forEach((colDef, index) => {
      if (colDef.hidden || colDef.visible === false) {
        return;
      }
      if (!colDef.groupingKey) {
        // don't push heatmapRenderer columns into group since they are handled separately
        if (finalColumnDefs[index].cellRenderer != 'heatmapRenderer') {
          groupedColDefs.push(finalColumnDefs[index]);
        }

        const maybeGroupbyTenantItem = this.getSelectedGroupByTenantItem();
        if (maybeGroupbyTenantItem) {
          // this overrides the regular ConfigurableGrid logic, which usually builds a treeColDef
          // instead for ReceiptsGrid, we're just overriding rowGroup and letting the grid
          // figure everything out
          if (colDef.dataIndex === maybeGroupbyTenantItem.dataIndex) {
            colDef.rowGroup = true;
            // @ts-ignore
            groupedColDefs.push(colDef);
          }
        }
      } else {
        // MARK FIX
        if (colDef.renderer && colDef.renderer === 'size_array') {
          // I believe this code path is defunct and has been replaced by 'size_array_configurable'
          // make sure all size columns are under this group
          groupedColDefs.push({
            headerName: colDef.groupingKey,
            children: finalColumnDefs.slice(index, sizingColumnDefs.length + 2),
            headerGroupComponent: 'customGroupHeader',
          });
          groupTemp = [];
        } else if (colDef.renderer && colDef.columns && colDef.renderer === 'size_array_configurable') {
          // make sure all size columns are under this group
          groupedColDefs.push({
            headerName: colDef.text,
            children: colDef.columns.map((size, idx, arr) => {
              return this.createSizeColDefs(
                size.id || '',
                idx,
                arr.map((s) => s.id || ''),
                colDef.dataIndex
              );
            }),
            headerGroupComponent: 'customGroupHeader',
          });
          groupTemp = [];
        } else {
          groupTemp.push(finalColumnDefs[index]);

          if (isLastInGroup(columnDefs, index + 1, colDef.groupingKey)) {
            groupedColDefs.push({
              headerName: colDef.groupingKey,
              children: groupTemp.slice(0),
              headerGroupComponent: 'customGroupHeader',
            });
            groupTemp = [];
          }
        }
      }
    });
    return groupedColDefs;
  };

  createSizeColDefs = (size: string, index: number, sizes: string[], dataIndex = 'heatmap') => {
    return {
      field: size,
      colId: `sizeHeatMap_${size}`,
      headerName: size,
      width: 100,
      cellClass: 'size-heatmap-cell',
      cellRenderer: 'heatmapRenderer',
      cellRendererParams: {
        sizeArrayIndex: index,
        dataIndex,
        valueAsCssColor: dataIndex !== 'heatmap',
      },
      sizes,
    };
  };

  handleChangeGroupByDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { groupByDropdownProps, setGroupBySelection } = this.props;
    if (isNil(groupByDropdownProps)) {
      return;
    }

    const newValue = event.currentTarget.textContent;
    const valueIndex = findIndex(groupByDropdownProps.options, (option) => {
      return newValue !== null && option.text.substr(0, 16) === newValue.substr(0, 16);
    });
    setGroupBySelection({
      selectedIndex: valueIndex,
      option: groupByDropdownProps.options[valueIndex],
    });
    this.gridApi.deselectAll();
  };

  isValidGroupBy = (groupByDropdown: SelectorSubheaderDropdownProps, selectedIndex: number) => {
    // check for selectionIndex in groupByDropdown.options first
    const selectedOption = groupByDropdown.options[selectedIndex];

    // selection index exceeds available groupBy options for current view
    if (isNil(selectedOption)) {
      return false;
    }

    // if an index is found, validate that it (option value) matches the actual groupBySelection.option value
    const foundItem =
      findIndex(groupByDropdown.options, (option) => option.text === this.props.groupBySelection?.option.text) !== -1;
    return foundItem;
  };

  getCorrectGroupByIndex = (groupByDropdown: SelectorSubheaderDropdownProps) => {
    const selectedOption = this.props.groupBySelection?.option;
    return findIndex(groupByDropdown.options, (option) => option.text === selectedOption?.text);
  };

  getSelectedGroupByTenantItem = () => {
    const { groupByDropdownProps, groupBySelection } = this.props;

    if (isNil(groupByDropdownProps)) {
      return;
    }

    let selectionIndex = groupBySelection ? groupBySelection.selectedIndex : -1;

    if (!this.isValidGroupBy(groupByDropdownProps, selectionIndex)) {
      // if not valid, reset to default selection or first item in groupBy
      // this was to fix groupBys set on a different view that is not available on the current view
      selectionIndex = groupByDropdownProps.defaultSelection ? groupByDropdownProps.defaultSelection : 0;

      // set new selection in state so rest of component catches the change
      this.props.resetGroupBySelection();
    } else {
      // if valid, make sure correct index is selected for current options
      selectionIndex = this.getCorrectGroupByIndex(groupByDropdownProps);
    }

    return groupByDropdownProps.options[selectionIndex];
  };

  getGroupedData = (data: BasicPivotItem[]): AgFlatResult | undefined => {
    const selectedGroupBy = this.getSelectedGroupByTenantItem();
    if (!selectedGroupBy) {
      return;
    }

    if (selectedGroupBy.dataIndex && data) {
      // const getFlat = memoize(() => flattenToLeaves(data));
      // const groupByKey = selectedGroupBy.dataIndex;
      // const result = groupedToAgFlatTree(
      //   generateOrderedGroups(getFlat(), {
      //     ...(groupByDropdownProps as SubheaderDropdownSlice),
      //     selection: selectionIndex,
      //   }),
      //   groupByKey,
      //   search,
      //   initialSortBy,
      //   this.props.showFlowStatus && flowStatus ? flowStatus : [],
      //   groupByDropdownProps.options,
      //   identityField === 'id' ? 'name' : `member:${identityField}:name`,
      //   true
      // );
      // const maybeGroupCol = this.columnApi.getColumns().find((c) => c.getColId() === selectedGroupBy.dataIndex);
      // maybeGroupCol?.setRowGroupActive(true);
      return;
    }

    return;
  };

  refreshGrid = () => {
    if (this.gridApi) {
      this.gridApi.redrawRows();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChangeFloorsetDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // noop
  };

  generatePublishedText = (data: BasicPivotItem[]) => {
    if (isNil(data)) {
      return [];
    }

    let publishedTotal = 0;
    let unpublishedTotal = 0;
    let partialTotal = 0;
    let noReceiptsTotal = 0;
    data.forEach((item: BasicPivotItem) => {
      if (Object.keys(item).length > 3) {
        // Groups show up in data. Makes it not count them
        const isPublished = item[IS_PUBLISHED];

        switch (isPublished) {
          case PUBLISHED_TEXT:
            publishedTotal += 1;
            break;
          case UNPUBLISHED_TEXT:
            unpublishedTotal += 1;
            break;
          case PARTIAL_PUBLISHED_TEXT:
            partialTotal += 1;
            break;
          default:
            // per CA, let the rest fall into no receipts
            noReceiptsTotal += 1;
            break;
        }
      }
    });

    return [
      `Published: ${publishedTotal}`,
      `Planned: ${unpublishedTotal}`,
      `Partial: ${partialTotal}`,
      `No Receipts: ${noReceiptsTotal}`,
    ];
  };

  getArrowFromValue = (value: number) => {
    // Math.sign return 1 for positive, 0 for 0, and -1 for negative
    switch (Math.sign(value)) {
      case 1:
      case 0:
        return ARROWDIRECTIONS.UP;
      case -1:
      default:
        return ARROWDIRECTIONS.DOWN;
    }
  };

  getActiveFloorset = (): string => {
    // noop
    return '';
  };

  onSaveClick = () => {
    const { styleColorsEdited } = this.state;

    Axios.get(this.props.planningApi.url, {
      params: {
        ...this.props.planningApi.params,
        products: styleColorsEdited,
      },
    });
  };

  getRowNodeValues = (dataIndex: string, nodes: IRowNode[]) => {
    const nodeValues = nodes.map((node) => {
      let value;

      if (node.data[dataIndex]) {
        value = node.data[dataIndex];
      } else {
        // try to strip down index to field only
        const fieldOnly = dataIndex.split(':')[1];
        value = node.data[fieldOnly] || 0;
      }

      return value;
    });

    return nodeValues;
  };

  groupRowAggNodes = (params: GetGroupRowAggParams) => {
    const { nodes } = params;
    if (nodes.length === 0) {
      return;
    }
    let colApi = this.columnApi;
    if (isNil(colApi)) {
      colApi = (nodes[0] as any).columnApi;
    }
    // get and store all aggregator or aggregatorFunction values from column configs
    const aggTypes = {};
    const aggResults = {};
    const columns = colApi.getColumns();

    columns?.forEach((column) => {
      const colId: string = column.getColId();
      const configColumn = this.props.columnDefs.find((def) => {
        return def.dataIndex === colId;
      });
      if (!isNil(configColumn)) {
        const { dataIndex, aggregator, aggregatorFunction } = configColumn;
        // We have special logic for checkbox "aggregation" (through indeterminance)
        // MARK FIX
        if (configColumn.renderer === 'checkbox') {
          aggTypes[dataIndex] = 'checkbox';
          return;
        }
        if (configColumn.renderer === 'validValuesCheckbox') {
          aggTypes[dataIndex] = 'validValuesCheckbox';
          return;
        }
        if (isNil(aggregator) && isNil(aggregatorFunction)) {
          return;
        }
        if (aggregator === 'eval') {
          aggTypes[dataIndex] = aggregatorFunction;
        } else {
          aggTypes[dataIndex] = aggregator || aggregatorFunction;
        }
      }
    });

    forEach(aggTypes, (value: string, key) => {
      // handle normal aggregations
      const nodeValues = this.getRowNodeValues(key, nodes);

      switch (value) {
        case 'sum':
        case 'min':
        case 'max': {
          const result = this.math[value](nodeValues);
          aggResults[key] = result;
          break;
        }
        case 'count': {
          const count = this.math.size(nodeValues);
          aggResults[key] = count;
          break;
        }
        case 'avg': {
          const avg = this.math.mean(nodeValues);
          aggResults[key] = avg;
          break;
        }
        case 'checkbox': {
          const allThere = every(nodeValues, (v) => (v ? true : false));
          const someThere = some(nodeValues, (v) => (v ? true : false));
          // For indeterminance, when only *some* are "selected", we set to the unknown "null"
          aggResults[key] = allThere ? true : someThere ? null : false;
          break;
        }
        case 'validValuesCheckbox': {
          const flatValues: string[] = flatten(nodeValues);
          const valueTotals = reduce(
            flatValues,
            (totals, value) => {
              if (has(totals, value)) {
                totals[value] += 1;
              } else {
                totals[value] = 1;
              }
              return totals;
            },
            {} as Record<string, number>
          );
          const orderedHeaders = this.vvHeaderMap[key];
          const orderedValues = orderedHeaders?.map((header) => valueTotals[header]) || [];
          aggResults[key] = orderedValues;
          break;
        }
        default: {
          // handle custom aggregations
          const column = colApi.getColumn(key);
          const colId = column?.getColId();
          const configColumn = this.props.columnDefs.find((def) => {
            return def.dataIndex === colId;
          });

          if (!isNil(configColumn) && !isNil(configColumn.aggregatorFunction)) {
            // parse and get expression dataIndices to calculate
            const parsedExpression = this.math.parse(configColumn.aggregatorFunction);
            const expressionNames = flow(
              () => parsedExpression.filter((node) => node.isSymbolNode && node.name !== 'AGG'),
              map((node) => node.name || '_')
            )();

            const aggregationHandler = partial(this.handleCustomAggregation, [
              nodes,
              parsedExpression,
              expressionNames,
            ]);
            aggResults[key] = aggregationHandler(value);
          }
        }
      }
    });

    return aggResults;
  };

  handleCustomAggregation = (
    nodes: IRowNode[],
    parsedExpression: globalMath.MathNode,
    expressionNames: string[],
    _aggregatorFunction: string
  ) => {
    // get values for expression
    const exprValues = flow(
      () => expressionNames,
      reduceFP((acc, id = '') => {
        acc[id] = coalesce(this.getRowNodeValues(id, nodes), []);
        return acc;
      }, {})
    )();

    let result;
    try {
      result = parsedExpression.evaluate({ ...exprValues });
    } catch (error) {
      debounce(() => {
        toast.error(`An error occurred aggregating values in the grid`);
        ServiceContainer.loggingService.error(`An error occurred aggregating values in the grid`);
      }, 1000);
    }

    if ((isNumber(result) && (isNaN(result) || !isFinite(result))) || isNil(result)) {
      result = 0;
    }

    return result;
  };

  getPostObject = (field: string, value: PostValue, data: BasicPivotItem, parentData: string[] = []) => {
    const { isStyleColorEdit } = this.props;
    // ccseason is a style attribute
    const id = isStyleColorEdit && field !== 'ccseason' ? data[STYLE_COLOR_ID] : data[STYLE_ID];

    if (isEmpty(parentData)) {
      // regular attribute

      let val = !isNil(value) ? value : '';
      if (val === true) {
        // these checks specificaly guard against a nil value being sent back as a zero-length string
        // and can instead be returned as the string 'true' or 'false'
        val = 'true';
      } else if (val === false) {
        val = '';
      }

      return {
        id,
        [field]: val,
      };
    }

    return {
      id,
      parent: parentData,
    };
  };

  getSelectedRows = (): PivotBasicItem[] => {
    if (this.gridApi == null) return [];
    const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
    const floorsetId = this.getActiveFloorset();
    return selectedNodes
      .filter((n) => {
        return isNil(n.allChildrenCount) || n.allChildrenCount <= 0;
      })
      .map((n) => {
        const rowData = n.data;
        rowData.floorset = floorsetId;
        return rowData;
      });
  };

  getAssociatedConfigCol = (colDef: ColDef) => {
    const configedColumn = this.props.columnDefs.find((col) => {
      return col.dataIndex === colDef.field;
    });
    return configedColumn;
  };

  columnUsesGenericUpdate = (colDef: ColDef) => {
    return this.getAssociatedConfigCol(colDef)?.useMassEditUpdate === true;
  };

  submitMassColumnUpdate = async (params: MassColumnUpdateParams) => {
    const massEditConfig = this.props.massEditConfig;

    if (isNil(massEditConfig)) {
      logError(
        `Cannot update ${get(params, 'dataIndex', '')} without massedit configured in useMassEditUpdate mode.`,
        null
      );
      return;
    }

    const coordinates = params.nodes.map((rowData) =>
      omitBy(
        mapValues(massEditConfig.coordinateMap, (v) => {
          const value = rowData[v];
          return value;
        }),
        isNil
      )
    );
    const pKey = params.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    await ServiceContainer.pivotService.coarseEditSubmitData({
      coordinates,
      [pKey]: params.value,
    });
  };

  submitGenericMassUpdate = async ({ colDef, node }: CellValueChangedEvent) => {
    const configedColumn = this.getAssociatedConfigCol(colDef);
    if (this.props.massEditConfig == null || configedColumn == null) {
      logError(
        `Cannot update ${colDef.colId}. Somehow set to generic update without updateCoordinateMap property.`,
        null
      );
      return;
    }
    const coordMap = this.props.massEditConfig.coordinateMap;
    const field = configedColumn.dataIndex;
    const value = node.data[field];
    let calcValue = value;
    if (isBoolean(value)) {
      calcValue = value ? 'true' : '';
    }
    let coordVals: {
      [k: string]: string;
    }[];
    // If we are cascading, we handle that here. Otherwise, we fall back to assuming we only mess
    // we row as is.
    if (node.allChildrenCount && node.allChildrenCount > 0 && configedColumn.cascadeGroupSelection) {
      if (isNil(value)) return;
      const itemsToUpdate: IRowNode[] = [];

      // update row node data without triggering cellValueChanged handler
      node.childrenAfterFilter?.forEach((rowNode) => {
        if (!isNil(rowNode.allChildrenCount) && rowNode.allChildrenCount > 0) {
          // skip nodes that are group nodes.
          return;
        }
        rowNode.data[field] = value;
        itemsToUpdate.push(rowNode.data);
      });

      this.gridApi.applyTransaction({ update: itemsToUpdate });
      // we need to redraw the rows as there's no state modifications causing a whole render loop.
      this.gridApi.redrawRows();
      // Generate a set of coordinates for each row (filter out nils for the set only having a subset of avail props)
      coordVals = itemsToUpdate.map((item) => {
        return omitBy(
          mapValues(coordMap, (v) => item[v]),
          isNil
        );
      });
    } else {
      coordVals = [
        omitBy(
          mapValues(coordMap, (v) => node.data[v]),
          isNil
        ),
      ];
    }

    const pKey = configedColumn.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    const payload = {
      coordinates: coordVals,
      [pKey]: calcValue,
    };
    await ServiceContainer.pivotService.coarseEditSubmitData(payload);
  };

  submitGenericUpdate = async ({ colDef, value, node }: CellValueChangedEvent) => {
    const configedColumn = this.getAssociatedConfigCol(colDef);
    if (this.props.updateCoordinateMap == null || configedColumn == null) {
      logError(`Cannot update ${colDef.colId} without massedit configured in useMassEditUpdate mode.`, null);
      return;
    }
    // This fun mess removes that silly wrapped colon stuff (eg: attribute:<x>:id)
    // This is the more concise version: .replace(/(?:^.*?:)?([^:]*)(?::.*)?/, '$1')
    const key = configedColumn.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    const coordMap = this.props.updateCoordinateMap;
    const coordVals = omitBy(
      mapValues(coordMap, (v) => node.data[v]),
      isNil
    );
    const payload: GranularEditPayloadItem = {
      coordinates: coordVals,
    };
    // These being top level is...a bit annoying.
    if (configedColumn.dataIndex.indexOf('member:subclass') >= 0) {
      // we need to do it this way because the config is stupid :/
      // there is an attribute:subclass which is **different** than member:subclass -,-
      payload.parent = value;
    } else {
      let calcValue = value;
      if (isBoolean(value)) {
        calcValue = value ? 'true' : '';
      }
      payload[key] = calcValue;
    }
    await ServiceContainer.pivotService.granularEditSubmitData([payload]);
    // await fetch new value set & merge
  };

  publishAssortment = async (actionType?: 'publish' | 'unpublish') => {
    if (!actionType) return;
    const selectedItems = this.state.selectedItems;

    if (isEmpty(selectedItems)) {
      return;
    }

    const update = selectedItems.map((item) => {
      return {
        product: item['product'],
        time: item['time'],
        ['dc_publish']: actionType === 'publish' ? 1 : 0,
      };
    });

    if (this.props.submitPayload) {
      const payload = {
        keyField: ['product', 'time'],
        create: [],
        update,
        delete: [],
      };
      if (this.props.dataApi && this.props.dataApi.isListData && !isEmpty(this.props.data)) {
        // this is a new code path added for allocation
        // an arbitrary pivot needs to be called on publish completion,
        // then the worklist needs to be cleared
        // in this case, it is presumed that all items are checked
        try {
          const dataApi = this.props.dataApi;
          await ServiceContainer.pivotService.listData(dataApi.defnId, ASSORTMENT, dataApi.params);

          const itemIds = this.props.data.map((i) => i.product);
          const validSizeMembers = await Promise.all(
            this.props.data.map(async (item) => {
              const validMemberData = await getValidMembers({
                member: 'stylecolorsize',
                parent: item.id,
              });
              return validMemberData.map((sz: { id: string }) => {
                return { product: sz.id, location: item.location, time: item.time };
              });
            })
          );
          const sizeIds = flatten(validSizeMembers);

          // doomed
          const payload: CoarseEditPayload = {
            coordinates: sizeIds,
            holdback: null,
            ecom_reserve: null,
            store_reserve: null,
          };
          await ServiceContainer.pivotService.coarseEditSubmitData(payload);

          const worklistService = WorklistService();
          await worklistService.removeItemsFromWorklist(itemIds);
        } catch (e) {
          toast.error('An error occured publishing your assortment');
        } finally {
          toast(<div>Your allocations have been published, clearing your worklist now</div>, {
            position: toast.POSITION.TOP_RIGHT,
            type: 'info',
            toastId: 'clear-worklist',
          });
          this.setState({
            notificationModal: false,
          });
        }
        return;
      }
      this.props.submitPayload(payload).then(() => {
        this.setState({
          notificationModal: false,
        });
      });
    }
  };

  renderConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={this.state.notificationModal}
        descriptionText={`Are you sure you wish to ${this.state.actionType}?`}
        onConfirm={() => {
          this.publishAssortment(this.state.actionType);
          this.gridApi.deselectAll();
        }}
        onCancel={() => {
          this.gridApi.deselectAll();
          this.setState({
            notificationModal: false,
          });
        }}
      />
    );
  };

  handleCheck = () => {
    // NOOP
  };

  private handleGetContextMenuItems = (): (string | MenuItemDefWithId)[] => {
    const expandElement = document.createElement('i');
    expandElement.classList.add('far', 'fa-chevron-double-down');
    const collapseElement = document.createElement('i');
    collapseElement.classList.add('far', 'fa-chevron-double-up');
    return [
      {
        name: 'Expand All',
        _id: 'expandAll',
        icon: expandElement,
        action: () => {
          this.gridApi.forEachNode((node) => {
            node.setExpanded(true);
          });
        },
      },
      {
        name: 'Collapse All',
        _id: 'contractAll',
        icon: collapseElement,
        action: () => {
          this.gridApi.forEachNode((node) => {
            node.setExpanded(false);
          });
        },
      },
    ];
  };

  submitChanges = async () => {
    const coordMap = this.props.updateCoordinateMap;
    if (coordMap == null) return;

    const payload: GranularEditPayloadItem[] = this.state.rowEdits
      .map((row) => {
        const coordinates = omitBy(
          mapValues(coordMap, (v) => row[v]),
          isNil
        );
        const dataUpdates: Record<string, unknown> = omitBy(
          omit(
            mapValues(row, (dVal) => {
              if (isBoolean(dVal)) {
                return dVal ? 'true' : '';
              } else return dVal;
            }),
            Object.values(coordMap)
          ),
          isNil
        );
        if (Object.keys(dataUpdates).length <= 0) {
          return null;
        }
        return {
          coordinates,
          ...dataUpdates,
        } as GranularEditPayloadItem;
      })
      .filter((row) => !isNil(row)) as GranularEditPayloadItem[];

    await ServiceContainer.pivotService.granularEditSubmitData(payload);
    this.detailCache.clear();
    this.props.updateAssortmentPlan();
  };

  _generateGridProps = () => {
    const { keys, dataLoaded } = this.props;
    const { gridScrollTo } = this.state;
    const identityField = keys.idProp;
    const gridOptions: DataGridProps = {
      data: [],
      isPrintMode: false,
      columnDefs: [],
      className: gridListPairStyle,
      loaded: dataLoaded,
      scrollTo: gridScrollTo,
      singleClickEdit: true,
      rowHeight: this.props.gridRowHeight,
      overrideDefaultContextMenuActions: true,
      onCellClicked: (event: CellClickedEvent) => {
        if (event && event.data && !event.data.$$GroupHeader) {
          let key = identityField === 'id' ? 'stylecolor' : `member:${identityField}:id`;
          const identityValue = event.data[key];
          const companionData = this.generateCompanionViewData(this.props.data);

          const index = companionData
            ? companionData.findIndex((datum: ListViewable) => {
                const fieldFound = datum[key] ? datum[key] : datum.name;
                // Check if the field is present as a member
                if (datum[`member:${key}:id`] == identityValue) {
                  key = `member:${key}:id`;
                  return true;
                }
                return fieldFound === identityValue;
              })
            : event.data[key];

          this.setState({
            companionScrollTo: {
              eventId: Date.now(),
              where: {
                key: key,
                value: identityValue,
              },
            },
            selectedIndex: index,
            activeStyleColor: event.data['stylecolor'],
          });
        }
      },
      onCellEditingStarted: (event: CellEditingStartedEvent) => {

        // TODO: store invalid dataIndices in state for quick access within method, can loop and clear each one
        // clear invalid data here so invalid styles are cleared
        resetAsyncValidationData(event);
      },
      extraAgGridProps: {
        purgeClosedRowNodes: true,
        valueCache: true,
        getRowId: (params) => {
          return coalesce(params.data._id, params.data.id);
        },
        getRowHeight: (params: any) => {
          const isDetailRow = params.node.detail;
          if (isDetailRow) {
            if (this.props.detailRowCountKey != null && params.data[this.props.detailRowCountKey] >= 0) {
              return params.data[this.props.detailRowCountKey] * (this.props.gridRowHeight || 30) + 50;
            } else {
              return 420; // default height for a detail row if no key provided
            }
          } else return this.props.gridRowHeight;
        },
        getGroupRowAgg: this.groupRowAggNodes,
        suppressColumnVirtualisation: true, // styling gets broken when this is on
        getContextMenuItems: this.handleGetContextMenuItems,
        onCellValueChanged: async (params: CellValueChangedEvent) => {
          const { colDef } = params;
          // NOTE: it's going to get a bit worse before it gets better here. I'm sorry.
          // This *should* completely replace all that chiz below once we have the single target
          // endpoint and the willpower. - Mark :/
          if (this.columnUsesGenericUpdate(colDef)) {
            this.submitGenericMassUpdate(params);
            return;
          }

          const field = colDef.field;

          if (this.props.updateCoordinateMap && field) {
            const coordinates = Object.values(this.props.updateCoordinateMap);
            const editedRow = this.state.rowEdits.find((row) => {
              return isEqual(pick(row, coordinates), pick(params.data, coordinates));
            });
            if (editedRow) editedRow[field] = params.newValue;
            else
              this.state.rowEdits.push({
                ...pick(params.data, coordinates),
                [field]: params.newValue,
              });
          }
          this.props.dataRules.map((dR) => {
            switch (dR.rule) {
              case 'setPreviousOnEdit':
                if (params.newValue == null || field !== dR.field) return;
                const copyfield = dR.params.indexToApply;
                const previousRow = this._getPreviousWeekNode(params);
                if (previousRow) {
                  const currentVal = this._getVal(field, previousRow, params.api);
                  if (currentVal != null) return;
                  const valToCopy = this._getVal(copyfield, previousRow, params.api);
                  previousRow.setDataValue(field, valToCopy);
                  params.api.refreshCells({
                    rowNodes: [previousRow],
                  });
                }
                break;
              default:
              // do nothing
            }
          });
        },
        suppressRowClickSelection: true,
        onSelectionChanged: this.handleCheck,
      },
    };
    return gridOptions;
  };

  generateSubheaderExtraDropdownProps(): SubheaderDropdownProps[] {
    const { groupByDropdownProps } = this.props;
    let dropdowns: SubheaderDropdownProps[] = [];

    if (!isNil(groupByDropdownProps)) {
      dropdowns = concat(dropdowns, {
        ...groupByDropdownProps,
        handleChangeOnDropdown: this.handleChangeGroupByDropdown,
      });
    }

    return dropdowns;
  }

  generateSubheaderExtraActionButtonProps(): SubheaderActionButtonProps {
    const { massEditConfig } = this.props;

    const massEdit = isNil(massEditConfig)
      ? undefined
      : {
          config: massEditConfig,
          title: massEditConfig.title,
          handleSubmit: () => {
            this.gridApi?.deselectAll();
            this.props.onRefreshConfigurableGridData();
          },
          getSelectedItems: () => this.getSelectedRows(),
          handleCancel: () => this.gridApi?.deselectAll(),
          gridApi: this.gridApi,
          dataLoading: this.state.gridRdyCnt === 0,
        };

    return {
      massEdit,
      postAction: undefined,
    };
  }

  render() {
    const {
      title,
      configLoaded,
      showFlowStatus,
      massEditConfig,
      showPublish,
      configuratorViewDefn,
      unmodifiedViewDefn,
      defaultCompanionSortField,
      groupByDropdownProps,
      topAttributesData,
      treeColumnDefinition,
    } = this.props;

    if (!configLoaded) {
      return <Overlay type="loading" visible={true} />;
    }

    const viewConfigurator = this.onUpdateConfig &&
      configuratorViewDefn &&
      unmodifiedViewDefn && {
        viewConfig: configuratorViewDefn,
        unmodifiedViewDefn: unmodifiedViewDefn,
        updateConfig: this.onUpdateConfig,
        showPinCheckboxForGrid: true,
        companionData: {
          companionSortDirection: this.state.companionSortDirection,
          companionCollapsed: this.state.companionCollapsed,
          companionSortField: this.state.companionSortField,
        },
        defaultCompanionData: {
          companionSortDirection: 'desc',
          companionCollapsed: false,
          companionSortField: defaultCompanionSortField,
        },
      };

    const finalTreeColumnDefinition = isNil(treeColumnDefinition)
      ? treeColumnDefinition
      : {
          ...treeColumnDefinition,
          treeData: true,
          filterValueGetter: (params: ValueGetterParams) => {
            return isArray(params.data.group) ? params.data.group[0] : params.data.group;
          },
          valueFormatter: (params: ValueFormatterParams) => {
            // if params.data exists, this formats a normal cell
            // otherwise, this formats the values that show up when you filter/funnel
            if (params.data) {
              if (isArray(params.value) && params.value.length === 1) {
                return last(params.value);
              } else {
                // Idea here is the base value is likely already rendered in another column.
                return '_';
              }
            } else {
              return params.value;
            }
          },
        };

    const subheaderProps: SubheaderOwnProps = {
      title: title || '',
      showFlowStatus,
      summary: '',
      showSearch: true,
      extraDropdowns: this.generateSubheaderExtraDropdownProps(),
      extraActionButtons: this.generateSubheaderExtraActionButtonProps(),
      downloadLink: this.props.subheader?.downloadLink,
      errorCondition: this.props.subheaderErrorText,
      viewConfigurator,
      favoritesSaveOverride: {
        groupBySelection: groupByDropdownProps?.selection as number | undefined,
      },
    };

    const gridOptions = this._generateGridProps();
    const groupedColumnDefs = this.props.detailColumnDefs ? this.createGroupedColumns(this.props.detailColumnDefs) : [];

    const masterColDefs = this.props.columnDefs ? this.createGroupedColumns(this.props.columnDefs) : [];
    gridOptions.columnDefs = masterColDefs;
    (gridOptions.onGridReady = (params: GridReadyEvent) => {
      if (params.api) {
        this.gridApi = params.api;
        params.api.setServerSideDatasource(this.serverSource);
      }

      if (params.columnApi) {
        this.columnApi = params.columnApi;
      }
      this.setState({ gridRdyCnt: this.state.gridRdyCnt + 1 }); // force a re-render to attach events to the grid
    }),
      (gridOptions.extraAgGridProps = {
        ...gridOptions.extraAgGridProps,
        detailCellRenderer: 'masterDetail',
        onFirstDataRendered: (params: FirstDataRenderedEvent) => {
          if (this.props.data.length <= 0) return;
          defer(() => {
            params.api.forEachNode((node) => {
              node.group ? undefined : node.setExpanded(true);
            });
          });
        },
        masterDetail: true,
        detailCellRendererParams: {
          dataGridProps: {
            ...this._generateGridProps(),
            frameworkComponents,
            nonFrameworkComponents,
            columnDefs: groupedColumnDefs,
          },
          detailHeight: 420,
          getDetailRowData: async (params: GetDetailRowDataParams) => {
            const { fetchDetails } = this.props;
            if (isNil(fetchDetails)) return;
            const topMembers = fetchDetails.params.topMembers
              .map((memKey) => {
                return params.data[memKey];
              })
              .join(',');
            const defnId = fetchDetails.defnId;
            const reqParams = {
              ...fetchDetails.params,
              nestData: false, // we require flat responses for ConfigurableGrid
              topMembers,
            };
            const cacheHash = PivotServiceCache.hash({
              endpoint: RequestEndpoint.listData,
              defnId,
              inputs: reqParams,
              appName: AppType.Assortment,
            });
            let rows: TreePivot;
            if (this.detailCache.has(cacheHash)) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              rows = this.detailCache.get(cacheHash)!.tree;
            } else {
              rows = await service.pivotService
                .listData(fetchDetails.defnId, AppType.Assortment, reqParams, false)
                .then((resp) => {
                  this.detailCache.set(cacheHash, resp);
                  return resp.tree;
                });
            }
            if (this.props.updateCoordinateMap) {
              const coordinates = Object.values(this.props.updateCoordinateMap);
              let incrId = 0;
              rows.forEach((serverRow) => {
                serverRow._id = incrId++;
                this.state.rowEdits.forEach((editRow) => {
                  if (isEqual(pick(serverRow, coordinates), pick(editRow, coordinates))) {
                    assign(serverRow, editRow);
                  }
                });
              });
            }
            // const rows = this.serverSource.getDetailRows(params);
            params.successCallback(rows);
          },
        },
        // detailRowHeight: 420,
      });
    // Added to prevent valueGetters from other tree col defs to be added (intereferes with valueFormatter)
    if (finalTreeColumnDefinition) {
      finalTreeColumnDefinition.valueGetter = undefined;
    }

    if (massEditConfig != null || showPublish == true) {
      if (finalTreeColumnDefinition != null) {
        finalTreeColumnDefinition.checkboxSelection = true;
      } else if (groupedColumnDefs.length > 0) {
        const showHeaderCheckbox: any = this.props.showPublish
          ? { headerCheckboxSelection: true, headerClass: headerCheckbox }
          : {};
        groupedColumnDefs.unshift({
          checkboxSelection: true,
          headerName: '',
          width: 40,
          pinned: true,
          ...showHeaderCheckbox,
        });
      }
    }

    const testArrowValue = (num: number) => {
      if (num > 100000 || num < -100000) {
        return '~';
      }
      if (!isNumber(num)) {
        return '';
      }
      return num;
    };

    let arrowValueGroup: ArrowValueRendererProps[] = [];
    if (topAttributesData && unmodifiedViewDefn && unmodifiedViewDefn.topAttributes) {
      arrowValueGroup = (unmodifiedViewDefn.topAttributes.right as TopAttribute[]).map((config) => {
        const { text = '', dataIndex, renderer } = config;
        const valueRenderer = Renderer[renderer];
        const testValue = testArrowValue(topAttributesData[dataIndex]);
        const finalValue = valueRenderer ? Renderer[renderer](testValue) : testValue;
        return {
          header: text,
          value: finalValue.toString(),
          arrowDirection: this.getArrowFromValue(topAttributesData[dataIndex]),
        };
      });
    }

    const menuItems = [
      {
        name: 'Publish',
        action: () => {
          this.setState({
            actionType: 'publish',
            notificationModal: true,
          });
        },
        icon: 'fal fa-plus-circle',
        tooltip: 'Publish selected items',
      },
      {
        name: 'Unpublish',
        action: () => {
          this.setState({
            actionType: 'unpublish',
            notificationModal: true,
          });
        },
        icon: 'fal fa-minus-circle',
        tooltip: 'Unpublish selected items',
      },
    ];
    const showPublishText = isNil(this.props.showPublishText) ? true : this.props.showPublishText;
    const showExtraRow = showPublishText || !isEmpty(arrowValueGroup) || showPublish;
    const extraRow = (
      <div style={{ display: 'flex' }}>
        {showPublishText && (
          <div className={styles.publishedContainer}>
            {this.generatePublishedText([]).map((text: string) => {
              return <span key={text}>{text}</span>;
            })}
          </div>
        )}
        <div className={styles.arrowGroup}>
          {arrowValueGroup.map((config) => {
            return <ArrowValueRenderer {...config} key={config.header} />;
          })}
        </div>
        <div>
          {showPublish && (
            <SplitButton
              text={menuItems[0].name}
              icon={menuItems[0].icon as string}
              isLoading={false}
              isDisabled={this.getSelectedRows().length === 0}
              onButtonClick={menuItems[0].action}
              menuItems={menuItems.slice(1)}
            />
          )}
        </div>
      </div>
    );

    return (
      <div className={listPairStyle}>
        <Subheader {...subheaderProps} />
        <div className="data-container">
          <div className={extraRowContainerStyles}>
            {showExtraRow ? extraRow : undefined}
            <div className={gridContainerStyle}>
              <ExtendedDataGrid
                {...gridOptions}
                frameworkComponents={frameworkComponents}
                nonFrameworkComponents={nonFrameworkComponents}
              />
            </div>
          </div>
        </div>
        <div className="submission-container">
          <Button onClick={this.submitChanges}>Submit</Button>
          <Button>Reset</Button>
        </div>
        {this.renderConfirmationModal()}
      </div>
    );
  }
}

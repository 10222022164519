import { combineReducers, AnyAction as BaseAction } from 'redux';
import { AppDispatch, AppState, AppThunkDispatch } from 'src/store';

import service from 'src/ServiceContainer';
import { ASSORTMENT, DIMENSION_PRODUCT, LEVEL_STYLE_COLOR } from 'src/utils/Domain/Constants';
import { getGroupBySelectedOptionProperty, getSortBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { ListDataOptions, Pivot } from 'src/worker/pivotWorker.types';
import { concat, get, isEmpty, isNil, omit } from 'lodash';
import { receiveError as shareDataError } from './SharedData.slice';
import { CacheCheckResponse } from 'src/services/pivotService';

import canvasViewReducer from './CanvasView/CanvasView.slice';
import summaryViewReducer from './SummaryView/SummaryView.slice';
import topTYvsLYReducer from './TopTYvsLY/TopTYvsLY.slice';
import flowTypeReducer from './FlowType/FlowType.slice';
import gridViewReducer from './GridView/GridView.slice';
import collectionViewReducer from './CollectionView/CollectionView.slice';
import sharedDataReducer from './SharedData.slice';
import { isListDataApi, DataApi } from 'src/services/configuration/codecs/confdefnView';
import { SubheaderSlice } from 'src/components/Subheader/Subheader.slice';
import { BasicTenantConfigViewItem } from 'src/dao/tenantConfigClient';

const styleColorReviewSlice = combineReducers({
  sharedData: sharedDataReducer,
  collectionView: collectionViewReducer,
  canvasView: canvasViewReducer,
  summaryView: summaryViewReducer,
  gridView: gridViewReducer,
  flowType: flowTypeReducer,
  topTYvsLY: topTYvsLYReducer,
});
export interface StyleColorReviewSlice extends ReturnType<typeof styleColorReviewSlice> {}
export default styleColorReviewSlice;

export type ExtraPivotOptions = Record<string, string | number[] | undefined>;

function getDefaultAggBy(key: 'aggBy' | 'topAggBy', dataApi?: DataApi): string {
  return isListDataApi(dataApi) ? get(dataApi.params, key, LEVEL_STYLE_COLOR) : LEVEL_STYLE_COLOR;
}

export function getConfigureModalAggBys(currentAggBys: string[], dataApi?: DataApi): string {
  let defaultAggBy = '';
  let aggBys: string[] = [];

  if (isListDataApi(dataApi)) {
    if (dataApi.params.topAggBy) {
      defaultAggBy = getDefaultAggBy('topAggBy', dataApi);
      aggBys = concat([defaultAggBy], currentAggBys);
    } else {
      defaultAggBy = getDefaultAggBy('aggBy', dataApi);
      aggBys = concat(currentAggBys, defaultAggBy);
    }
  } else {
    defaultAggBy = getDefaultAggBy('aggBy');
    aggBys = concat(currentAggBys, defaultAggBy);
  }

  return aggBys.filter((i) => !isEmpty(i)).join(',');
}

interface GetAggByOptions {
  /** If true, sets the value to the groupBy dropdown value instead of potentially resetting it to an empty string  */
  forceReload?: boolean;
  /** Allows the default bottom level to be ignored. */
  includeBottomLevel?: boolean;
  /** Allows for a dynamic value for retreiving the correct property value for groupingKey. Some views are not configured with `groupingKey` */
  groupingKeyOverride?: keyof BasicTenantConfigViewItem;
}

export function getAggBys(state: SubheaderSlice, dataApi?: DataApi, options?: GetAggByOptions): string {
  const { forceReload = false, includeBottomLevel = true, groupingKeyOverride = 'groupingKey' } = options || {};
  const subheaderGroupBy = state.groupBy;
  const groupingKey = getGroupBySelectedOptionProperty(subheaderGroupBy, groupingKeyOverride);
  const groupByDimension = getGroupBySelectedOptionProperty(subheaderGroupBy, 'dimension');
  let groupBy = '';

  if (forceReload) {
    groupBy = groupingKey;
  } else {
    groupBy = DIMENSION_PRODUCT !== groupByDimension || subheaderGroupBy.refreshOnChange ? groupingKey : '';
  }

  const defaultAggBy = getDefaultAggBy('aggBy', dataApi);
  return includeBottomLevel ? [groupBy, defaultAggBy].filter((i) => !isEmpty(i)).join(',') : groupBy;
}

/**
 * Combines a variety of inputs into a single object to be provided to the pivot request
 *
 * @param {ListDataOptions} options - grab bag of extra stuff to be passed to the pivot
 * @param {DataApi} [dataApi=undefined] - configured dataApi details to build additional pivot aggregations
 * @returns {ListDataOptions} consolidated options
 */
export function organizeListDataOptions(extraOptions: ExtraPivotOptions, dataApi?: DataApi): ListDataOptions {
  if (isListDataApi(dataApi)) {
    if (!dataApi.isListData) {
      service.loggingService.warn(
        `Potentially malformed ClientDataApi detected, check UI Confdefn for 'isListData: false'`
      );
      return {} as ListDataOptions;
    }

    // order matters here, anything in extraOptions will overwrite what is in dataApi.params.
    // if you want to take a configured value from dataApi.params (e.g. aggBy), just don't include it in extraOptions
    return {
      ...omit(dataApi.params, 'topAggBy'), // topAggBy is only used client side
      ...extraOptions,
    };
  }

  return extraOptions;
}

export function getSortBy(state: SubheaderSlice) {
  return getSortBySelectedOptionProperty(state.sortBy, 'dataIndex');
}

/**
 * Fetches cached data if possible and returns immediately to caller
 * while waiting for the live data load to be ready to return to caller.
 * Allows caching of any pivot that is configured to return a cached/live data promise
 *
 * @param requestPromises the pivotService promise that contains the cached/live data promises
 * @param requestData global state dispatch action to be called before the async call is made
 * @param receiveCacheHash global state dispatch action to store the cacheHash for quick lookup later
 * @param receiveCachedData global state dispatch action when the cached data is returned
 * @param receiveLiveData global state dispatch action when the 'live' data is returned
 * @param fetchWorklist boolean flag that when set 'true' retrieves worklist items
 * @returns {(dispatch: Dispatch<S>, getState: () => S) => Promise<BaseAction | void>}
 */
export function cacheCheckFetchPivotData(
  requestPromises: Promise<CacheCheckResponse>,
  requestData: () => BaseAction,
  receiveCacheHash: (cacheHash: string) => BaseAction,
  receiveCachedData: (cacheHash: string) => BaseAction,
  receiveLiveData: (cacheHash: string) => BaseAction
) {
  return async (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    dispatch(requestData());
    try {
      const { cacheHash, cachePromise, dataPromise } = await requestPromises;
      dispatch(receiveCacheHash(cacheHash));

      const { cacheHit } = await cachePromise;
      if (cacheHit) {
        dispatch(receiveCachedData(cacheHash));
      }

      await dataPromise;
      return dispatch(receiveLiveData(cacheHash));
    } catch (error) {
      return dispatch(shareDataError(error));
    }
  };
}

// TODO: convert this method to be more 'generic' like above, rename to fetchPivotData
export function fetchListData(
  modelDefn: string,
  requestData: () => BaseAction,
  receiveData: (data: Pivot) => BaseAction,
  options?: ListDataOptions
) {
  return async (dispatch: AppThunkDispatch): Promise<BaseAction | void> => {
    dispatch(requestData());
    try {
      const resp = await service.pivotService.listData(modelDefn, ASSORTMENT, options);
      return dispatch(receiveData(resp));
    } catch (error) {
      return dispatch(shareDataError(error));
    }
  };
}

/**
 * @deprecated
 *
 * Please DO NOT USE this function, it currently only is used by Style Edit/Worklist because converting to storing the cacheHash only breaks the lens update logic in StyleEdit.container.ts/Worklist.container.ts
 *
 * Fetches cached data if possible and returns immediately to caller
 * while waiting for the live data load to be ready to return to caller.
 *
 * @param modelDefn the pivot modelDefn to be called, this is used as a key cache parameter
 * @param isDataLoadingPath the period deliminted dot notation path to the `isDataLoading` key in global state
 * @param requestData global state dispatch action to be called before the async call is made
 * @param receiveCachedData global state dispatch action when the cached data is returned
 * @param receiveLiveData global state dispatch action when the 'live'' data is returned
 * @param fetchWorklist bool to determine if this pivot requires a worklist fetch alongside, to merge in worklist data from adjacent pivot
 * @param extraParams grab bag of extra stuff to be passed to the pivot call
 * @returns (dispatch: Dispatch<S>, getState: () => S) => Promise<BaseAction | void>
 *
 * TODO: change the args to object?
 */
export function cacheCheckFitViewData(
  modelDefn: string,
  isDataLoadingPath: string,
  requestData: () => BaseAction,
  receiveCachedData: (data: Pivot) => BaseAction,
  receiveLiveData: (data: Pivot) => BaseAction,
  fetchWorklist: boolean,
  receiveError: (error: any) => BaseAction,
  extraParams?: { [key: string]: string | undefined }
): (dispatch: AppThunkDispatch, getState: () => AppState) => Promise<BaseAction | void> {
  return async (dispatch: AppThunkDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    dispatch(requestData());

    const subheaderGroupBy = getState().subheader.groupBy;
    const groupingKey = getGroupBySelectedOptionProperty(subheaderGroupBy, 'groupingKey');
    const groupByDimension = getGroupBySelectedOptionProperty(subheaderGroupBy, 'dimension');
    const groupBy = DIMENSION_PRODUCT !== groupByDimension || subheaderGroupBy.refreshOnChange ? groupingKey : '';

    // FIXME: REMOVED FOR BETTER CACHING, NEED TO SOLVE FOR BODEN
    // const subheaderSortBy = getState().subheader.sortBy;
    // const sortingKey = getSortBySelectedOptionProperty(subheaderSortBy, 'dataIndex');
    // const sortbyDirection = subheaderSortBy.direction;
    // const sortByPayload = sortingKey ? `ORDER BY ${sortingKey} ${sortbyDirection}` : '';

    try {
      const { cachePromise, dataPromise } = await service.pivotService.fitViewCacheCheck(modelDefn, {
        groupBy,
        ...extraParams,
      });

      const { cacheHit, cacheData } = await cachePromise;

      if (cacheHit) {
        dispatch(receiveCachedData(cacheData));
        const liveData = await dataPromise;
        return dispatch(receiveLiveData(liveData));
      } else {
        const liveData = await dataPromise;
        return dispatch(receiveLiveData(liveData));
      }
    } catch (error) {
      return dispatch(receiveError(error));
    }
  };
}
/**
 * @deprecated
 *
 *  *
 * Please DO NOT USE this function, it currently only is used by Worklist because converting to storing the cacheHash only breaks the lens update logic in Worklist.container.ts
 *
 */

export type CachePayload = { data: Pivot; cacheHash: string };
export function cacheCheckListData(
  modelDefn: string,
  isDataLoadingPath: string,
  requestData: () => BaseAction,
  receiveCacheHash: (cacheHash: string) => BaseAction,
  receiveCachedData: (data: CachePayload) => BaseAction,
  receiveLiveData: (data: CachePayload) => BaseAction,
  extraParams?: ListDataOptions
) {
  return async (dispatch: AppDispatch, getState: () => AppState): Promise<BaseAction | void> => {
    dispatch(requestData());

    const subheaderGroupBy = getState().subheader.groupBy;
    const groupingKey = getGroupBySelectedOptionProperty(subheaderGroupBy, 'groupingKey');
    const groupByDimension = getGroupBySelectedOptionProperty(subheaderGroupBy, 'dimension');
    const groupBys: string[] = [];
    if (DIMENSION_PRODUCT !== groupByDimension || subheaderGroupBy.refreshOnChange) {
      groupBys.push(groupingKey);
    }
    groupBys.push('level:stylecolor');
    const extraParamsToSend: ListDataOptions = isNil(extraParams)
      ? { aggBy: groupBys.join(',') }
      : {
          aggBy: groupBys.join(','),
          ...extraParams,
        };

    try {
      const { cachePromise, dataPromise, cacheHash } = await service.pivotService.listDataCacheCheck(
        modelDefn,
        extraParamsToSend
      );
      dispatch(receiveCacheHash(cacheHash));
      const { cacheHit, cacheData } = await cachePromise;

      if (cacheHit) {
        dispatch(receiveCachedData({ data: cacheData, cacheHash }));
        const liveData = await dataPromise;
        return dispatch(receiveLiveData({ data: liveData, cacheHash }));
      } else {
        const liveData = await dataPromise;
        return dispatch(receiveLiveData({ data: liveData, cacheHash }));
      }
    } catch (error) {
      return dispatch(shareDataError(error));
    }
  };
}

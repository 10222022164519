import { style } from 'typestyle';
import { TEAL_PRIMARY, faintBorderColor, mediumBreakpoint } from 'src/utils/Style/Theme';
import { rangePx } from 'src/utils/Style/Media';

const itemHeight = 60;

export const modal = style({
  display: 'flex !important',
  justifyContent: 'flex-start',
  overflow: 'hidden !important',
  $nest: {
    '.s5-MuiDialog-container ': {
      margin: '0 auto',
      width: 'auto',
    },
    'button.apply': {
      backgroundColor: TEAL_PRIMARY,
      $nest: {
        '&:disabled': {
          cursor: 'not-allowed',
          background: '#9d9d9d',
        },
      },
    },
    'button.reset': {
      border: `2px solid ${TEAL_PRIMARY}`,
      color: TEAL_PRIMARY,
      background: '#f1f1f1',
    },
    button: {
      border: 'none',
      cursor: 'pointer',
      textTransform: 'uppercase',
      outline: 'none',
      padding: '0.4rem 1.2rem',
      color: 'white',
      fontSize: '1rem',
      $nest: {
        '> i': {
          marginRight: 5,
        },
      },
    },
    '.instructions': {
      fontSize: '0.9rem',
    },
    '.s5-MuiDialogTitle-root': {
      color: 'white',
      backgroundColor: TEAL_PRIMARY,
      width: '100%',
      padding: '1rem',
      $nest: {
        '.s5-MuiTypography-h6': {
          display: 'flex',
          $nest: {
            '.right-container': {
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              fontSize: '1.8rem',
              cursor: 'pointer',
            },
            '.icon': {
              marginRight: '0.5rem',
              fontSize: '1.5rem',
            },
          },
        },
      },
    },
    '.s5-MuiDialogContent-root': {
      display: 'grid',
      padding: '2rem 4rem',
      $nest: {
        '> main': {
          display: 'flex',
          [rangePx(0, mediumBreakpoint)]: {
            width: 600,
          },
        },
        '.option-group': {
          width: 300,
          height: 500,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          [rangePx(0, mediumBreakpoint)]: {
            width: '50%',
          },
          $nest: {
            '& + .option-group': {
              marginLeft: '1rem',
            },
            '> header': {
              fontSize: '1.2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '10%',
            },
            '> ul': {
              flex: 1,
              margin: 0,
              padding: 0,
              overflowY: 'auto',
              paddingRight: 5,
            },
            '&.selectable .option:not(.disabled)': {
              cursor: 'pointer',
            },
            '&:not(.selectable) .overlay': {
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              opacity: 0.7,
              zIndex: 1,
            },
          },
        },
        '.clear-choice': {
          position: 'absolute',
          right: '10px',
          fontSize: '1.1rem',
          top: '10px',
        },
        '.option': {
          height: itemHeight,
          marginBottom: '1rem',
          backgroundColor: faintBorderColor,
          fontWeight: 300,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          $nest: {
            '&.selected': {
              color: 'white',
              backgroundColor: TEAL_PRIMARY,
              fontWeight: 'normal',
            },
            '&.disabled': {
              color: '#ddd',
            },
          },
        },
        '> footer': {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: '0.4rem',
          justifySelf: 'end',
          paddingTop: '1rem',
        },
      },
    },
  },
});

import { classes, style } from 'typestyle';
import { smallFont, TEAL_PRIMARY } from 'src/utils/Style/Theme';

const HISTORY_STYLE_SECTION_MARGIN_TOP = 30;
const STYLE_PANE_IMG_WIDTH = 100;
const STYLE_PANE_IMG_HEIGHT = 150;

const historyStylePane = style({
  $debugName: 'historyStylePane',
  paddingLeft: 18,
  paddingRight: 18,
  paddingBottom: 40,
  paddingTop: 10,
  fontSize: smallFont,
  fontWeight: 400,
});
const historyStylePaneTitle = style({
  $debugName: 'historyStylePaneTitle',
  color: TEAL_PRIMARY,
  fontWeight: 'bold',
  fontSize: '20px',
  paddingTop: 15,
});
const historyStylePaneDescription = style({
  $debugName: 'historyStylePaneDescription',
  fontWeight: 'bold',
  fontSize: '18px',
});
const historyStylePaneSwatches = style({
  $debugName: 'historyStylePaneSwatches',
  display: 'flex',
  marginTop: 15,
  $nest: {
    div: {
      border: '1px solid #aca899',
      cursor: 'pointer',
      width: 20,
      height: 20,
      margin: '5px 3px',
    },
  },
});

export const containerChildBase = () =>
  style({
    $debugName: 'containerChildBase',
    display: 'flex',
    borderTop: '1px solid #eee',
    borderLeft: '1px solid #eee',
    borderRight: '1px solid #eee',
    padding: 5,
    flexWrap: 'wrap',
  });

const historyStylePaneGroup = style({
  $debugName: 'historyStylePaneGroup',
  marginTop: HISTORY_STYLE_SECTION_MARGIN_TOP,
});

const historyStylePaneGroupContent = style({
  $debugName: 'historyStylePaneGroupContent',
  borderBottom: '1px solid #eee',
});

const historyStylePaneGroupTitleContainer = style({
  $debugName: 'historyStylePaneGroupTitleContainer',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 5,
  cursor: 'pointer',
});

const historyStylePaneGroupTitle = style({
  $debugName: 'historyStylePaneGroupTitle',
  alignSelf: 'flex-start',
  fontSize: '1rem',
  fontWeight: 600,
});

const historyStylePaneGroupToggle = style({
  $debugName: 'historyStylePaneGroupToggle',
  alignSelf: 'flex-end',
});

const historyStylePaneTuple = style({
  $debugName: 'historyStylePaneTuple',
  display: 'flex',
});

const historyStylePaneLabel = classes(
  style({
    $debugName: 'historyStylePaneLabel',
    width: '40%',
    backgroundColor: '#f9f9f9',
  }),
  containerChildBase()
);

const historyStylePaneLabelText = style({
  $debugName: 'historyStylePaneLabelText',
  alignSelf: 'center',
});

const historyStylePaneSwatchContainer = style({
  overflowX: 'auto',
});

const historyStylePaneValue = classes(
  style({
    $debugName: 'historyStylePaneValue',
    width: '60%',
    overflowWrap: 'anywhere',
  }),
  containerChildBase()
);

export default {
  historyStylePaneSwatchContainer,
  historyStylePane,
  historyStylePaneTitle,
  historyStylePaneDescription,
  historyStylePaneSwatches,
  historyStylePaneGroup,
  historyStylePaneGroupContent,
  historyStylePaneGroupTitleContainer,
  historyStylePaneGroupTitle,
  historyStylePaneGroupToggle,
  historyStylePaneTuple,
  historyStylePaneLabel,
  historyStylePaneLabelText,
  historyStylePaneValue,
  STYLE_PANE_IMG_WIDTH,
  STYLE_PANE_IMG_HEIGHT,
};

import * as React from 'react';
import { GeoChart, Overlay } from 'src/common-ui';
import { ExtendedPointObject, SimplerLineChart } from './Charts/SimplerChart';
import { default as styles, MAP_BUBBLE_MAX_SIZE, MAP_BUBBLE_MIN_SIZE } from './GeoTrends.styles';
import { isNil, get, forEach, head } from 'lodash';
import { TenantConfigViewItem, GeoTrendsConfigData } from 'src/dao/tenantConfigClient';
import { Numbro } from 'numbro';
import * as Highcharts from 'highcharts';
import { Grid } from '@material-ui/core';
import Subheader from 'src/components/Subheader/Subheader.container';
import SubheaderDropdown from 'src/components/Subheader/SubheaderDropdown';
import { GeoTrendsProps } from 'src/pages/Hindsighting/MacroTrends/GeoTrends/GeoTrends.selectors';
import { GeoChartProps } from 'src/common-ui/components/Charts/GeoChart/GeoChart';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { MACRO_TRENDS_WARNING_MESSAGE } from 'src/pages/Hindsighting/MacroTrends/MacroTrends';
import Renderer from 'src/utils/Domain/Renderer';
import numbro from 'numbro';
import { SeriesClickEventObject } from 'highcharts';

export interface GeoDataItem {
  globalregion?: string;
  store?: string;
  channel?: string;
  slsr: number;
  children: GeoDataItem[];
  // latitude properties
  GLOBAL_LAT: string;
  CHANNEL_LAT: string;
  STORE_LAT: string;

  // longitude properties
  GLOABL_LNG: string;
  CHANNEL_LNG: string;
  STORE_LNG: string;
  trend: number; // or should be an enum?
}

type State = {
  dropdownOpen: boolean;
};

export class GeoTrends extends React.Component<GeoTrendsProps, State> {
  constructor(props: GeoTrendsProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };

    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleClickDropdown = this.handleClickDropdown.bind(this);
    this.onMapBubbleClick = this.onMapBubbleClick.bind(this);
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: GeoTrendsProps) {
    if (this.props.configLoaded && prevProps.configLoaded) {
      if (isNil(this.props.selectedGeoLevel)) {
        this.updateLevel(numbro(0));
      }
    }
  }

  updateLevel(index: Numbro) {
    const { mapConfig, selectedGeoLevel } = this.props;
    if (this.props.configLoaded && !isNil(mapConfig)) {
      if (isNil(selectedGeoLevel)) {
        this.props.onUpdateGeoLevel({ ...(mapConfig.view[0] as TenantConfigViewItem), selectedIndex: 0 });
      } else {
        this.props.onUpdateGeoLevel({
          ...(mapConfig.view[index.value()] as TenantConfigViewItem),
          selectedIndex: index.value(),
        });
      }
    }
  }

  handleClickDropdown(event: React.ChangeEvent<HTMLSelectElement>) {
    this.updateLevel(numbro(event.currentTarget.dataset.position));
  }

  handleToggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onMapBubbleClick(event: SeriesClickEventObject) {
    if (event) {
      const data: ExtendedPointObject = {
        ...event.point,
      } as ExtendedPointObject;
      this.props.onSelectPoint(data);
    }
  }

  parseData(
    geoData: BasicPivotItem[],
    mapConfig: GeoTrendsConfigData
  ): (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[] {
    if (!this.props.mapDataLoaded) {
      return [];
    }
    const { selectedGeoLevel } = this.props;

    if (isNil(selectedGeoLevel)) {
      return [];
    }

    const selectedGeoLevelGroupKey = selectedGeoLevel.groupingKey || '';

    // basic MapSeries options to be used by all MapSeries
    const mapType = mapConfig.map.type;
    const mapDataIndex = mapConfig.map.dataIndex;
    const mapTrendIndex = mapConfig.map.trendDataIndex;

    const mapDataRecord: Record<string, any[]> = {};
    // this is fake to trick highcharts
    // it should probably always be 'mapbubble'
    const isMapBubble = (type: string): type is 'mapbubble' => true;
    const [latitudeKey, longitudeKey] = selectedGeoLevel.geoLocationKeys ?? [];

    forEach(geoData, (dataItem) => {
      const name = dataItem.store || dataItem.channel || dataItem.globalregion;
      const lat = parseFloat(dataItem[latitudeKey]);
      const lon = parseFloat(dataItem[longitudeKey]);

      if (isNaN(lat) || isNaN(lon)) {
        console.error(`Could not parse latitude or longitude from GeoData for ${JSON.stringify(dataItem, null, 2)}`); // eslint-disable-line no-console
        return;
      }

      const prefix = `member:${selectedGeoLevelGroupKey.split(':')[1]}`;
      const trend = dataItem[mapTrendIndex];
      if (isNil(mapDataRecord[trend])) mapDataRecord[trend] = [];
      const dataArray = mapDataRecord[trend];
      const value = get(dataItem, mapDataIndex);
      const newItem = {
        name: name,
        lat,
        lon,
        value,
        z: value,
        mId: dataItem[`${prefix}:id`],
        mDesc: dataItem[`${prefix}:description`],
        departmentData: dataItem.children,
      };
      dataArray.push(newItem);
    });

    const mapSeries: (Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMapOptions)[] = mapConfig.trends.map(
      (trend) => {
        const seriesData = mapDataRecord[trend.trendValueMap] ?? [];
        const modifiedData = seriesData.map((dataPoint) => ({ ...dataPoint, z: dataPoint.value }));
        return {
          type: isMapBubble(mapType) ? mapType : 'mapbubble',
          sizeByAbsoluteValue: true,
          sizeBy: 'width',
          minSize: mapConfig.map.minSize ?? MAP_BUBBLE_MIN_SIZE,
          maxSize: mapConfig.map.maxSize ?? MAP_BUBBLE_MAX_SIZE,
          name: trend.text,
          color: trend.color,
          data: modifiedData,
        };
      }
    );

    return mapSeries;
  }

  getGeoConfig(geoData: BasicPivotItem[], mapConfig: GeoTrendsConfigData): Highcharts.Options | undefined {
    const { mapDataLoaded } = this.props;
    const highestValue = Math.max(...geoData.map((dataItem) => get(dataItem, mapConfig.map.dataIndex)));
    return {
      plotOptions: {
        mapbubble: {
          zMax: highestValue,
        },
      },
      tooltip: {
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          if (mapDataLoaded && !isNil(mapConfig) && !isNil(mapConfig.bubble)) {
            // mDesc and z are custom properties inserted onto the point type
            const point = (this.point as unknown) as { mDesc: string; z: string };
            const popoverTitle = mapConfig.bubble.text ? mapConfig.bubble.text : mapConfig.bubble.dataIndex;
            const dataRenderer = mapConfig.bubble ? mapConfig.bubble.renderer : 'usMoneyNoCents';
            const label = point.mDesc + `<br/> ${popoverTitle} ` + Renderer[dataRenderer](point.z);
            return label;
          } else {
            return '';
          }
        },
      },
    };
  }

  render() {
    let dropDown = <div />;
    let charts;

    const geoChartConfig: GeoChartProps = {
      title: 'Global Region Volume and Trend',
      mapUri: this.props.mapUri,
      mapSeries: [],
      geoConfig: {},
    };
    const {
      data,
      mapConfig,
      chartConfig,
      mapDataLoaded,
      configLoaded,
      chartDataLoaded,
      chartData,
      selectedItem,
      selectedGeoLevel,
    } = this.props;
    const overlayGeoChart = !configLoaded && !mapDataLoaded;
    if (configLoaded && !isNil(mapConfig)) {
      dropDown = (
        <SubheaderDropdown
          defaultSelection={selectedGeoLevel?.selectedIndex || 0}
          selection={selectedGeoLevel?.selectedIndex}
          options={mapConfig.view as TenantConfigViewItem[]}
          label={'GeoLevel: '}
          handleChangeOnDropdown={this.handleClickDropdown}
        />
      );
      geoChartConfig.onMapBubbleClick = this.onMapBubbleClick;
      geoChartConfig.mapSeries = this.parseData(data, mapConfig);
      geoChartConfig.geoConfig = this.getGeoConfig(data, mapConfig);

      if (!isNil(chartConfig) && !isNil(selectedItem)) {
        charts = chartConfig.view.map((chart, index) => {
          return (
            <Grid item={true} lg={12} className={styles.fullWidthHalfHeightGridItem} key={index}>
              <div className={styles.chartContainer}>
                <Overlay visible={!chartDataLoaded} type={'loading'} fitParent={true} />
                <SimplerLineChart
                  title={`${selectedItem.mDesc}  ${head(chart.view)?.text}`}
                  data-qa="geotrends-bottom-chart"
                  data={chartData}
                  config={(chart as unknown) as TenantConfigViewItem}
                />
              </div>
            </Grid>
          );
        });
      }
    }

    const mapChart = <GeoChart {...geoChartConfig} />;

    return (
      <Grid container={true} className={styles.geoTrendsContainer} spacing={0} data-qa-component="GeoTrends">
        <Grid item={true} lg={12} md={12}>
          <Subheader
            title={this.props.title}
            showFlowStatus={this.props.showFlowStatus}
            showSearch={false}
            showLookBackPeriod={this.props.showLookBackPeriod}
            errorCondition={MACRO_TRENDS_WARNING_MESSAGE}
          />
        </Grid>
        <Grid item={true} lg={8} md={12} xs={12} className={styles.fullHeightGridItem}>
          <Overlay visible={overlayGeoChart} type={'loading'} fitParent={true} />
          <div className={styles.dropDownMapChart}>{dropDown}</div>
          {mapChart}
        </Grid>
        <Grid item={true} lg={4} className={styles.fullHeightGridItem}>
          <Grid container={true} style={{ height: '100%' }}>
            {charts}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

import { z } from 'zod';
import { zClientDataApi, zConfigApiV2, zDataApi } from '../confdefnView';
import { BasicEditors, StyleEditSectionComponents, WorklistTabComponents } from './literals';
import { ConfDefnComponentType } from '../confdefnComponents';

// TODO: this is the very basic type that I'm using that will encompass any viewdefnitem keys
// until we move to a more generic ViewItem type across all views. Abstracting keys
// from the type that are too renderer/editor/etc specific
export const BasicViewItem = z
  .object({
    text: z.string().optional(),
    dataIndex: z.string(),
  })
  // FIXME: this is temporary to allow unknown keys to not be filtered out
  .passthrough();

export interface BasicViewItem extends z.infer<typeof BasicViewItem> {}

export const NestedColumnsBasicViewItem = z.object({
  text: z.string().optional(),
  columns: z.array(BasicViewItem),
  visible: z.boolean().optional(),
  hidden: z.boolean().optional(),
});

export const NestedViewBasicViewItem = z.object({
  text: z.string().optional(),
  view: z.array(BasicViewItem),
});

export const NestedOptionsBasicViewItem = z.object({
  text: z.string().optional(),
  options: z.array(BasicViewItem),
});

export const DynamicTitle = z.object({
  dataIndex: z.string(),
  renderer: z.string(),
  mask: z.string(),
});

const zDefaultsObject = z.object({
  dataIndex: z.string(),
});

const zDefaultString = z.string().optional();

export const SortByConfig = z.object({
  type: z.literal('sort'),
  defaults: zDefaultsObject,
  view: z.array(BasicViewItem),
});

const zGroupViewItem = BasicViewItem.merge(
  z.object({
    groupingKey: z.string().optional(),
    dimension: z.string(),
  })
);

export interface GroupViewItem extends z.infer<typeof zGroupViewItem> {}

export const GroupByConfig = z.object({
  type: z.literal('groupBy'),
  default: zDefaultString,
  hideEmptyRow: z.boolean().optional(),
  view: z.array(zGroupViewItem),
});

export const zLevelByConfig = z.object({
  defaults: zDefaultsObject,
  view: z.array(zGroupViewItem),
});

const zWorklistSortByConfig = z.object({
  text: z.string().optional(),
  defaults: zDefaultsObject,
  options: z.array(BasicViewItem),
});

/**
 * Defines the shape of a companion card object, used for rendering companion-related information.
 *
 * @constant
 * @type {ZodObject}
 * @property {string} body - The main body content of the companion card, mapped from 'name'.
 * @property {string} image - The image URI for the companion card.
 * @property {string|null} [stars] - An optional, nullable string representing the star rating.
 * @property {string} title - The title of the companion card, mapped to 'id'.
 * @property {string} [displayTitle] - An optional display title for the companion card, mapped to 'title'.
 * @property {string} [style] - An optional style identifier for the companion card.
 */
export const zCompanionCard = z.object({
  body: z.string(),
  image: z.string(),
  stars: z
    .string()
    .nullable()
    .optional(),
  title: z.string(),
  displayTitle: z.string().optional(),
  style: z.string().optional(),
});

export const ItemMappings = z.object({
  main: zCompanionCard,
});

const zItemMappings = z.object({
  main: zCompanionCard,
});

export interface ItemMappings extends z.infer<typeof zItemMappings> {}

export const zCompanionView = z.object({
  itemMappings: zItemMappings,
  sortBy: zWorklistSortByConfig,
  itemMappingsStyleColor: zItemMappings.optional(),
  levelBy: zLevelByConfig.optional(),
});

export interface CompanionView extends z.infer<typeof zCompanionView> {}

const DropdownOption = z.object({
  text: z.string(),
  dataIndex: z.string(),
  groupingKey: z.string(),
  xtype: z.string(),
});

export const SubheaderDropdown = z.object({
  label: z.string(),
  options: z.array(DropdownOption),
  defaultSelection: z.optional(z.number()),
});

export interface SubheaderDropdown extends z.infer<typeof SubheaderDropdown> {}

export const zMassEditCoordinateMap = z.record(z.string());
export interface MassEditCoordinateMap extends z.infer<typeof zMassEditCoordinateMap> {}

export const zConfigurableGridButtonBase = z.object({
  text: z.string(),
  icon: z.optional(z.string()),
  tooltip: z.optional(z.string()),
  selectableLevels: z.array(z.string()).optional(),
});
export const zConfigurableGridMultiSelectPostButton = zConfigurableGridButtonBase.merge(
  z.object({
    type: z.enum(['multiSelectPost']),
    params: z.object({
      dataApi: zDataApi,
      coordinateMap: zMassEditCoordinateMap,
      keysToCopy: z.array(z.string()),
      keyToUpdate: z.string(),
    }),
  })
);
export interface ConfigurableGridMultiSelectPostButton extends z.infer<typeof zConfigurableGridMultiSelectPostButton> {}

export const zConfigurableGridMfpScopeButton = zConfigurableGridButtonBase.merge(
  z.object({
    type: z.enum(['multiSelectCreateMfpScope']),
    params: z.object({
      /** `coordinateMap` is essentially synonymous with mfp's `anchor`, so I am re-using it here as such as it matches the asst api internals better */
      coordinateMap: zMassEditCoordinateMap,
      path: z.string(),
      /** initParams included here to make the object keys match the scope send, but it is not currently intended to be used,
       * and is left here only as a potential future extension point should it be needed
       */
      initParams: z.unknown(),
      workflow: z
        .string()
        .optional()
        .default('pre-season'),
    }),
  })
);
export interface ConfigurableGridMfpScopeButton extends z.infer<typeof zConfigurableGridMfpScopeButton> {}

export const zConfigurableGridButtons = z.discriminatedUnion('type', [
  zConfigurableGridMfpScopeButton,
  zConfigurableGridMultiSelectPostButton,
]);
export type ConfigurableGridButton = ConfigurableGridMfpScopeButton | ConfigurableGridMultiSelectPostButton;
export const ConfigurableGridActions = z.object({
  patch: z.optional(
    z.object({
      planningParams: z.array(z.string()),
    })
  ),
  buttons: z.array(zConfigurableGridButtons).optional(),
});

export const zConfigurableGridGraph = z.object({
  title: z.string(),
  timeDataIndex: z.string().default('week'),
  configApi: zClientDataApi,
  ignoreClicks: z.boolean().default(false),
  height: z
    .number()
    .optional()
    .default(250),
  expanded: z
    .boolean()
    .optional()
    .default(false),
});

export interface ConfigurableGridGraph extends z.infer<typeof zConfigurableGridGraph> {}

// TODO: This step, the ref mapping to the columndef, needs to be handled before validation
// currently being validated too late
export const ConfigRefs = z.any();

export const SalesAdjustment = z
  .object({
    keys: z.array(BasicViewItem),
  })
  .optional();

const MassEditModificationOptions = z.object({
  title: z.string(),
  modifierTypes: z.array(BasicViewItem),
});

const MassEditProductListing = z.object({
  title: z.string(),
  editor: BasicEditors,
  dataIndex: z.string(),
});

export const MassEditConfig = z.object({
  coordinateMap: zMassEditCoordinateMap,
  modificationOptions: MassEditModificationOptions,
  productListing: MassEditProductListing,
});

export const RollupDefn = z.object({
  type: z.literal('rollUp').optional(),
  view: z.array(BasicViewItem),
});

export const KeyFinancialsViewDefn = z.object({
  id: z.string(),
  view: z.array(BasicViewItem),
});

export const ChoiceProductivityViewDefn = z.object({
  type: z.literal('metrics'),
  id: z.string(),
  view: z.array(BasicViewItem),
});

export const MacroViewDefn = z.object({
  type: z.literal('macro'),
  id: z.string(),
  model: z.string(),
  view: z.array(BasicViewItem),
});

export const CompanionViewDefn = z.object({
  type: z.literal('companionView'),
  main: zCompanionCard,
  view: z.array(BasicViewItem).max(0),
});

export const WorklistTab = z.object({
  title: z.string(),
  componentType: WorklistTabComponents.transform((type) => {
    // (parameter) type: "visualize" | "styleEdit" | "pricing" | "flowsheet"
    switch (type) {
      case 'visualize':
        return ConfDefnComponentType.visualize;
      case 'styleEdit':
        return ConfDefnComponentType.styleEdit;
      case 'pricing':
        return ConfDefnComponentType.pricing;
      case 'flowsheet':
        return ConfDefnComponentType.flowSheet;
    }
  }),
  pathSlot: z.string(),
  viewDefns: z.array(z.string()),
});
export const MainViewDefnConfig = z.object({
  view: z.array(BasicViewItem),
  assortment: z.boolean().optional(),
  cardType: z.string().optional(),
});
export const CanvasViewRollUpConfig = z.object({
  type: z.literal('rollUp'),
  view: z.array(BasicViewItem),
});
export const GridMainViewDefn = z.object({
  displayTitle: z.string().optional(),
  rowHeight: z.number(),
  groupRowHeight: z.number().optional(),
  columnWidth: z.number().optional(),
  showGroupTitlesExcel: z.boolean().optional(),
});

export const MainView = z.object({
  view: z.array(BasicViewItem),
  assortment: z.boolean().optional(),
});

const ConfigureDefaults = z.object({
  first: z.string(),
  second: z.string().optional(),
  third: z.string().optional(),
  fourth: z.string().optional(),
});

export const ConfigureViewDefn = z.object({
  type: z.literal('configure').optional(),
  defaults: ConfigureDefaults,
  main: z
    .object({
      info: z.string(),
      minimumSelections: z.number(),
    })
    .optional(),
  view: z.array(BasicViewItem),
});
export interface ConfigureViewDefn extends z.infer<typeof ConfigureViewDefn> {}
const ParetoViewItem = z.object({
  text: z.string(),
  view: z.array(BasicViewItem),
});

export const ParetoConfigureViewDefn = z.object({
  id: z.string(),
  defaults: ConfigureDefaults,
  view: z.array(ParetoViewItem),
});

export const ParetoSection = z.object({
  xtype: z.union([z.literal('top-left'), z.literal('top-right'), z.literal('column-items'), z.literal('graph')]),
  view: z.array(z.union([BasicViewItem, z.object({ xtype: z.string(), text: z.string() })])),
});

export const ParetoItemStrip = z.object({
  title: DynamicTitle,
  view: z.array(BasicViewItem),
});

export const SearchIndexes = z.array(z.string());

const StyleEditHeaderItem = z.object({
  dataIndex: z.string(),
  inputParams: z
    .object({
      whitelist: z.string(),
      maxLength: z.number(),
    })
    .optional(),
});

export const StyleEditHeader = z.object({
  primary: StyleEditHeaderItem,
  secondary: StyleEditHeaderItem,
  image: StyleEditHeaderItem,
});

/**
 * @deprecated
 *
 * This is a one off zod validator, instead use one of the globally defined DataApi types in confdefnView.ts
 *
 * */
export const StyleEditDataApi = z.union([
  z.object({
    isListData: z.literal(true),
    defnId: z.string(),
    params: z.object({
      topMembers: z.string(),
      aggBy: z.string(),
      nestData: z.string().optional(),
    }),
  }),
  z.object({
    url: z.string(),
    params: z.object({
      styleId: z.string(),
    }),
  }),
]);

// FIXME: this should be using one of the globally defined DataApi types in confdefnView.ts
export const StyleEditSection = z.object({
  text: z.string(),
  componentType: StyleEditSectionComponents,
  skipForwardPropagation: z.boolean().optional(),
  dataApi: StyleEditDataApi,
  configApi: zConfigApiV2,
  dependentsApi: z
    .object({
      url: z.string(),
    })
    .optional(),
});

export const TimeLevels = z.object({
  colDef: z.array(z.string()),
  colDefSortIndex: z.array(z.string()).optional(),
  data: z.array(z.string()),
});

export const TransposedColumnLevels = z.object({
  dataIndex: z.string(),
  text: z.string(),
  type: z.string(),
  colDef: z.string(),
});

export const zTransposedColumns = z
  .object({
    topLevelTitle: z.string(),
    bottomLevelTitle: z.string(),
    topLevel: z.array(TransposedColumnLevels),
    bottomLevel: z.array(TransposedColumnLevels),
  })
  .optional();

export type TransposedColumns = z.infer<typeof zTransposedColumns>;

export const zConfigurablePostAction = z.object({
  name: z.string().default('Unlock'),
  icon: z.string().default('fa fa-unlock-alt'),
  tooltip: z.string().default('Unlock items'),
  updateType: z.union([z.literal('coarse'), z.literal('granular')]),
  valueDataIndex: z.string().default(''),
  value: z.unknown().default(''),
});
export interface ConfigurablePostAction extends z.infer<typeof zConfigurablePostAction> {}

export const zTopAttribute = BasicViewItem.merge(
  z.object({
    renderer: z.string(),
    mask: z.string().optional(),
    xtype: z.string().optional(),
    classes: z.array(z.string()).optional(),
  })
);
export interface TopAttribute extends z.infer<typeof zTopAttribute> {}

export function formatViewDefnError(error: unknown) {
  return `Invalid viewdefn detected.\n\nZodError:\n\n${(error as any).message}`;
}

export const zNoRowsOverlay = z
  .object({
    type: z.union([z.literal('EmptyWorklistLink'), z.literal('unset')]),
    path: z.string().optional(),
  })
  .default({
    type: 'unset',
  });

export interface NoRowsOverlayConfig extends z.infer<typeof zNoRowsOverlay> {}

import type {
  GridReadyEvent,
  RangeSelectionChangedEvent,
  NavigateToNextCellParams,
  CellPosition,
  VirtualColumnsChangedEvent,
  Column,
  ColumnEvent,
  ProcessHeaderForExportParams,
  BodyScrollEvent,
  IRowNode,
  ProcessDataFromClipboardParams
} from '@ag-grid-community/core';
import ViewportDatasource from './ViewportDataSource';
import { TIME } from '../../../utils/Domain/Constants';
import _, { get, head, isEmpty, isNil, isObject, last } from 'lodash';
import { MOVE_DIRECTION_LOOKUP } from '../../../utils/Component/AgGrid/keyBoardBindings';
import { AgPivot } from './AgPivot';
import { HierarchyMember } from '../../../pivot/Member';
import { Field } from '../../../pivot/Field';
import { GRID_SAVING, GRID_ERROR, GRID_REFRESHING } from 'src/state/scope/Scope.types';
import { getCurrentVirtualRowIndicies, getRowBoundsFromGridApi } from 'src/utils/Component/Pivot';
import { CELL_TAG_DISABLED, PivotCell } from 'src/pivot/PivotCell';
import { FAUX_CELL, GROUP_DEPTH, S5_ROW, S5_ROW_COUNTER } from 'src/utils/interface/PivotCell.tags';

export function handleGridReady(this: AgPivot, params: GridReadyEvent) {
  // assign private vars to callback
  this.gridApi = params.api;

  // setup the viewportDatasource
  if (!this.viewportDataSource && this.state.manager) {
    this.viewportDataSource = new ViewportDatasource({
      manager: this.state.manager,
      gridApi: this.gridApi,
      initialColumnIndicies: getCurrentVirtualRowIndicies(this.gridApi),
      imperativeAsyncRefreshOnly: false
    });
    // this.gridApi.setViewportDatasource(this.viewportDataSource);
    this.gridApi.setGridOption('viewportDatasource', this.viewportDataSource);
    this.viewportDataSource.onRowCountChanged();
  }

  // attach and fire one rowData call after first mount to refresh the css
  this.gridApi.addEventListener(
    'cellsReturned',
    this.redrawRowsAfterModelUpdate
  );

  // attach listener to refresh floating row after cells returned
  this.gridApi.addEventListener(
    'cellsReturned',
    this.resetFloatingTextAfterCellsReturned
  );

  // select the first cell when the grid first loads
  const firstIntersection = this.state.manager.config
    .getColIntersections()[0];
  const firstCol = firstIntersection.getFieldListAsPipeString();

  if (params.api.getAllDisplayedColumns().length > 0) {
    // focus the first cell on load, and if the columns are loaded
    this.gridApi.setFocusedCell(0, firstCol);
  }

  // bind collapsing time columns on grid ready
  const isFirstColTime = get(firstIntersection, 'fields[0].member.parent.id') === TIME;
  if (isFirstColTime) {
    const timeFields = this.state.manager.config.getColGroups()[0].getVisible();
    const cols = params.api.getAllDisplayedVirtualColumns();
    bindColumnToClick(cols, timeFields, this.eventCache);
  }

  this.setState({
    ready: true,
    loading: true
  });
}

export function handleBindClickToColumns(this: AgPivot) {
  const timeFields = this.state.manager.config.getColGroups()[0].getVisible();
  bindColumnToClick(this.gridApi!.getColumns()!, timeFields, this.eventCache);
}

const bindColumnToClick = (cols: Column[], timeFields: Field[], eventCache: {
  [key: string]: (evt: ColumnEvent) => void
}) => {
  // this if for collapsing of time columns
  cols.forEach((col) => {
    const evt = function (evt: ColumnEvent) {
      // @ts-ignore
      // total hack around the fact that sortChanged fires multiple times per column click
      // note that this accesses a private interface and it could break
      if (evt.api.eventService.asyncFunctionsQueue.length === 0) {
        onSortChanged(evt, timeFields);
      }
    };
    col.addEventListener('sortChanged', evt);
    eventCache[col.getColId()] = evt;
  });
};

function onSortChanged(event: ColumnEvent, timeFields: Field[]) {
  function returnIdCheckChildren(mem: HierarchyMember): string[] {
    let ids: string[] = [mem.id];
    if (mem.children) {
      const childIds = _.flattenDeep(mem.children.map(returnIdCheckChildren)) as string[];
      ids = ids.concat(childIds);
    }
    return ids;
  }

  const column = event.column;
  if (!column) { return; }

  const colId = column.getColId();
  let timeIdsToToggle: string[] = [];
  const clickedField = timeFields!.find(fld => fld.member.id === colId);
  if (clickedField && clickedField.member.children) {
    timeIdsToToggle = _.flattenDeep(clickedField.member.children.map(returnIdCheckChildren)) as string[];
    const isVisible = clickedField.member.areChildrenColumnsVisible;
    clickedField.member.areChildrenColumnsVisible = !isVisible;
    event.api.setColumnsVisible(timeIdsToToggle, !isVisible);
  }
}

export function handlePaste(
  this: AgPivot,
  params: ProcessDataFromClipboardParams
): string[][] | null {
  // custom paste so we can override ag-grid default action
  const manager = this.state.manager;
  const pasteContent = params.data;
  const selectionRange = this.selectionRange;
  const cellsToSend: any[] = [];
  const updatePromises: Promise<any>[] = [];

  // refresh stale indicator
  this.props.onUpdateGridAsyncState(GRID_SAVING);
  if (this.props.onDataChange) {
    this.props.onDataChange(this);
  }

  for (let r = selectionRange.startY; r <= selectionRange.endY; r++) {
    const columns = pasteContent[(r - selectionRange.startY) % pasteContent.length];
    for (let c = selectionRange.startX; c <= selectionRange.endX; c++) {
      const cell = manager.getCell(r, c);
      if (cell && !cell.hasTag(CELL_TAG_DISABLED) && !cell.hasLock()) {
        let value;
        const pasteCell = cell.clone();
        value = columns[(c - selectionRange.startX) % columns.length];
        pasteCell.value = value;
        cellsToSend.push(pasteCell);
      }
    }
  }

  updatePromises.push(manager.updateCells(cellsToSend));

  Promise.all(updatePromises).then(() => {
    this.props.onUpdateGridAsyncState(GRID_REFRESHING);
    // refresh stale indicator
    if (this.props.onDataChange) {
      this.props.onDataChange(this);
    }
    this.refreshCells();
    this.gridApi.addEventListener(
      'cellsReturned',
      this.handleGridHasRefreshed
    );
    if (this.props.onDataModification) {
      this.props.onDataModification();
    }

    // reset the focus cell so keyboard nav doesn't break
    const lastFocusedCell = this.gridApi.getFocusedCell();
    if (!lastFocusedCell) { return; }
    this.gridApi.setFocusedCell(
      lastFocusedCell.rowIndex,
      lastFocusedCell.column
    );
  }).catch((_err) => {
    this.props.onUpdateGridAsyncState(GRID_ERROR);
  });

  return null;
}

export const handleProcessHeaderForClipboard = (params: ProcessHeaderForExportParams) => {
  return params.column.getColId();
};

export function handleRangeSelectionChange(this: AgPivot, event: RangeSelectionChangedEvent) {

  // only supporting one selection right now, ignoring the rest
  const colOffset = this.state.manager.config.getRowGroupsCount();
  const currentRangeSelections = this.gridApi.getCellRanges();
  if (!isNil(currentRangeSelections) && !_.isEmpty(currentRangeSelections)) {
    const newRangeSelection = currentRangeSelections[0];
    const { startRow, endRow, columns } = newRangeSelection;

    if (!startRow || !endRow || isEmpty(columns)) {
      return;
    }

    const endColumn = last(columns);
    // deriving startColumn here instead of using the one from the event,
    // as the event sets `startColumn` to be the first column the user selected, not the first column
    // in column order.  We need them in column order, so use the first in the `columns` object
    const startColumn = head(columns);

    if (isNil(startColumn) || isNil(endColumn)) {
      return;
    }

    const startColIndex = this.gridApi.getAllGridColumns().findIndex(c => c == startColumn);
    const endColIndex = this.gridApi.getAllGridColumns().findIndex(c => c == endColumn);

    // need to apply an offset to make the ag-grid api fit the remote api
    const startColNum = startColIndex - colOffset;
    const endColNum = endColIndex - colOffset;

    this.selectionRange = {
      startX: startColNum,
      endX: endColNum,
      startY: startRow.rowIndex,
      endY: endRow.rowIndex
    };
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(this.getSelectedCells());
    }
  }
}

export function handleNavigateToNextCell(
  this: AgPivot,
  params: NavigateToNextCellParams
): CellPosition {
  // merging the event streams together here
  // maybe this is too DRY, split them and repeat if it causes issues
  const event: KeyboardEvent | null = params.event;
  const previousCellPosition: CellPosition = params.previousCellPosition;

  if (!event) {
    // don't move if the event is missing for some reason
    return this.getCellFromRowCol(
      0,
      0,
      previousCellPosition
    );
  }

  event.preventDefault();
  event.stopPropagation();

  let [moveRow, moveColumn] = MOVE_DIRECTION_LOOKUP[event.which];
  if (event.shiftKey) {
    // flip it
    moveRow = moveRow * -1;
    moveColumn = moveColumn * -1;
  }

  const nextCellDef: CellPosition = this.getCellFromRowCol(
    moveRow,
    moveColumn,
    previousCellPosition
  );

  return nextCellDef;
}

export function handleKeyboardNavigateToNextCell(
  this: AgPivot,
  params: React.KeyboardEvent
): void {
  // merging the event streams together here
  // maybe this is too DRY, split them and repeat if it causes issues
  const event: React.KeyboardEvent = params;
  const previousCellPosition = this.gridApi.getFocusedCell();

  if (!previousCellPosition) {
    // don't move if the cell is missing for some reason
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  // This allows users to continue using WASD keys for navigation, until they start entering numbers to edit
  this.gridApi.stopEditing();

  let [moveRow, moveColumn] = MOVE_DIRECTION_LOOKUP[event.which];
  if (event.shiftKey) {
    // flip it
    moveRow = moveRow * -1;
    moveColumn = moveColumn * -1;
  }

  const nextCellDef = this.getCellFromRowCol(
    moveRow,
    moveColumn,
    previousCellPosition
  );

  this.gridApi.clearRangeSelection();
  this.gridApi.clearFocusedCell();

  this.gridApi.setFocusedCell(
    nextCellDef.rowIndex,
    nextCellDef.column,
    undefined
  );


  this.gridApi.addCellRange({
    rowStartIndex: nextCellDef.rowIndex,
    rowEndIndex: nextCellDef.rowIndex,
    columnStart: nextCellDef.column,
    columnEnd: nextCellDef.column
  });
}

export function handleVirtualColumnsChanged(this: AgPivot, params: VirtualColumnsChangedEvent) {
  if (!params.api) { return; }
  // TODO: this causes a memory leak because we don't clean up the columns event correctly
  // because the events for catching a column removed are awful
  const timeFields = this.state.manager.config.getColGroups()[0].getVisible();
  bindColumnToClick(params.api.getAllDisplayedVirtualColumns(), timeFields, this.eventCache);

  // column pagination stuff
  const currentRowIndicies = getRowBoundsFromGridApi(params.api);
  this.viewportDataSource!.updateColumnIndicies(getCurrentVirtualRowIndicies(params.api), currentRowIndicies);

  // tries to float: right the columGroup headerName
  // this functionality has been requested with ag-grid directly,
  // this is a hack for now
  // gridPanel is a private interface, it could break
  // @ts-ignore
  const scrollLeft = params.api.gridBodyCtrl.eGridBody.scrollLeft;
  const columnIndexToCheck = 2; // arrived at this based on trial and error
  const { config } = this.state.manager;

  // clear the other first rows
  document
    .querySelectorAll('.ag-header-group-cell.ag-header-group-cell-with-group')
    .forEach(elem => elem.classList.remove('first'));

  // don't do it if you're already scroll to the left, and if there aren't multiple colGroups
  if (scrollLeft > 0 && config.getColGroups().length > 1) {
    // manually add a class to the first row when scrolling left
    const id = params.api
      .getAllDisplayedVirtualColumns()[columnIndexToCheck].getParent()
      .getColGroupDef()!.groupId;
    const querySelector = `[col-id="${id}_0"]`;
    const colElem = document.querySelector(querySelector);
    if (colElem) {
      colElem.classList.add('first');
    }
  }
}

const removeStyleTags = (p: HTMLParagraphElement) => {
  // that could conflict with future useage of style tags on these elements
  p.style.removeProperty('top');
};

export function handleBodyScroll(this: AgPivot, params: BodyScrollEvent) {
  if (!this.gridApi) { return; }

  // this is a private ag-grid interface.  We use it here because it's the easiest way
  // to get the first "in view" row, irrespective of virtualization and rowBuffer
  // this interface may change when we upgrade ag-grid.  getFirstRenderedRow gives the
  // first virtualized row, which usually isn't in view
  // @ts-ignore
  const rowIndexFromPixel = this.gridApi.rowModel.getRowIndexAtPixel(params.top);
  const firstRowInView = this.gridApi.getDisplayedRowAtIndex(rowIndexFromPixel);
  if (!firstRowInView) { return; }
  const rowData = firstRowInView.data as PivotCell[];

  if (!firstRowInView || !rowData || !Array.isArray(rowData)) {
    // This shouldn't happen, but it can despite what the types say
    return;
  }

  // we need to loop the current first visible row's data in order to find the group cell above it and
  // dimension member information
  rowData.forEach((cell, cellIdx) => {
    let firstRowOfGroup: undefined | IRowNode;

    // faux cells are created by the front end, and contain the dimension information,
    // we only need to move those, so skip all other cells others
    if (cellIdx >= this.rowGroupOffsets!.length - 1 || !cell.tags.includes(FAUX_CELL)) { return; }

    const rowClass = cell.tags.find(t => t.startsWith(S5_ROW))!;
    const groupIndex = cell.tags.find(t => t.startsWith(GROUP_DEPTH))!;
    const dupeCount = cell.tags.find(t => t.startsWith(S5_ROW_COUNTER))!;

    // clear all previous top attributes
    document.querySelectorAll<HTMLParagraphElement>(`.${groupIndex}`).forEach(removeStyleTags);

    // find the domElement associated with the cell, based on css classes applied at cell render time
    const rowElem = document.querySelector<HTMLParagraphElement>(
      `.row-header-cell.${dupeCount}.${rowClass}.${groupIndex}`
    );
    if (rowElem) {
      // starting from the current cell's index, count upward in grid row order to find the cell
      // that has the member displayText so we can adjust it's top
      for (let index = firstRowInView.rowIndex!; index >= 0; index--) {
        if (index === 0) {
          firstRowOfGroup = this.gridApi.getDisplayedRowAtIndex(index)!;
          break;
        }
        const prevRow = this.gridApi.getDisplayedRowAtIndex(index)!;
        if (isNil(prevRow.data) || !isObject(prevRow.data[cellIdx])) { continue; }
        const prevRowCell = prevRow.data[cellIdx] as PivotCell; // aggrid doesn't type rowData in this build
        const prevRowRowClass = prevRowCell.tags.find(t => t.startsWith(S5_ROW))!;
        if (prevRowRowClass !== rowClass) {
          firstRowOfGroup = this.gridApi.getDisplayedRowAtIndex(index++)!;
          break;
        }
      }

      // make sure we have it before proceeding
      if (!firstRowOfGroup) { return; }
      // if it's the first row in the grid, we need don't need to offset by one row's height
      const zeroOffset = firstRowOfGroup.rowIndex! === 0 ? 0 : firstRowOfGroup.rowHeight!;

      // 2.5px is based on trial and error, to make the grid match the previous cell position
      const calculatedRowTopOffset = params.top - firstRowOfGroup.rowTop! - zeroOffset;
      rowElem.style.setProperty('top', `${calculatedRowTopOffset < 2.5 ? 2.5 : calculatedRowTopOffset}px`);
      rowElem.animate;
    }
  });
}

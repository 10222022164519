import { OptionGroup, Option } from 'src/components/Configure/ConfigureModal';
import { ColDef } from '@ag-grid-community/core';
import { uniqBy, memoize, isEmpty } from 'lodash';

import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { FrameworkComponents, parseGridConfig, MasterDetailConfig } from 'src/utils/Component/AgGrid/AgConfigParse';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { TenantConfigViewData, TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import {
  GroupBySlice,
  SubheaderSlice,
  SortBySlice,
  SubheaderViewDefns,
} from 'src/components/Subheader/Subheader.slice';
import type { HasFab, HasFlowStatus, HasTitle } from 'src/services/configuration/codecs/confdefnView'
import {
  GridItem,
  groupedToAgFlatTree,
  listDataTreeToAgFlatTree,
  GroupHeaderKey,
} from 'src/utils/Component/AgGrid/AgDataFormat';

import { formatSummaries, processSummaries } from 'src/utils/Pivot/RollUp';
import { filterAndSortPivotItems, filterData, filterPareDown } from 'src/utils/Pivot/Filter';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { parseConfigureConfig } from 'src/components/Configure/Configure';
import { parseSortConfig } from 'src/utils/Config/Sort';
import { DefaultShownValues } from 'src/common-ui/components/DataGrid/DataGrid';
import { buildGroupsFromFlatData } from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.selectors';
import { parseCompanionListViewConfig, CompanionDataLookup } from 'src/utils/Component/ListView';
import { flattenToLeaves } from 'src/utils/Pivot/Flatten';
import { getGroupBySelectedOptionProperty } from 'src/utils/Pivot/Sort';
import { MassEditConfig } from '../MassEdit/MassEdit';
import { flattenAndLabelGroup } from '../StandardCardView/SortAndGroup';
import { FabProps, FabType, getfabProps } from '../higherOrder/withFab';
import { FavoriteResponseItem } from 'src/components/Subheader/Favorites/Favorites.types';
import { ViewDataState } from 'src/types/Domain';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { WithRouterProps } from '../higherOrder/withRouter';

export interface OwnProps extends WithRouterProps {
  showPopover?: boolean;
  subheader?: {
    downloadLink: string;
  };
  fab: FabProps;
};

export interface ViewDefns {
  grid: TenantConfigViewData;
  listSort: TenantConfigViewData;
  subheaderRollUp?: TenantConfigViewData;
  subheaderGroup?: TenantConfigViewData;
  configure?: TenantConfigViewData;
  list: TenantConfigViewData;
  massEdit?: MassEditConfig;
  unmodifiedViewDefn?: TenantConfigViewData;
  favoritesList?: FavoriteResponseItem[];
}

 interface LoadingProjection {
  configLoaded: false;
  dataLoaded: false;
  subheaderViewDefns: SubheaderViewDefns;
  data: GridItem[];
  colDefs: ColDef[];
};

 interface LoadedProjection extends HasFlowStatus {
  identityField: string;
  configLoaded: boolean;
  dataLoaded: boolean;
  defaultConfigureSelections: Option[];
  data: GridItem[];
  uniqueItems: BasicPivotItem[];
  colDefs: ColDef[];
  masterDetailConfig: MasterDetailConfig | null;
  defaultCompanionSortField: SortOption;
  companionDataLookup: CompanionDataLookup;
  sortOptions: SortOption[];
  frameworkComponents: FrameworkComponents;
  treeColumnDefinition: ColDef | undefined;
  stylePaneTriggerSet: Set<string>;
  subheaderViewDefns: SubheaderViewDefns;
  fab?: FabProps;
  adornments: AdornmentType[];
  allowWorklistFunctionality: boolean;
  configureInstructions?: string;
  configureOptionGroups?: OptionGroup[];
  configureSelections?: Option[];
  originalDefaultSelections?: Option[];
  defaultShownValues?: DefaultShownValues;
  isPrintMode?: boolean;
  rowHeight?: number;
  groupRowHeight?: number;
  columnWidth?: number;
  subheaderSummary?: string;
  configuratorViewDefn?: TenantConfigViewData;
  unmodifiedViewDefn?: TenantConfigViewData;
  favoritesList: FavoriteResponseItem[];
  sortBy: SortBySlice;
  groupBy: GroupBySlice;
  flowStatus: number[];
  rollupConfig?: TenantConfigViewItem[];
  downloadLink?: string;
  shouldFilterFlowStatus: boolean;
};

 interface StateProjectionProps extends HasTitle {
  subheaderViewDefns: SubheaderViewDefns;
  hideCompanion?: boolean;
  activeTab?: string;
  viewDataState?: ViewDataState;
  topMembers?: string;
  fab?: FabProps;
};
export interface LoadingStateProjectionProps extends LoadingProjection, StateProjectionProps {}
export interface LoadedStateProjectionProps extends LoadedProjection, StateProjectionProps {}
export type ProjectStateProps = LoadingStateProjectionProps | LoadedStateProjectionProps
export interface StateSelection extends HasTitle, HasFlowStatus {
  fabTooltip?: string;
  isPrintMode?: boolean;
  identityField: string;
  configLoaded: boolean;
  dataLoaded: boolean;
  shouldFilterFlowStatus: boolean;
  aggBys?: string[];
  treeData: BasicPivotItem[];
  subheaderViewDefns: SubheaderViewDefns;
  viewDefns?: ViewDefns;
  isConfigLoading: boolean;
  subheaderState: SubheaderSlice;
  hideCompanion?: boolean;
  activeTab: string;
  adornments: AdornmentType[];
  allowWorklistFunctionality: boolean;
  downloadLink?: string;
  viewDataState?: ViewDataState;
  fab?: FabProps;
};

function generateOrderedGroups(flatData: BasicPivotItem[], groupBy: GroupBySlice) {
  const groups = buildGroupsFromFlatData(flatData, groupBy);
  const newGroupsObj = {};
  groups.forEach((group) => (newGroupsObj[group.header] = group.items));
  return newGroupsObj;
}

export function projectState(stateSelection: StateSelection): ProjectStateProps{
  const {
    fab,
    configLoaded,
    dataLoaded,
    identityField,
    treeData,
    aggBys,
    viewDefns,
    subheaderState,
    title,
    subheaderViewDefns,
    shouldFilterFlowStatus = true,
    adornments,
    activeTab,
    downloadLink,
    viewDataState,
    hideTitle,
    hideCompanion,
    allowWorklistFunctionality,
    showFlowStatus,
  } = stateSelection;

  const { search, sortBy, flowStatus, groupBy, favoritesList } = subheaderState;

  if (configLoaded && viewDefns && !isEmpty(viewDefns.grid) && !isEmpty(viewDefns.list)) {
    // Config
    const {
      rowHeight,
      groupRowHeight,
      columnWidth,
      colDefs,
      treeColumnDefinition,
      frameworkComponents,
      stylePaneTriggerSet,
      defaultShownValues,
      masterDetailConfig,
      hiddenGroupColumnDataIndices,
    } = parseGridConfig(viewDefns.grid);

    let actualTreeColumnDefinition = treeColumnDefinition;
    let actualColDefs = [...colDefs];
    let gridItems: GridItem[];
    let configureOptionGroups;
    let defaultConfigureSelections: Option[] = [],
      originalDefaultSelections;
    let configureInstructions;

    if (viewDefns.configure) {
      const parseResult = parseConfigureConfig(viewDefns.configure);
      configureOptionGroups = parseResult.optionGroups;
      defaultConfigureSelections = parseResult.defaultSelections;
      configureInstructions = parseResult.instructions;
      originalDefaultSelections = parseResult.originalDefaultSelections;
    }
    const flowStatusFilter = (shouldFilterFlowStatus && flowStatus) || [];

    // Parse companion view config
    const companionDataLookup = parseCompanionListViewConfig(viewDefns.list);
    const getFlat = memoize(() => flattenToLeaves(treeData));

    let searchFields = externalGridSearchFields;
    if (viewDefns && viewDefns.grid && viewDefns.grid.searchIndexes) {
      searchFields = viewDefns.grid.searchIndexes;
    }

    // Subheader Group By
    const key = getGroupBySelectedOptionProperty(subheaderState.groupBy, 'dataIndex');
    if (subheaderState.groupByDefn && subheaderState.groupBy.selection && key && key.length > 0) {
      // GroupBy supercedes config passed treeColumns
      if (treeColumnDefinition) {
        actualColDefs.push(treeColumnDefinition);
      }

      const groupDataIndex = getGroupBySelectedOptionProperty(groupBy, 'dataIndex');
      const groupDimension = getGroupBySelectedOptionProperty(groupBy, 'dimension');
      const orderedGroups = generateOrderedGroups(
        flattenAndLabelGroup({ items: treeData, groupDataIndex, groupDimension }),
        subheaderState.groupBy
      );
      const hideGroupedColumn = hiddenGroupColumnDataIndices.has(groupDataIndex);
      if (hideGroupedColumn) {
        actualColDefs = actualColDefs.filter((colDef) => colDef.field !== groupDataIndex);
      }
      const result = groupedToAgFlatTree(
        orderedGroups,
        key,
        search,
        sortBy,
        flowStatusFilter,
        viewDefns.grid.view,
        identityField,
        hideGroupedColumn,
      );
      actualTreeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else if (aggBys && aggBys.length) {
      // GroupBy supercedes config passed treeColumns
      if (treeColumnDefinition) {
        actualColDefs.push(treeColumnDefinition);
      }
      // TODO: make nameOverride configurable, was here to fix INT-573
      const nameOverride = title === 'Nested View';
      const result = listDataTreeToAgFlatTree(aggBys, search, flowStatusFilter, treeData, nameOverride);
      actualTreeColumnDefinition = result.treeColumnDefinition;
      gridItems = result.agFlatTree;
    } else {
      // No Group By
      const flatData = getFlat();
      gridItems = filterAndSortPivotItems(search, sortBy, searchFields, flowStatusFilter, flatData);
    }

    if (gridItems && subheaderState.pareDownDefn && subheaderState.pareDown) {
      gridItems = filterPareDown(gridItems, subheaderState.pareDown.selections, subheaderState.groupBy);
    }

    let subheaderSummary;

    const getUniqFlatLeafs = memoize(() => {
      const leaves = gridItems.filter((item) => item[GroupHeaderKey] === undefined);
      return uniqBy(leaves, identityField);
    });

    if (viewDefns.subheaderRollUp && viewDefns.subheaderRollUp.view) {
      const filtered = filterData(gridItems, search, searchFields, flowStatusFilter);
      subheaderSummary = formatSummaries(processSummaries(filtered, viewDefns.subheaderRollUp.view, identityField));
    }
    const sortParseResult = parseSortConfig(viewDefns.listSort);

    const projection: LoadedStateProjectionProps = {
      configLoaded,
      dataLoaded,
      defaultShownValues,
      identityField,
      // grid
      rowHeight,
      groupRowHeight,
      columnWidth,
      data: gridItems,
      colDefs: actualColDefs,
      masterDetailConfig,
      treeColumnDefinition: actualTreeColumnDefinition,
      frameworkComponents,
      stylePaneTriggerSet,
      // configure
      configureInstructions,
      defaultConfigureSelections,
      configureOptionGroups,
      originalDefaultSelections,
      // subheader
      title,
      hideTitle,
      subheaderViewDefns,
      unmodifiedViewDefn: viewDefns.unmodifiedViewDefn,
      sortBy,
      groupBy,
      flowStatus,
      activeTab,
      rollupConfig: viewDefns.subheaderRollUp && viewDefns.subheaderRollUp.view,
      configuratorViewDefn: viewDefns.grid,
      subheaderSummary,
      shouldFilterFlowStatus,
      showFlowStatus,
      // companion list
      hideCompanion,
      defaultCompanionSortField: sortParseResult.defaultSort,
      sortOptions: sortParseResult.sortOptions,
      companionDataLookup,
      uniqueItems: getUniqFlatLeafs(),
      adornments,
      fab,
      downloadLink,
      favoritesList: favoritesList || [],
      viewDataState,
      allowWorklistFunctionality,
    };
    return projection;
  }
  return {
    configLoaded: false,
    dataLoaded: false,
    title,
    hideTitle,
    hideCompanion,
    subheaderViewDefns,
    activeTab,
    viewDataState,
    data: [],
    colDefs: [],
  };
}

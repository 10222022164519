import { style } from 'typestyle';
import { StylingOverrides } from './WeekRangePicker.interface';
import { isOutsideViewport, pixelsToNumber } from './WeekRangePicker.utils';
import { DayPickerType } from './RadioOptions.styles';

export const SCROLLBAR_WIDTH = 17; // TODO: this is mac's scrollbar width.
export const INPUT_HEIGHT = 34;
export const STATIC_LEFT_OFFSET = 340;
const DEFAULT_STYLE_LEFT = 0;

const secondaryColor = 'rgba(69,182,159,.2)';
const primaryColor = '#56cfc7';
export const TEAL_PRIMARY = '#00a89b';
const white = '#ffffff';
const black = '#000000';

const DayInputContainer = style({
  $debugName: 'DayInputContainer',
  display: 'flex',
  justifyContent: 'space-around',
  flex: 1,
  outline: 'none',
  border: 'none',
  background: 'transparent',
  color: 'white',
  paddingLeft: '10px',
  cursor: 'pointer',
});

const backdrop = style({
  $debugName: 'backdrop',
  width: 4000,
  height: 2000,
  left: -1500,
  top: -500,
  position: 'absolute',
  cursor: 'default',
  zIndex: 100,
  // TODO remove this
  backgroundColor: 'black',
  opacity: 0.3,
});

export function buildWeekRangeContainer(stylingOverrides: StylingOverrides | undefined = {}) {
  const designStyle = {
    primaryColor: stylingOverrides.primaryColor || primaryColor,
    padding: stylingOverrides.padding || '4px',
  };
  return style({
    $debugName: 'WeekRangeContainer',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: designStyle.primaryColor,
    color: white,
    padding: designStyle.padding,
    maxWidth: '400px',
    cursor: 'pointer',
    fontSize: '0.75rem',
    height: '34px',
  });
}

const DayPickerContainer = (options: {}, stylingOverrides: StylingOverrides | undefined = {}) => {
  const designStyle = {
    primaryColor: stylingOverrides.primaryColor || primaryColor,
    secondaryColor: stylingOverrides.secondaryColor || secondaryColor,
    minWidth: stylingOverrides.minWidth,
    border: stylingOverrides.border || undefined,
    borderRadius: stylingOverrides.borderRadius || undefined,
  };

  return style({
    $debugName: 'DayPickerContainer',
    position: 'absolute',
    left: DEFAULT_STYLE_LEFT,
    color: black,
    cursor: 'pointer',
    minWidth: designStyle.minWidth,
    border: stylingOverrides.border,
    borderRadius: stylingOverrides.borderRadius,
    zIndex: 101,
    fontSize: '.9rem',
    $nest: {
      '.DayPicker-Month': {
        borderCollapse: 'separate',
      },
      '.DayPicker-Months': {
        minHeight: 300,
      },
      '.DayPicker-Caption': {
        textAlign: 'center',
      },
      '.DayPicker-NavButton--prev': {
        left: '1.5rem !important',
      },
      '.DayPicker-WeekNumber': {
        outline: 'none',
      },
      '.DayPicker-Day': {
        outline: 'none',
        border: '1px solid transparent',
        borderRadius: 0,
      },
      '.DayPicker-Day--hoverRange': {
        backgroundColor: '#EFEFEF !important',
      },
      '.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside)': {
        backgroundColor: designStyle.secondaryColor + ' !important',
      },
      '.DayPicker-Day--secondOptionRange:not(.DayPicker-Day--outside)': {
        backgroundColor: '#fdcfdb !important',
        border: '1px solid #fdcfdb  ',
      },
      '.DayPicker-Day--selectedAsOptions:not(.DayPicker-Day--outside)': {
        backgroundColor: designStyle.primaryColor + '!important',
        border: '1px solid ' + designStyle.primaryColor,
      },

      '.DayPicker-Day--dateSelection:not(.DayPicker-Day--outside)': {
        backgroundColor: '#f97367' + '!important',
        border: '1px solid #f97367',
      },

      '.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        borderRadius: '0 !important',
        color: 'black !important',
        backgroundColor: designStyle.primaryColor,
      },
      '.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected': {
        borderRadius: '0 !important',
        color: 'black !important',
      },
      '.DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected': {
        borderRadius: '0 !important',
        color: 'black !important',
      },
      '.DayPicker-Day--hoverRange:hover': {
        borderRadius: '0 !important',
      },
    },
    ...options,
  });
};

export const wrappingContainerStyles = style({
  $debugName: 'wrappingContainer',
  left: DEFAULT_STYLE_LEFT,
  position: 'absolute',
  top: INPUT_HEIGHT,
});

export const buildDayPickerContainer = (
  dayPicker: DayPickerType,
  stylingOverrides: StylingOverrides | undefined,
  numberOfMonths: number
) => {
  let left;
  let top;
  if (dayPicker && dayPicker.dayPicker) {
    const { height: dayPickerHeight } = dayPicker.dayPicker.getBoundingClientRect();

    if (dayPickerHeight) {
      const dayPickerComputedStyles = window.getComputedStyle(dayPicker.dayPicker);
      const isOut = isOutsideViewport(dayPicker.dayPicker, SCROLLBAR_WIDTH);

      if (isOut.bottom) {
        top = -(INPUT_HEIGHT + dayPickerHeight);
      }

      const hasPickerMovedLeft = !!pixelsToNumber('left', dayPickerComputedStyles);
      if (isOut.right || hasPickerMovedLeft) {
        left = -STATIC_LEFT_OFFSET;
      }
    }
  }

  let minWidth = 750;
  if (numberOfMonths === 1) {
    minWidth = 400;
  }
  if (stylingOverrides) {
    if (stylingOverrides.minWidth !== undefined) {
      minWidth = stylingOverrides.minWidth;
    }
    if (stylingOverrides.top !== undefined) {
      top = stylingOverrides.top;
    }
  }
  // Don't go off screen
  if (left && left < -260) {
    left = -260;
  }
  return DayPickerContainer({ minWidth, left, top, background: 'white' }, stylingOverrides);
};

export const buttonContainerStyles = (dayPicker: DayPickerType) => {
  const buttonContainerMarginRightBottom = 15;
  const buttonHeightWidth = 34;
  const buttonMarginLeftRigth = 4;
  const buttonContainerWidth = buttonHeightWidth * 2 + buttonMarginLeftRigth * 4;
  let top;
  let left;
  let marginTopHeight;

  if (dayPicker && dayPicker.dayPicker) {
    const { height: dayPickerHeight, width: dayPickerWidth } = dayPicker.dayPicker.getBoundingClientRect();

    if (dayPickerWidth && dayPickerHeight) {
      marginTopHeight = dayPickerHeight - buttonContainerMarginRightBottom

      const dayPickerComputedStyles = window.getComputedStyle(dayPicker.dayPicker);
      const isOut = isOutsideViewport(dayPicker.dayPicker, SCROLLBAR_WIDTH);

      const totalTopHeight = -(INPUT_HEIGHT + dayPickerHeight);
      top = 0;
      if (isOut.bottom) {
        top = totalTopHeight;
      }

      const hasPickerMovedLeft = !!pixelsToNumber('left', dayPickerComputedStyles);
      const calculatedLeft = dayPickerWidth - (buttonContainerWidth + buttonContainerMarginRightBottom);
      left = calculatedLeft;
      if (isOut.right || hasPickerMovedLeft) {
        left = calculatedLeft + buttonContainerWidth - STATIC_LEFT_OFFSET;
      }
    }
  }

  // Don't go off screen
  if (left && left < -260) {
    left = -260;
  }

  return style({
    $debugName: 'buttonContainer',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    left: left ? left : 681,
    marginBottom: buttonContainerMarginRightBottom,
    marginTop: marginTopHeight,
    position: 'absolute',
    top: top,
    width: buttonContainerWidth,
    zIndex: 200,
    $nest: {
      '.button': {
        height: buttonHeightWidth,
        margin: `0 ${buttonMarginLeftRigth}px`,
        width: buttonHeightWidth,
        boxShadow: 'none',
        $nest: {
          '&__submit': {
            backgroundColor: TEAL_PRIMARY,
          },
          '&__cancel': {
            backgroundColor: 'red',
          },
        },
      },
      '.icon': {
        color: 'white',
        fontSize: 18,
      },
      '.s5-MuiTypography-body1': {
        fontSize: 14,
        color: 'black',
      },
      '.buttonContainer': {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
      },
    },
  });
};

export default {
  DayInputContainer,
  backdrop,
  TEAL_PRIMARY,
};

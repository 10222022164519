import * as React from 'react';
import { ICellEditorParams } from '@ag-grid-community/core';
import { isEmpty, get, isNil, isArray, isString } from 'lodash/fp';
import { style, classes } from 'typestyle';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { ClientDataApi } from 'src/services/configuration/codecs/confdefnView';
import { SpecialStoreGroupEditor } from './SpecialStoreGroupEditor';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { SizeDetail } from './TabbedReceiptsAdjCalculator';
import { StyleDetailsEditor } from './StyleDetailsEditor';
import { BasicItem } from 'src/worker/pivotWorker.types';
import AcceptButton from 'src/components/AcceptButton/AcceptButton';
import RejectButton from 'src/components/RejectButton/RejectButton';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import { makeModalOverlay } from './Editor.styles';

export interface PopoverSelectState {
  initialSelection: Suggestion | null;
  selection: Suggestion | null;
  sizes: SizeDetail[];
  overlayWidth: number;
  overlayHeight: number;
}

export interface PopoverSelectProps extends ICellEditorParams {
  title: string;
  inputComponent: string;
  dataApi: ClientDataApi;
  configApi: ClientDataApi;
  sizes: SizeDetail[];
  hideBottomButtons?: boolean;
  styleColorData?: BasicItem;
  makeModal?: boolean;
}

// Override for style details so it doesn't go off screen
const lowerStyleDetails = 190;
const makeModalContainerStyle = (type?: string) => {
  const styles: NestedCSSProperties = {
    background: 'white',
    position: 'absolute',
    left: 150,
    width: '35vw',
    bottom: -130,
    boxShadow: '3px 3px 9px 3px rgba(0,0,0,0.44)',
    $nest: {
      '@media (max-width: 1366px)': {
        width: '30vw',
        height: '42vh',
      },
    },
  };
  if (type === 'styleDetailsSelect') {
    styles.bottom = (styles.bottom as number) + lowerStyleDetails * -1;
    styles.left = 150;
  }

  return style(styles);
};

const makeModalArrowStyle = (type: string, isBackgroundOutline?: boolean) => {
  const styles: NestedCSSProperties = {
    color: 'white',
    display: 'block',
    position: 'absolute',
    borderTop: '20px solid transparent',
    borderBottom: '20px solid transparent',
    borderRight: '20px solid white',
    left: -20,
    bottom: 0,
    zIndex: 1,
  };
  if (type === 'styleDetailsSelect') {
    styles.bottom = (styles.bottom as number) + (lowerStyleDetails + 7);
  }
  if (isBackgroundOutline) {
    styles.borderRight = '20px solid #afafaf';
    styles.left = (styles.left as number) + -1;
  }

  return style(styles);
};

const headerContainer = style({
  $debugName: 'popoverSelectHeaderContainer',
  background: TEAL_PRIMARY,
  padding: '0.5rem 0.75rem !important',
  fontSize: '0.85rem',
});

const headerText = style({
  color: '#ffffff',
  fontWeight: 'normal',
});

const actionsStyle = style({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '10px 20px 20px !important',
  $nest: {
    button: {
      marginLeft: '10px',
    },
  },
});

function getSsgSelection(initialValue: string | string[]): string {
  if (isString(initialValue)) {
    return initialValue;
  } else if (isArray(initialValue)) {
    return isEmpty(initialValue) ? '' : initialValue[0];
  }

  return '';
}

export class PopoverSelect extends React.Component<PopoverSelectProps> {
  state: PopoverSelectState;
  /** This controls whether the `getValue` receives the upated value or not, based on if the popover is blured or not */
  editCanceled = true; // assume canceled since outside click can cause stopEdit without detection

  constructor(props: PopoverSelectProps) {
    super(props);

    this.state = {
      selection: null,
      initialSelection: null,
      sizes: [],
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    };

    window.addEventListener('resize', this.resizeOverlay.bind(this));
  }

  resizeOverlay() {
    this.setState({
      overlayWidth: window.innerWidth,
      overlayHeight: window.innerHeight,
    });
  }

  getValue() {
    const { selection } = this.state;

    if (this.props.inputComponent !== 'ssgSelect' && this.editCanceled) {
      // clean out empty string if necessary
      if (isArray(this.props.value) && !isEmpty(this.props.value) && this.props.value[0] === '') {
        return [];
      }

      return this.props.value;
    } else if (this.props.inputComponent === 'ssgSelect') {
      const selectionObj = this.editCanceled ? this.state.initialSelection : selection;

      return !isNil(selectionObj)
        ? {
          id: get('value.id', selectionObj),
          store_count: get('value.description', selectionObj),
        }
        : undefined;
    }

    return !isNil(selection) ? get('value.id', selection) : undefined;
  }

  isPopup() {
    return true;
  }

  handleSelection = (selection: Suggestion | null, editorRenderSelection = false) => {
    const newState = { selection };

    if (editorRenderSelection) {
      newState['initialSelection'] = selection;
    }

    this.setState(newState);
  };

  handleUpdate = () => {
    this.editCanceled = false;
    this.props.stopEditing();
  };

  handleClose = () => {
    this.props.stopEditing();
  };

  renderEditor = (type: string) => {
    const { dataApi, configApi, value: initialValue, styleColorData } = this.props;
    const { selection } = this.state;

    switch (type) {
      case 'ssgSelect':
        return (
          <SpecialStoreGroupEditor
            onSelect={this.handleSelection}
            dataConfig={dataApi}
            selectedItemId={isNil(selection) ? getSsgSelection(initialValue) : get('value.id', selection)}
          />
        );
      case 'styleDetailsSelect':
        return (
          <StyleDetailsEditor
            dataApi={dataApi}
            configApi={configApi}
            styleColorData={styleColorData ? [styleColorData] : undefined}
          />
        );
      default:
        return `Unknown PopoverSelect inputComponent type: ${type}`;
    }
  };

  render() {
    const { title, inputComponent, hideBottomButtons, makeModal } = this.props;

    const content = (
      <Card>
        <CardHeader
          title={<span className={headerText}>{`Select ${title}`}</span>}
          classes={{
            root: headerContainer,
          }}
          action={
            <IconButton>
              <Icon className={classes('fas fa-times', headerText)} onClick={this.handleClose} />
            </IconButton>
          }
        />
        <CardContent>{this.renderEditor(inputComponent)}</CardContent>
        {!hideBottomButtons && (
          <CardActions className={actionsStyle}>
            <AcceptButton onClick={this.handleUpdate} />
            <RejectButton onClick={this.handleClose} />
          </CardActions>
        )}
      </Card>
    );

    if (makeModal) {
      return (
        <div
          className={makeModalOverlay(false)}
          style={{ width: this.state.overlayWidth, height: this.state.overlayHeight }}
        >
          {content}
        </div>
      );
    } else {
      return (
        <div data-qa="ag-popover" className={makeModalContainerStyle(inputComponent)}>
          <div data-qa="ag-popover-arrow-outline" className={makeModalArrowStyle(inputComponent, true)} />
          <div data-qa="ag-popover-arrow" className={makeModalArrowStyle(inputComponent)} />
          {content}
        </div>
      );
    }
  }
}

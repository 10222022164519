import * as React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { concat, filter, forEach, head, intersectionBy, isEmpty, isNil, mapValues, pick, unionBy, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ServiceContainer from 'src/ServiceContainer';
import { modal } from 'src/components/Configure/Configure.style';
import { ToggleAction } from 'src/components/Configure/ConfigureModal';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';
import { getValidValues } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { muiTheme } from 'src/utils/Style/Theme';
import { zConfigurableGridMultiSelectPostButton } from 'src/services/configuration/codecs/viewdefns/general';
import { getUrl, processApiParams } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { z } from 'zod';
import { generateCoordinateValues } from './ConfigurableGrid.utils';
import { AppType } from 'src/services/configuration/codecs/bindings.types';
import {
  searchInputContainerStyle,
  tableStyle,
  styles,
  selectedTextContainer,
  tableContentContainer,
} from './ActionModal.style';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Skeleton } from '@material-ui/lab';
export interface ActionModalProps {
  isOpen: boolean;
  // config
  onToggleModal: (action: ToggleAction) => void;
  selectedItems: Record<string, unknown>[];
  config?: z.infer<typeof zConfigurableGridMultiSelectPostButton>;
}
export interface Option {
  value: string | number;
  label: string;
}
const LoadingSkeletons = () => {
  return (
    <React.Fragment>
      <Skeleton animation="wave" variant="text" width="100%" />
    </React.Fragment>
  );
};
export const ActionModal = ({ isOpen, onToggleModal, selectedItems, config }: ActionModalProps) => {
  const [dropdownData, setDropdownData] = useState<Option[]>([]);
  const [selections, setSelections] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isDataLoaded, setDataloaded] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  useEffect(() => {
    const firstItem = head(selectedItems);
    if (isNil(firstItem) || isNil(config)) {
      return;
    }
    const dataApi = config.params.dataApi;
    setDataloaded(false);
    setError(false);
    const fetchDropdownData = async () => {
      try {
        if (dataApi.isListData) {
          const processedParamsArray = selectedItems.map((item) => processApiParams(dataApi, item).params);

          const joinedParams = mapValues(
            processedParamsArray.reduce((acc, item) => {
              forEach(item, (value, key) => {
                acc[key] = acc[key] ?? [];
                acc[key].push(value);
              });
              return acc;
            }, {}),
            (value) => uniq(value).join(',')
          );

          const res = await ServiceContainer.pivotService.listData(dataApi.defnId, AppType.Assortment, {
            ...dataApi.params,
            ...joinedParams,
          });

          setDataloaded(true);
          setDropdownData((res.flat as unknown) as Option[]);
        } else {
          const processedParams = processApiParams(dataApi, firstItem);
          const values = await getValidValues(getUrl(processedParams));

          setDropdownData(
            values.map((v: Option) => ({
              value: v.value,
              label: v.label,
            }))
          );
          setDataloaded(true);
        }
      } catch (err) {
        setError(true);
        setDataloaded(true); // Set to true to stop any loaders even on error
      }
    };
    fetchDropdownData();
  }, [config, selectedItems]); // <- this should be the config, but I'll fix once I send in config

  const submitUpdate = useCallback(async () => {
    if (isNil(config) || isEmpty(selectedItems)) {
      return; // how did we even get here?
    }
    const { keysToCopy, keyToUpdate, coordinateMap } = config.params;
    const payload: GranularEditPayloadItem[] = (selectedItems as BasicPivotItem[]).map((item) => {
      return {
        coordinates: generateCoordinateValues(coordinateMap, item),
        ...pick(item, keysToCopy),
        [keyToUpdate]: selections.map((i) => i.value),
      };
    });
    await ServiceContainer.pivotService.granularEditSubmitData(payload);
  }, [config, selectedItems, selections]);

  const closeModal = useCallback(
    async (toggleType: ToggleAction) => {
      if (toggleType === 'apply') {
        // submit based on selections then close
        await submitUpdate();
      }
      onToggleModal(toggleType);
    },
    [onToggleModal, submitUpdate]
  );

  const filteredDropdownData = useMemo(() => {
    if (isNil(searchValue) || isEmpty(searchValue)) {
      return dropdownData;
    }
    const searchTerms = searchValue
      .toLowerCase()
      .split(';')
      .filter((term) => !isEmpty(term.trim()));

    return dropdownData.filter((i) =>
      searchTerms.some(
        (term) => i.label.toLowerCase().indexOf(term.trim()) >= 0 || (i.value as string).indexOf(term.trim()) >= 0
      )
    );
  }, [searchValue, dropdownData]);

  const selectAllFiltered = useCallback(() => {
    setSelections(unionBy(filteredDropdownData, selections, (o) => o.value));
  }, [selections, filteredDropdownData]);

  useEffect(() => {
    if (filteredDropdownData.length > 0 && selections.length > 0) {
      const allFilteredSelected =
        intersectionBy(filteredDropdownData, selections, (o) => o.value).length == filteredDropdownData.length;
      setSelectAll(allFilteredSelected);
    } else {
      setSelectAll(false);
    }
  }, [selections, filteredDropdownData]);

  const createTable = useCallback(() => {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={searchInputContainerStyle}>
          <input
            type="text"
            placeholder="Search groups..."
            aria-label="Search groups"
            onChange={(ev) => setSearchValue(ev.currentTarget.value)}
            defaultValue={''}
          />
          <button
            className="select-all-btn"
            onClick={() => {
              selectAllFiltered();
            }}
            disabled={selectAll}
          >
            Select All
          </button>
        </div>
        <div className={selectedTextContainer}>
          <Tooltip title={'Deselect All'}>
            <HighlightOffIcon
              onClick={() => {
                setSelections([]);
              }}
              className={`${styles.removeAllButton} ${selections.length === 0 ? 'disabled' : ''}`}
            />
          </Tooltip>
          Selected: {selections.length}
        </div>
        <div className={tableContentContainer}>
          <Table stickyHeader={true}>
            <TableHead className={tableStyle}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                if (isError) {
                  return <TableCell colSpan={2}>Failed to fetch item data</TableCell>;
                }

                if (!isDataLoaded) {
                  return Array(4)
                    .fill(null)
                    .map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <LoadingSkeletons />
                        </TableCell>
                        <TableCell>
                          <LoadingSkeletons />
                        </TableCell>
                      </TableRow>
                    ));
                }

                if (isEmpty(dropdownData)) {
                  return <TableCell colSpan={2}>No Data</TableCell>;
                }

                return filteredDropdownData.map((item) => {
                  const { label: name, value } = item;
                  const isSelected = selections.some((s) => s.value === value);
                  const rowClass = isSelected ? styles.selected : styles.unselected;

                  return (
                    <TableRow
                      key={value}
                      className={rowClass}
                      onClick={() => {
                        setSelections((curSels) =>
                          isSelected ? filter(curSels, (s) => s.value !== value) : concat(curSels, item)
                        );
                      }}
                    >
                      <TableCell>{value}</TableCell>
                      <TableCell>{name}</TableCell>
                    </TableRow>
                  );
                });
              })()}
            </TableBody>
          </Table>
        </div>
      </MuiThemeProvider>
    );
  }, [dropdownData, filteredDropdownData, isDataLoaded, isError, selectAll, selectAllFiltered, selections]);
  const className = modal + ' configure-modal';

  const table = createTable();
  return (
    <Dialog
      open={isOpen}
      className={className}
      onClose={() => closeModal('close')}
      fullWidth={true}
      scroll={'paper'}
      maxWidth={'xl'}
    >
      <DialogTitle>
        <span className="left-container">
          <i className="fas fa-cog icon" />
          {config?.text}
        </span>
        <span className="right-container">
          <i className="far fa-times" onClick={() => closeModal('close')} />
        </span>
      </DialogTitle>
      <DialogContent>
        {table}
        <footer>
          <button
            onClick={() => closeModal('apply')}
            className="apply"
            disabled={!isDataLoaded || isError || isEmpty(dropdownData) || isEmpty(selections)}
          >
            <i className="far fa-check" />
            Apply
          </button>
          <button onClick={() => closeModal('close')} className="reset">
            <i className="fas fa-ban" />
            Reset
          </button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};

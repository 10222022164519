import fp from 'lodash/fp';
import { connect } from 'react-redux';
import container from 'src/ServiceContainer';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { makePrintSensitive } from 'src/components/higherOrder/Print/PrintSenstive';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { overloadSubheader } from 'src/components/Subheader/Subheader.slice';
import {
  cleanUp,
  receiveError,
  requestFlowSheetByStyleConfig,
  receiveFlowSheetByStyleConfig,
  fetchCompanionData,
  updateSelectedItem,
  refreshOvertimeData,
  receiveSomeFlowSheetConfig,
  submitMassEditPayload,
  FlowSheetViewDefns,
} from './FlowSheetByStyle.slice';
import { AppState, AppThunkDispatch } from 'src/store';
import { StateSelection, projectState } from 'src/components/ListGridPair/ListGridPair.selectors';
import { FlowSheetByStyle } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetByStyle';
import { isNil, isEmpty, isEqual, isArray, isObject, transform } from 'lodash/fp';
import { get } from 'lodash';
import { makePopoverSensitive } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { MassEditConfig } from 'src/components/MassEdit/MassEdit';
import { FabType, getfabProps, withFab } from 'src/components/higherOrder/withFab';
import { updateAssortmentPlan } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.actions';
import { FlowSheetAndPricingDispatchProps, OwnProps, FlowSheetAndPricingMapState } from './FlowSheet.types';
import { getUniqueDataFromCache, HashType, isDataLoaded } from 'src/services/pivotServiceCache';
import { ViewDataState } from 'src/types/Domain';
import { ComponentErrorType } from 'src/components/ErrorBoundary/ErrorBoundary.slice';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { zFlowSheetGridDefn, MassEditDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import {
  CompanionViewDefn,
  GroupByConfig,
  RollupDefn,
  SortByConfig,
} from 'src/services/configuration/codecs/viewdefns/general';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';

import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { getLocalConfig } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal.utils';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';

export function dispatchToProps(dispatch: AppThunkDispatch, ownProps: OwnProps): FlowSheetAndPricingDispatchProps {
  const { tenantConfigClient } = container;
  const { defns } = ownProps;
  const factory = {
    submitPayload(payload: GranularEditPayloadItem[]) {
      return dispatch(submitMassEditPayload(payload));
    },
    getMassEditData() {
      // do nothing
    },
    onCompanionItemClick(memberId: string) {
      dispatch(updateSelectedItem(memberId));
    },
    onShowView() {
      dispatch(requestFlowSheetByStyleConfig());
      tenantConfigClient
        .getTenantViewDefnsWithFavorites({
          defnIds: defns.view,
          appName: ASSORTMENT,
          validationSchemas: [
            zFlowSheetGridDefn,
            SortByConfig,
            GroupByConfig,
            RollupDefn,
            CompanionViewDefn,
            MassEditDefn,
          ],
        })
        .then((resp) => {
          const defnStr = defns.view[0];
          const favoritesList = (resp as any)[defns.view.length];
          const unmodifiedViewDefn = resp[0];
          const localConfig = getLocalConfig(defnStr, favoritesList, dispatch, unmodifiedViewDefn);

          const configWithFavorites =
            isNil(localConfig) || isNil(localConfig.config) ? unmodifiedViewDefn : localConfig.config;
          const hasMassEdit = defns.view.length >= 6;
          const maybeMassEdit = hasMassEdit ? resp[defns.view.length - 1] : undefined;

          dispatch(
            receiveFlowSheetByStyleConfig({
              // lazy change, types are insane
              grid: configWithFavorites as any,
              listSort: resp[1],
              subheaderRollUp: resp[3],
              list: resp[4],
              // FIXME: revisit this type issue
              massEdit:
                !isNil(maybeMassEdit) && !isEmpty(maybeMassEdit)
                  ? ((maybeMassEdit as unknown) as MassEditConfig)
                  : undefined,
              unmodifiedViewDefn,
            })
          );
          dispatch(
            overloadSubheader({
              groupByOptions: resp[2],
              showFlowStatus: true,
              showSearch: true,
              title: 'Grid View',
            })
          );

          dispatch(fetchCompanionData(defns.models));
        })
        .catch((error) => {
          dispatch(
            receiveError({
              type: ComponentErrorType.config,
              message: (error as Error)?.message,
              name: ConfDefnComponentType.flowSheet,
              stack: (error as Error)?.stack,
              issues: error,
              defnId: error.defnId,
            })
          );
        });
    },
    onRefetchData() {
      // resetting selected item so overtimeData fetches with new item
      dispatch(updateSelectedItem(''));
      dispatch(fetchCompanionData(defns.models));
    },
    onRefetchOvertimeData() {
      dispatch(refreshOvertimeData());
    },
    onDestroy() {
      dispatch(cleanUp());
    },
    onUpdateConfig(config: TenantConfigViewData) {
      dispatch(receiveSomeFlowSheetConfig((config as unknown) as FlowSheetViewDefns));
    },
    updateAssortmentPlan: () => {
      dispatch(updateAssortmentPlan('PlanQueue'));
    },
  };

  return factory;
}

window['o_diff'] = function difference(origObj: any, newObj: any) {
  function changes(newObj: any, origObj: any) {
    let arrayIndexCounter = 0;
    return transform(newObj, function(result: any, value: any, key: any) {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] = isObject(value) && isObject(origObj[key]) ? changes(value, origObj[key]) : value;
      }
    });
  }
  return changes(newObj, origObj);
};

export function mapStateToProps(state: AppState, ownProps: OwnProps): FlowSheetAndPricingMapState {
  const viewState = state.pages.assortmentBuild.flowSheet;
  const { title, defns, keys, showPopover, fabType = FabType.none, showFlowStatus, showUndoBtn } = ownProps;
  const { rangeList, daysRangeList, timeInfo } = state.scope;
  const allowFrom = !isEmpty(rangeList) ? rangeList[0].id : '';
  const allowTo = !isEmpty(rangeList) ? rangeList[rangeList.length - 1].id : '';
  const fabProps = getfabProps(state, fabType);

  const companionData = getUniqueDataFromCache(viewState, HashType.flowsheetCompanion)?.tree || [];
  const overTimeData = getUniqueDataFromCache(viewState, HashType.flowsheetOverTime)?.tree || [];
  const companionDataLoaded = isDataLoaded(viewState.viewDataStateCompanion);
  const overTimeDataLoaded = isDataLoaded(viewState.viewDataStateOverTime);
  const massEditDataLoaded = isDataLoaded(viewState.viewDataStateMassEdit);
  const liveDataReady = viewState.viewDataStateOverTime === ViewDataState.liveDataReady;
  const adornments: AdornmentType[] = get(viewState.viewDefns, 'grid.adornments', []);

  const selectedId = viewState.selectedItem;
  const rowHeight = viewState.viewDefns.grid.main?.rowHeight;
  const groupRowHeight = viewState.viewDefns.grid.main?.groupRowHeight;
  const columnWidth = viewState.viewDefns.grid.main?.columnWidth;
  const configLoaded = !viewState.isConfigLoading;
  const dataLoaded = companionDataLoaded;
  const scopeStart = get(state, 'scope.scope.start');
  const originalState = {
    title,
    identityField: keys.idProp,
    treeData: companionData,
    subheaderState: state.subheader,
    subheaderViewDefns: defns.subheader,
    shouldFilterFlowStatus: true,
    showPopover,
    allowFrom,
    allowTo,
    rangeList: daysRangeList,
    ...viewState,
    activeTab: state.perspective.activeTab,
    timeInfo,
    showFlowStatus: showFlowStatus === false ? false : true,
    adornments,
    selectedId,
    rowHeight,
    groupRowHeight,
    columnWidth,
    configLoaded,
    dataLoaded,
    showUndoBtn,
    fab: fabProps,
  };

  const stateProps = {
    ...originalState,
    ...projectState((originalState as unknown) as StateSelection),
    ...viewState,
    scopeStart,
    overTimeDataLoaded,
    massEditDataLoaded,
    liveDataReady,
    overTimeData,
  };
  return stateProps;
}
const sensitiveView = fp.flow(
  () => FlowSheetByStyle,
  withFab,
  makeScopeAndFilterSensitive,
  makePrintSensitive,
  makePopoverSensitive
)();

export default connect(mapStateToProps, dispatchToProps)(sensitiveView);

import * as React from 'react';
import { FilterValue } from './Filters.interfaces';

import styles from './Filters.styles';

export default class FilterSectionItem extends React.PureComponent<FilterValue> {
  checkBoxRef: React.RefObject<HTMLInputElement>;

  constructor(props: FilterValue) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.checkBoxRef = React.createRef();
  }

  handleChange() {
    const { id, onToggleFilter, filterDefnId, selected } = this.props;
    const itemData = {
      id,
      filterDefnId,
      value: !selected,
    };

    if (onToggleFilter) {
      onToggleFilter(itemData);
    }
  }

  render() {
    const { id, selected, disabled } = this.props;

    return (
      <div className="filter-item" data-qa-component="filter-item" data-qa-key={id} onClick={this.handleChange}>
        <input
          data-qa-action={'select-filter-section-item'}
          ref={this.checkBoxRef}
          type="checkbox"
          className={styles.filterCheckbox}
          checked={selected}
          disabled={disabled && !selected}
        />
        <label htmlFor={id} className={disabled ? 'disabled' : undefined}>
          {id}
        </label>
      </div>
    );
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultTo } from 'lodash';
import { NavPath } from 'src/pages/NavigationShell/navigationUtils';

export interface BreadcrumbState {
  crumbPaths: Record<string, NavPath[]>;
  activeBreadcrumb: string | null;
}

const initialState: BreadcrumbState = {
  crumbPaths: {},
  activeBreadcrumb: null,
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: initialState,
  reducers: {
    addCrumb: (state, action: PayloadAction<[string, NavPath[]]>) => {
      const existingCrumbs = defaultTo(state.crumbPaths[action.payload[0]], []);
      // @ts-ignore
      const replacementCrumbs = existingCrumbs.concat(action.payload[1]);
      state.crumbPaths[action.payload[0]] = replacementCrumbs;
      state.activeBreadcrumb = action.payload[0];
    },
    removeLastCrumb: (state, action: PayloadAction<string>) => {
      state.crumbPaths[action.payload].pop();
    },
    resetCrumbs: (state, action: PayloadAction<string>) => {
      state.crumbPaths[action.payload] = [];
      state.activeBreadcrumb = null;
    },
  },
});

export const { addCrumb, removeLastCrumb, resetCrumbs } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;

import { style } from 'typestyle';

const inputStyle = style({
  width: '100%',
  height: '100%',
});

const arrowlessNumberInputStyle = style({
  '-moz-appearance': 'textfield', // hide number spinners in firefox
  $nest: {
    '&::-webkit-outer-spin-button': {
      display: 'none',
    },
    '&::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
});

export { inputStyle, arrowlessNumberInputStyle };

import { connect } from 'react-redux';
import { concat, defaultTo, first, isEqual, last } from 'lodash';

import { SelectionOverride, FilterSelection } from 'src/common-ui/components/Filters/Filters';

import serviceContainer from 'src/ServiceContainer';
import { AppState, AppThunkDispatch } from 'src/store';
import { DaysRangeListResponse, FilterGroup } from 'src/types/Scope';
import { computeFilterSelection } from 'src/utils/Filter/Filters';
import FilterPanel from './FilterPanel';

import {
  updateSelectionOverrides,
  flushSelectionOverridesStarted,
  receiveFilterState,
  makeUpdateLastSelection,
  receiveFilterStateAfterSelection,
  isLoading,
  rollbackSelectionOverrides,
  receiveFilterStateAfterSubmission,
  overwriteSelectionOverridesByDefnId,
  clearAllSelections,
  clearTabSelections,
  clearSectionSelections,
} from './FilterPanel.slice';
import { getSectionContext } from 'src/utils/Domain/Perspective';
import { toast } from 'react-toastify';
import { getActiveContextString } from 'src/pages/PerspectiveSelection/PerspectiveSelection.selectors';

export interface FilterSelections {
  id: string;
  filterDefnId: string;
}

export interface ReduxSlice {
  isFilterSensitive?: boolean;
  updatedAt?: number;
  state: FilterGroup[];
  isFlushing: boolean;
  isLoading: boolean;
  selectionOverrides: SelectionOverride[];
  lastSelections: FilterSelection[];
  lastPostedSelections: FilterSelection[];
  containsInvalid: boolean;
  selectionsChanged: boolean;
}

export interface StoreProps extends ReduxSlice {
  allowFrom: string;
  allowTo: string;
  daysRangeList: DaysRangeListResponse;
  scopeStartMonth: string | null;
}
export interface Props extends DispatchProps, StoreProps {}

export function submitFilterSelections() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeProps = state.filters;
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
    const section = getActiveContextString(state);
    if (!isEqual(filterSelections, storeProps.lastSelections)) {
      dispatch(flushSelectionOverridesStarted());
      serviceContainer.filterClient
        .setFilterSelections(filterSelections, {
          appName,
          section,
        })
        .then(() => {
          serviceContainer.filterClient
            .getFullFilters({
              appName,
              section,
            })
            .then((refreshResult) => {
              if (refreshResult) {
                const submission = {
                  filters: refreshResult.filters,
                  updatedAt: Date.now(),
                };
                dispatch(receiveFilterStateAfterSubmission(submission));
                dispatch(makeUpdateLastSelection(filterSelections));
              }
            });
        });
    }
  };
}
export function getAvailableFilterSelections(sectionId: string) {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeProps = state.filters;
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    const filterSelections = computeFilterSelection(storeProps.state, storeProps.selectionOverrides);
    const section = getActiveContextString(state);
    dispatch(isLoading(true));
    serviceContainer.filterClient
      .getAvailableFilterSelections({
        appName,
        section,
        filterSelections,
        attributeId: sectionId,
      })
      .then((refreshResult) => {
        if (refreshResult) {
          dispatch(
            receiveFilterStateAfterSelection({
              filters: refreshResult.filters,
              postedSelections: filterSelections,
            })
          );
        }
      });
  };
}
export function getFilters() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    const section = getSectionContext();

    dispatch(flushSelectionOverridesStarted());
    dispatch(isLoading(true));
    serviceContainer.filterClient
      .getFullFilters({
        appName,
        section,
      })
      .then((result) => {
        if (result) {
          dispatch(receiveFilterState({ filters: result.filters, updatedAt: Date.now() }));
        }
      })
      .catch((err) => {
        dispatch(receiveFilterState({ filters: [], updatedAt: Date.now() }));
        toast.error('An error occured fetching filters');
        serviceContainer.loggingService.error('An error occured fetching filters', err);
      });
  };
}

export function getFilterSelections() {
  return (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const perspective = state.perspective.selected!;
    const appName = perspective.appType;
    serviceContainer.filterClient.getOnlyFilterSelections(appName).then((selections) => {
      dispatch(rollbackSelectionOverrides(selections));
    });
  };
}

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    updateSelectionOverrides(overrides: SelectionOverride[]) {
      dispatch(updateSelectionOverrides(overrides));
    },
    overwriteSelectionOverridesByDefnId(defnId: string, selections: SelectionOverride[]) {
      dispatch(overwriteSelectionOverridesByDefnId({ defnId, selections }));
    },
    clearAllSelections() {
      dispatch(clearAllSelections());
    },
    clearTabSelections(tabId: string) {
      dispatch(clearTabSelections(tabId));
    },
    clearSectionSelections(sectionId: string) {
      dispatch(clearSectionSelections(sectionId));
    },
    submitSelectionOverrides() {
      dispatch(submitFilterSelections());
    },
    getAvailableFilterSelections(sectionId: string) {
      dispatch(getAvailableFilterSelections(sectionId));
    },
  };
};

const mapStateToProps = (state: AppState) => {
  const { filters, scope } = state;
  const { daysRangeList, daysPastRangeList, pastRangeList, rangeListExtended } = scope;

  const filterSelections = computeFilterSelection(filters.state, filters.selectionOverrides);

  // data for range pickers
  // FIXME: should combined lists be in a selector?
  const combinedDaysRangeList: DaysRangeListResponse = {
    start_date: {
      ...daysRangeList.start_date,
      ...daysPastRangeList.start_date,
    },
    end_date: {
      ...daysRangeList.end_date,
      ...daysPastRangeList.end_date,
    },
  };
  const combinedRangeList = concat(pastRangeList, rangeListExtended);
  const startRangeList = combinedRangeList.filter(
    (x) => Object.values(combinedDaysRangeList.start_date).indexOf(x.id) !== -1
  );
  const endRangeList = combinedRangeList.filter(
    (x) => Object.values(combinedDaysRangeList.end_date).indexOf(x.id) !== -1
  );
  const allowFrom = defaultTo(first(startRangeList)?.id, '');
  const allowTo = defaultTo(last(endRangeList)?.id, '');

  return {
    ...filters,
    selectionsChanged: !isEqual(filterSelections, filters.lastSelections),
    allowFrom,
    allowTo,
    daysRangeList: combinedDaysRangeList,
    scopeStartMonth: scope.scope.start,
  };
};

export interface DispatchProps extends ReturnType<typeof mapDispatchToProps> {}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);

import React from 'react';

import Overlay from 'src/common-ui/components/Overlay/Overlay';
import CompanionListView, {
  Props as CompanionProps,
  SortOption,
} from 'src/common-ui/components/CompanionListView/CompanionListView';

import { Option } from 'src/components/Configure/ConfigureModal';
import Subheader from 'src/components/Subheader/Subheader.container';
import { LegacySubheaderActionButtonProps, SortByDirection } from 'src/components/Subheader/Subheader.types';
import { resolvePath } from 'src/cdn';

import { listPairStyle } from 'src/components/ListGridPair/ListGridPair.styles';
import { companionStyles } from 'src/common-ui/components/CompanionListView/CompanionListView.styles';
import { FlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetGrid';
import { FlowSheetByStyleProps, State, FlowSheetGridProps } from './FlowSheet.types';
import { companionDataParse } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.utils';
import { ASSORTMENT_BUILD_FILTER_WARNING, CACHED_DATA_EDITING_DISABLED_WARNING } from 'src/utils/Domain/Constants';
import { isNil, isEmpty, isEqual, filter, findIndex, map, indexOf, uniqBy, get, isUndefined } from 'lodash';

import noImagePath from 'src/common-ui/images/noimage.jpg';
import { MassEdit, MassEditSubmissionData, MassEditSelectItemData } from 'src/components/MassEdit/MassEdit';
import serviceContainer from 'src/ServiceContainer';
import { FabType } from 'src/components/higherOrder/withFab';

import { executeCalculation } from 'src/utils/LibraryUtils/MathUtils';
import { ViewConfiguratorModalProps } from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import { GranularEditPayloadItem } from 'src/dao/pivotClient';

import { create, all } from 'mathjs';
import * as globalMath from 'mathjs';
const noImage = resolvePath(noImagePath);

export class FlowSheetByStyle extends React.Component<FlowSheetByStyleProps, State> {
  protected fsGrid!: FlowSheetGrid | null;
  math = create(all) as globalMath.MathJsStatic;

  constructor(props: FlowSheetByStyleProps) {
    super(props);

    this.state = {
      companionCollapsed: false,
      companionSortDirection: 'desc',
      configureIsOpen: false,
      companionData: [],
      uniqueItems: props.dataLoaded ? props.uniqueItems : [],
    };

    this.onCompanionItemClick = this.onCompanionItemClick.bind(this);
    this.onCompanionDirectionChange = this.onCompanionDirectionChange.bind(this);
    this.onCompanionSortChange = this.onCompanionSortChange.bind(this);
    this.onCompanionCollapseToggle = this.onCompanionCollapseToggle.bind(this);
    this.onGridValueChange = this.onGridValueChange.bind(this);
  }

  static getDerivedStateFromProps(newProps: FlowSheetByStyleProps, currentState: State): State {
    const {
      companionSortDirection,
      companionSortField = newProps.dataLoaded ? newProps.defaultCompanionSortField.dataIndex : '',
    } = currentState;

    let favoriteSortField, favoriteSortDirection: SortByDirection | undefined, favoriteCompIsCollapsed;
    if (newProps.dataLoaded) {
      const { uniqueItems, companionDataLookup, defaultCompanionSortField } = newProps;

      const activeFavorite = newProps.favoritesList && newProps.favoritesList.find((fav) => fav.active === true);
      if (activeFavorite && activeFavorite.jsonBlob && activeFavorite.jsonBlob.companionData) {
        const {
          companionCollapsed,
          companionSortDirection,
          companionSortField,
        } = activeFavorite.jsonBlob.companionData;
        favoriteSortField = companionSortField;
        favoriteSortDirection = companionSortDirection;
        favoriteCompIsCollapsed = companionCollapsed;
      }

      if (
        !isEqual(uniqueItems, currentState.uniqueItems) ||
        activeFavorite ||
        currentState.isDefault ||
        currentState.isCompanionChangeOnDefault
      ) {
        const lookup = companionDataLookup;
        const items = uniqueItems;
        const direction = favoriteSortDirection ? favoriteSortDirection : companionSortDirection;
        // noinspection UnnecessaryLocalVariableJS
        const field = favoriteSortField
          ? favoriteSortField
          : companionSortField
          ? companionSortField
          : defaultCompanionSortField.dataIndex;
        const companionData = companionDataParse(items, lookup, direction, field);

        let getMassEditData = false;
        if (
          (uniqueItems && !currentState.uniqueItems) ||
          (uniqueItems && currentState.uniqueItems && !isEqual(uniqueItems.sort(), currentState.uniqueItems.sort()))
        ) {
          getMassEditData = true;
        }
        if (getMassEditData && newProps.getMassEditData && companionData) {
          newProps.getMassEditData();
        }
        return {
          ...currentState,
          companionData,
          companionSortField: field,
          uniqueItems,
          companionCollapsed: favoriteCompIsCollapsed ? favoriteCompIsCollapsed : currentState.companionCollapsed,
          companionSortDirection: direction,
        };
      }
    }
    return {
      ...currentState,
      companionSortField,
      uniqueItems: [],
    };
  }

  componentDidMount() {
    this.props.onShowView();
  }

  componentDidUpdate(prevProps: FlowSheetByStyleProps, prevState: State) {
    const { selectedId } = this.props;
    if (isEmpty(selectedId) && !isEmpty(this.state.companionData) && !isUndefined(this.state.companionData[0].id)) {
      const selectedItem = this.state.companionData[0].id;
      this.onCompanionItemClick(selectedItem);
    }
    if (!isEqual(prevProps, this.props) && !isEqual(prevState, this.state)) {
      this.isCompanionChange(prevState);
    }
  }

  isCompanionChange(prevState: State) {
    if (this.props.dataLoaded) {
      const defaultCompanion = {
        companionSortField: this.props.defaultCompanionSortField.dataIndex,
        companionSortDirection: 'desc',
        companionCollapsed: false,
      };
      if (
        defaultCompanion.companionCollapsed == this.state.companionCollapsed ||
        defaultCompanion.companionSortDirection == this.state.companionSortDirection ||
        defaultCompanion.companionSortField == this.state.companionSortField
      ) {
        this.setState({
          isCompanionChangeOnDefault: false,
          isDefault: true,
        });
      } else if (!isEqual(prevState.companionData, this.state.companionData)) {
        this.setState({
          isCompanionChangeOnDefault: true,
          isDefault: false,
        });
      }
      if (!isEqual(prevState.companionData, this.state.companionData)) {
        if (isEmpty(this.state.companionData)) {
          this.onCompanionItemClick('');
        } else {
          const selectedItem = this.state.companionData[0].id;
          this.onCompanionItemClick(selectedItem);
        }
      }
    }
  }
  updateConfigureSelections(selections: Option[]) {
    if (this.props.updateConfigureSelections) {
      this.props.updateConfigureSelections(selections);
    }
  }

  componentWillUnmount() {
    if (this.props.onDestroy) {
      this.props.onDestroy();
    }
  }

  rebuildCompanionData() {
    if (!this.props.dataLoaded) {
      return;
    }
    const companionData = companionDataParse(
      this.state.uniqueItems,
      this.props.companionDataLookup,
      this.state.companionSortDirection,
      this.state.companionSortField
    );
    this.setState({
      companionData,
    });
  }

  onFabClick = () => {
    switch (this.props.fab?.fabType) {
      case FabType.planning:
        this.props.updateAssortmentPlan();
        break;
      default:
        break;
    }
  };

  /*  Start Companion List Events */

  onCompanionItemClick(itemId: string) {
    if (this.props.onCompanionItemClick) {
      this.props.onCompanionItemClick(itemId);
      this.setState({
        selectedItem: itemId,
      });
    }
  }

  onCompanionDirectionChange(newDir: SortByDirection) {
    this.setState({ companionSortDirection: newDir }, () => {
      this.rebuildCompanionData();
    });
  }

  onCompanionSortChange(option: SortOption) {
    this.setState({ companionSortField: option.dataIndex, isDefault: false }, () => {
      this.rebuildCompanionData();
    });
  }

  onCompanionCollapseToggle(collapse: boolean) {
    this.setState({ companionCollapsed: collapse, isDefault: false });
  }

  /* End Companion List Events */

  onGridValueChange(payload: GranularEditPayloadItem[]) {
    this.props.submitPayload(payload);
  }

  handleMassEditSubmission = async (submission: MassEditSubmissionData) => {
    const { selectedModifier, selectedItems, selectedWeekList, modifierValue } = submission;
    const viewDefns = this.props.viewDefns;
    if (!isNil(selectedModifier) && !isNil(selectedWeekList) && !isNil(viewDefns) && !isNil(viewDefns.massEdit)) {
      // no null submission data expected, except for modifier value
      const massEditConf = viewDefns.massEdit;
      const configViewItems = MassEdit.getConfigViewItems(massEditConf);
      const selectedModConf = configViewItems[0].modifierTypes.find(
        (modType) => modType.dataIndex === selectedModifier
      );
      const dataPiv = await serviceContainer.pivotService.getFlowSheetByIndex(
        selectedItems.map((i) => i.value),
        selectedWeekList[0],
        selectedWeekList[selectedWeekList.length - 1]
      );
      const data = dataPiv.tree;
      const filteredItems = filter(data, (item) => {
        return findIndex(selectedItems, ['value', item.id]) >= 0 && indexOf(selectedWeekList, item.time) >= 0;
      });
      const updatedWeekData = map(filteredItems, (item) => {
        if (selectedModConf && selectedModConf.calculation) {
          const newValue = isNil(modifierValue)
            ? null
            : executeCalculation(this.math, selectedModConf.calculation, (key) => {
                return {
                  rowNodeFound: true,
                  data: key === '$editValue' ? modifierValue : get(item, key, null),
                };
              });
          const coordinates = {
            product: item.id,
            time: item.time,
            location: item.channel,
          };
          return {
            coordinates,
            [selectedModifier]: newValue,
          };
        } else {
          const coordinates = {
            product: item.id,
            time: item.time,
            location: item.channel,
          };
          return {
            coordinates,
            [selectedModifier]: modifierValue,
          };
        }
      });
      if (this.props.submitPayload) {
        await this.props.submitPayload(updatedWeekData);
      }

      // update grid data
      // clear state so that it can come back from props in componentDidUpdate
      this.setState({
        companionData: [],
      });
      if (!isNil(this.props.onRefetchOvertimeData)) {
        this.props.onRefetchOvertimeData();
      }
    }
  };

  getEditableItems = (): (MassEditSelectItemData & { time: string; weekadjslsu: number })[] | undefined => {
    const { viewDefns, massEditData } = this.props;
    const { massEdit: massEditConfig } = viewDefns;
    const configViewItems = MassEdit.getConfigViewItems(massEditConfig);
    const itemsDataIndex = !isEmpty(configViewItems) ? configViewItems[1].dataIndex : '';
    const editableItems =
      massEditData &&
      massEditData.tree.map((dataItem) => {
        const label = `${dataItem['name']}-${dataItem[itemsDataIndex]}`;
        const weekadjslsu = dataItem['a_unc_sls_u'];
        return {
          value: dataItem['id'],
          label,
          weekadjslsu,
          time: dataItem['time'],
        };
      });

    return editableItems;
  };
  onUpdateConfig = (config: TenantConfigViewData) => {
    if (this.props.configLoaded && this.state.isDefault && config.isDefault) {
      this.setState({
        companionSortField: this.props.defaultCompanionSortField.dataIndex,
        companionSortDirection: 'desc',
        companionCollapsed: false,
        isDefault: true,
      });
    }
    this.props.onUpdateConfig(config);
  };

  generateExtraActionButtonProps(): LegacySubheaderActionButtonProps {
    const { massEditDataLoaded, allowFrom, allowTo, rangeList, scopeStart, viewDefns } = this.props;
    const { massEdit: massEditConfig } = viewDefns;
    const massEdit = isNil(massEditConfig)
      ? undefined
      : {
          config: massEditConfig,
          allowFrom,
          allowTo,
          scopeStart: scopeStart || '',
          rangeList,
          editableItems: uniqBy(this.getEditableItems(), 'value'),
          title: 'Mass Copy Flowsheet Data',
          handleSubmit: this.handleMassEditSubmission,
          dataLoading: !massEditDataLoaded,
        };

    return {
      massEdit,
    };
  }

  render() {
    const {
      title,
      configLoaded,
      dataLoaded,
      overTimeDataLoaded,
      liveDataReady,
      isPrintMode,
      selectedId,
      columnWidth,
      viewDataStateCompanion,
      viewDataStateMassEdit,
      viewDataStateOverTime,
      showFlowStatus,
      onItemClicked,
      rowHeight,
      groupRowHeight,
      showUndoBtn,
    } = this.props;

    const sortOptions = dataLoaded ? this.props.sortOptions : [];
    const companionDataLookup = dataLoaded ? this.props.companionDataLookup : undefined;
    const companionData = this.state.companionData;
    const defaultSort = this.state.companionSortField;
    const defaultSelection = sortOptions.findIndex((option) => option.dataIndex === defaultSort);
    const selectedIndex = companionData.findIndex((item) => item.id === selectedId);

    const companionProps: CompanionProps = {
      defaultSelection: defaultSelection,
      sortOptions,
      label: 'Count',
      selectedIndex: selectedIndex,
      className: companionStyles,
      data: companionData,
      isDataloaded: this.props.dataLoaded,
      noImageUrl: noImage,
      dataLookup: companionDataLookup,
      initialSortDirection: this.state.companionSortDirection,
      onListItemClicked: this.onCompanionItemClick,
      onChangeDirection: this.onCompanionDirectionChange,
      onSortSelection: this.onCompanionSortChange,
      onToggleCollapse: this.onCompanionCollapseToggle,
      isCollapsed: this.state.companionCollapsed,
    };

    const flowSheetGridProps: FlowSheetGridProps = {
      gridDefn: this.props.viewDefns.grid,
      scopeTimeEntries: this.props.timeInfo.entries,
      overTimeData: this.props.overTimeData,
      rowHeight: this.props.dataLoaded ? rowHeight : 30,
      anchorField: this.props.scopeStart || this.props.timeInfo.planCurrent,
      editable: liveDataReady,
      onValueChange: this.onGridValueChange,
      onItemClicked,
      updateAssortmentPlan: this.props.updateAssortmentPlan,
      groupRowHeight,
      columnWidth: columnWidth,
      loaded: overTimeDataLoaded,
      adornments: this.props.adornments,
    };

    const errorCondition = [ASSORTMENT_BUILD_FILTER_WARNING];
    if (!liveDataReady) {
      errorCondition.push(CACHED_DATA_EDITING_DISABLED_WARNING);
    }

    const viewConfigurator: ViewConfiguratorModalProps | undefined =
      configLoaded && this.props.configuratorViewDefn && this.props.unmodifiedViewDefn
        ? {
            viewConfig: this.props.configuratorViewDefn,
            unmodifiedViewDefn: this.props.unmodifiedViewDefn,
            updateConfig: this.onUpdateConfig,
            showPinCheckboxForGrid: false,
            companionData: {
              companionSortDirection: this.state.companionSortDirection,
              companionCollapsed: this.state.companionCollapsed,
              companionSortField: this.state.companionSortField,
            },
            defaultCompanionData: {
              companionSortDirection: 'desc',
              companionCollapsed: false,
              companionSortField: this.props.defaultCompanionSortField.dataIndex,
            },
          }
        : undefined;

    return (
      <div className={listPairStyle}>
        <Overlay type="loading" visible={!configLoaded} />
        <Subheader
          title={title || ''}
          errorCondition={errorCondition}
          showSearch={true}
          showFlowStatus={showFlowStatus}
          showUndoBtn={showUndoBtn}
          summary={this.props.dataLoaded ? this.props.subheaderSummary : undefined}
          viewConfigurator={viewConfigurator}
          viewDataState={[viewDataStateCompanion, viewDataStateOverTime, viewDataStateMassEdit]}
          extraLegacyActionButtons={this.generateExtraActionButtonProps()}
        />
        <div className="data-container">
          {!isPrintMode && <CompanionListView {...companionProps} />}
          <FlowSheetGrid ref={(el) => (this.fsGrid = el)} {...flowSheetGridProps} />
        </div>
      </div>
    );
  }
}

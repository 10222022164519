import * as React from 'react';
import { identity } from 'lodash';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import { wrappedDispatch, WrappedDispatch } from 'src/utils/Redux/Dispatch';
import { addCrumb } from 'src/state/breadcrumbs/Bread.slice';
import { removeLastCrumb, resetCrumbs } from 'src/state/breadcrumbs/Bread.slice';
import { NavPath } from 'src/pages/NavigationShell/navigationUtils';

export interface InjectedProps<ParentProps> {
  parentProps: ParentProps;
  addCrumb(keyAndCrumb: [string, NavPath[]]): void;
  removeLastCrumb(key: string): void;
  resetCrumbs(key: string): void;
}

export interface WithBreadCrumbProps
  extends Pick<InjectedProps<unknown>, 'addCrumb' | 'removeLastCrumb' | 'resetCrumbs'> { }

export interface Nameable {
  displayName?: string;
  name?: string;
}

function mergeProps<P>(state: AppState, wrapped: WrappedDispatch, parentProps: P): InjectedProps<P> {
  return {
    parentProps,
    addCrumb(keyAndCrumb: [string, NavPath[]]) {
      wrapped.dispatch(addCrumb(keyAndCrumb));
    },
    removeLastCrumb: (key: string) => wrapped.dispatch(removeLastCrumb(key)),
    resetCrumbs: (key: string) => wrapped.dispatch(resetCrumbs(key)),
  };
}

export function makeBreadcrumbSensitive<P>(WrappedComponent: React.ComponentType<P>) {
  class BreadcrumbSensitive extends React.Component<InjectedProps<P>> {
    displayName = 'BreadcrumbSensitive';
    constructor(props: InjectedProps<P>) {
      super(props);
    }

    render() {
      return (
        <WrappedComponent
          addCrumb={this.props.addCrumb}
          removeLastCrumb={this.props.removeLastCrumb}
          resetCrumbs={this.props.resetCrumbs}
          {...this.props.parentProps}
        />
      );
    }
  }

  return connect<AppState, WrappedDispatch, P, InjectedProps<P>>(
    identity,
    wrappedDispatch,
    // @ts-ignore
    mergeProps
  )(BreadcrumbSensitive);
}

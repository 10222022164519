import React, { Component } from 'react';
import { noop } from 'lodash';

import container from 'src/ServiceContainer';
import Subheader from 'src/components/Subheader/Subheader';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import { formatGroupBySelection, formatSortBySelection } from './ViewSimilarItems.utils';
import { ViewSimilarItemsSubheaderProps } from './ViewSimilarItems.types';
import { ViewDataState } from 'src/types/Domain';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';

export class ViewSimilarItemsSubheader extends Component<ViewSimilarItemsSubheaderProps> {
  constructor(props: ViewSimilarItemsSubheaderProps) {
    super(props);
  }

  getViewDefns = async () => {
    const { viewDefns, subheaderSlice, onSubheaderChange } = this.props;

    const configResponse = await container.tenantConfigClient.getTenantViewDefns({
      defnIds: viewDefns,
      appName: ASSORTMENT,
    });
    const [groupByDefn, sortByDefn] = configResponse;

    const { groupBy, sortBy } = subheaderSlice;
    const formattedGroupBy = formatGroupBySelection(groupByDefn, groupBy);
    const formattedSortBy = formatSortBySelection(sortByDefn, sortBy);

    onSubheaderChange({
      groupByDefn,
      sortByDefn,
      groupBy: formattedGroupBy,
      sortBy: formattedSortBy,
    });
  };

  handleSearchChange = (search: string) => {
    this.props.onSubheaderChange({ search });
  };

  handleGroupByChange = (selection: number) => {
    const updatedGroupBy = {
      ...this.props.subheaderSlice.groupBy,
      selection,
    };
    this.props.onSubheaderChange({ groupBy: updatedGroupBy });
  };

  handleSortByChange = (selection: number) => {
    const updatedSortBy = {
      ...this.props.subheaderSlice.sortBy,
      selection,
    };
    this.props.onSubheaderChange({ sortBy: updatedSortBy });
  };

  handleSortByDirectionChange = () => {
    const currentDirection = this.props.subheaderSlice.sortBy.direction;
    const direction: SortByDirection = currentDirection === 'asc' ? 'desc' : 'asc';

    const updatedSortBy = {
      ...this.props.subheaderSlice.sortBy,
      direction,
    };
    this.props.onSubheaderChange({ sortBy: updatedSortBy });
  };

  render() {
    const { viewDefns, subheaderSlice } = this.props;
    const [groupByDefnString, sortByDefnString] = viewDefns;
    return (
      // @ts-ignore review theses type if we ever use this again, some new types are missing
      <Subheader
        title={''}
        groupByDefn={groupByDefnString}
        sortByDefn={sortByDefnString}
        getViewDefns={this.getViewDefns}
        showSearch={true}
        updateSearchString={this.handleSearchChange}
        setGroupBySelection={this.handleGroupByChange}
        setSortBySelection={this.handleSortByChange}
        setSortByDirection={this.handleSortByDirectionChange}
        subheader={{ ...subheaderSlice }}
        // following props aren't necessary for this view but here to compile
        updateAltSearchString={noop}
        updateFlowStatus={noop}
        updateAltFlowStatus={noop}
        updateLookBackPeriod={noop}
        setCountLimit={noop}
        getFavoritesList={noop}
        setFavoritesList={noop}
        getFavorites={noop}
        setFavorites={noop}
        setPareDownSelections={noop}
        onDestroy={noop}
        lookBackPeriods={[]}
        flowStatusOptions={{
          values: [],
          defaultSelection: [],
        }}
        viewDataState={ViewDataState.idle}
        gridFilterModel={{}}
        gridSortModel={[]}
        gridColumnState={[]}
        resetGridModel={noop}
        showUndoBtn={false}
        onOpenUndoPanel={noop}
        activeBreadcrumb={null}
        activeCrumbPath={null}
      />
    );
  }
}

import * as React from 'react';
import { noop } from 'lodash';
import { isNil } from 'lodash/fp';

import { Filters, Overlay } from 'src/common-ui';
import { SelectionOverride } from 'src/common-ui/components/Filters/Filters';

import { Filter } from 'src/types/Scope';
import { Props } from './FilterPanel.container';
import { toast } from 'react-toastify';

type State = {
  triggerFuse: boolean;
};

export default class FilterPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      triggerFuse: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.containsInvalid !== this.props.containsInvalid && this.props.containsInvalid) {
      toast.info('Invalid filters have been removed, and data is being refreshed.', {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  private onFilterToggled = (updatedFilter: SelectionOverride): void => {
    this.props.updateSelectionOverrides([updatedFilter]);
  };

  onRangeFiltersUpdated = (id: string, labels: string[]) => {
    const rangeSelectionOverrides: SelectionOverride[] = labels.map((l) => ({
      id: l,
      filterDefnId: id,
      value: true,
      selected: true,
    }));

    this.props.overwriteSelectionOverridesByDefnId(id, rangeSelectionOverrides);
  };

  // Clear filters by queueing an override for everything
  onFiltersCleared = (): void => {
    if (!this.props.isFlushing) {
      this.props.clearAllSelections();
      this.props.submitSelectionOverrides();
    }
  };

  onFilterTabCleared = (tab: number): void => {
    if (!this.props.isFlushing && !isNil(tab)) {
      const tabToBeCleared = this.props.state[tab];
      this.props.clearTabSelections(tabToBeCleared.id);
      this.props.getAvailableFilterSelections('');
    }
  };

  onFilterSectionCleared = (sectionId: string, isOpen: boolean) => {
    this.props.clearSectionSelections(sectionId);

    if (isOpen) {
      this.props.getAvailableFilterSelections(sectionId);
    }
  };

  onFilterSubmit = () => {
    this.props.submitSelectionOverrides();
  };

  onToggleSection = (filter: Filter) => {
    if (filter.inputType !== 'rangepicker') {
      this.props.getAvailableFilterSelections(filter.id);
    }
  };

  render() {
    return (
      <div style={{ padding: '10px 0 0 20px' }}>
        <Overlay type="loading" visible={this.props.isFlushing || this.props.isLoading} />
        <Filters
          data={this.props.state}
          lockedNavigation={this.state.triggerFuse}
          onViewUpdate={noop}
          selectionOverrides={this.props.selectionOverrides}
          selectionsChanged={this.props.selectionsChanged}
          hasFiltersSelected={this.props.lastSelections.length > 0}
          onRangeFiltersUpdated={this.onRangeFiltersUpdated}
          onFilterToggled={this.onFilterToggled}
          onFiltersCleared={this.onFiltersCleared}
          onFilterTabCleared={this.onFilterTabCleared}
          onFilterSectionCleared={this.onFilterSectionCleared}
          onFiltersSubmit={this.onFilterSubmit}
          onToggleSection={this.onToggleSection}
          allowFrom={this.props.allowFrom}
          allowTo={this.props.allowTo}
          daysRangeList={this.props.daysRangeList}
          scopeStartMonth={this.props.scopeStartMonth}
        />
      </div>
    );
  }
}

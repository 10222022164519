import { extractNavPathFromString, NavPath } from 'src/pages/NavigationShell/navigationUtils';
import { isEqual } from 'lodash';
import store from 'src/store';
import { isSome } from 'fp-ts/es6/Option';

export const getViewNameFromPath = (path: NavPath): string | undefined => {
  const viewLinks = store.getState().perspective.viewLinks;
  return viewLinks.find((v) => {
    const viewLinkNavPath = extractNavPathFromString(v.link);
    return isSome(viewLinkNavPath) && isEqual(path, viewLinkNavPath.value);
  })?.name;
};

export const getCrumbPathFromActiveCrumb = (
  activeCrumbPath: string,
  crumbPaths: Record<string, NavPath[]>
): NavPath[] => {
  return crumbPaths[activeCrumbPath];
};

import { useCallback } from 'react';
import { ColumnApi, GridApi, GridReadyEvent, ValueFormatterParams } from '@ag-grid-community/core';
import { DropdownProps, InputOnChangeData } from 'semantic-ui-react';
import { PlansFilters } from 'src/services/Admin.service';
import dayjs, { Dayjs } from 'dayjs';
import { get } from 'lodash';

export const useOnRowDataChanged = (columnApi: ColumnApi | undefined) =>
  useCallback(() => {
    if (columnApi && columnApi.getColumns()) {
      const allColumnIds = columnApi.getColumns()!.map((column) => column.getColId());
      columnApi.autoSizeColumns(allColumnIds);
    }
  }, [columnApi]);

export const useHandleQuickFilterChange = (gridApi: GridApi | undefined) =>
  useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const value = data.value;
      if (gridApi) {
        gridApi.setQuickFilter(value);
      }
    },
    [gridApi]
  );

export const useHandleGroupByChange = (setSelectedScopeGroupBy: React.Dispatch<React.SetStateAction<string>>) =>
  useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      if (data && typeof data.value === 'string') {
        setSelectedScopeGroupBy(data.value);
      }
    },
    [setSelectedScopeGroupBy]
  );

export const useHandleChangeFilters = (
  setSelectedTime: (value: React.SetStateAction<string | undefined>) => void,
  setFilters: (newFilters: PlansFilters) => void,
  extraFilters?: PlansFilters
) =>
  useCallback(
    (_event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      if (data && typeof data.value === 'string') {
        setSelectedTime(data.value);
        // move this to a useEffect?
        setFilters({
          ...extraFilters,
          time: [data.value],
        });
      }
    },
    [setSelectedTime, setFilters, extraFilters]
  );

export const useHandleGridReady = (
  gridApiRef: React.MutableRefObject<GridApi | null>,
  columnApiRef: React.MutableRefObject<ColumnApi | null>
) =>
  useCallback(
    (params: GridReadyEvent) => {
      gridApiRef.current = params.api;
      columnApiRef.current = params.columnApi;
    },
    [columnApiRef, gridApiRef]
  );

export const dayJsGridFilterComparator = (filterLocalDateAtMidnight: Date, cellValue: Dayjs) => {
  const filterDay = dayjs(filterLocalDateAtMidnight);

  if (!cellValue.isValid()) {
    return 0;
  }

  // Now that both parameters are dayJs objects, we can compare
  if (cellValue.unix() < filterDay.unix()) {
    return -1;
  } else if (cellValue.unix() > filterDay.unix()) {
    return 1;
  }
  return 0;
};

export const dayJsGridSortComparator = (leftDate?: Dayjs, rightDate?: Dayjs) => {
  return leftDate && rightDate ? rightDate.unix() - leftDate.unix() : -1;
};

export const versionLabelFormatter = (params: ValueFormatterParams) => {
  const maybeDisplayValue =
    get(params.context.entriesByKey, `revision.${params.value}.display`)?.value || params.value.toLocaleUpperCase();
  return params.value && typeof params.value === 'string' && maybeDisplayValue ? maybeDisplayValue : ' ';
};

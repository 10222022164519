import { style } from 'typestyle';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
export const tableStyle = style({
  $debugName: 'ssg-table-editor',
  $nest: {
    th: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
});
export const styles = {
  select: style({
    width: '100%',
  }),
  selected: style({
    backgroundColor: TEAL_PRIMARY,
  }),
  unselected: style({
    backgroundColor: 'white',
  }),
  removeAllButton: style({
    color: '#00a69c',
    cursor: 'pointer',
    $nest: {
      '&.disabled': {
        cursor: 'not-allowed',
        color: '#bcbcbc',
      },
    },
  }),
};
export const selectedTextContainer = style({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
  paddingTop: '1rem',
});
export const tableContentContainer = style({
  height: 375,
  width: 300,
  overflowY: 'auto',
  marginTop: '0.5rem',
});
export const searchInputContainerStyle = style({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '5px 10px',
  width: '100%',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  $nest: {
    input: {
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      color: '#495057',
      backgroundColor: '#fff',
      borderColor: '#29ffee',
      outline: 0,
      boxShadow: '0 0 0 0.2rem rgba(0, 168, 155, 0.25)',
    },
    '.search-input': {
      border: 'none',
      outline: 'none',
      width: '100%',
      padding: '5px',
      fontSize: '14px',
    },
    '.select-all-btn': {
      padding: 0,
      background: 'none',
      border: 'none',
      color: '#00a69c',
      fontSize: '12px',
      cursor: 'pointer',
      $nest: {
        '&:disabled': {
          color: '#bcbcbc',
          cursor: 'not-allowed',
        },
      },
    },
    '.search-input-wrapper:focus-within': {
      borderColor: '#00a69c',
    },
  },
});

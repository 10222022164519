import { ofType } from 'redux-observable';
import { mergeMap, map, filter } from 'rxjs';
import { AppEpic } from 'src/store';
import { of } from 'rxjs';
import { updateFlowStatus, updateGroupBy, receiveViewDefns } from 'src/components/Subheader/Subheader.slice';
import { fetchCategorySummaryData } from './CategorySummary.slice';
import { inputIsNotNullOrUndefined, isScopeDefined, isSubheaderLoaded, topMemInWorklistSelected } from 'src/utils/Functions/epicsFunctions';
import {
  ConfDefnComponentType,
  CategorySummaryComponent,
  maybeGetComponentProps,
} from 'src/services/configuration/codecs/confdefnComponents';
import {
  ExtraPivotOptions,
  getAggBys,
  getConfigureModalAggBys,
  organizeListDataOptions,
} from 'src/pages/Hindsighting/StyleColorReview/StyleColorReview.slice';
import { DataApi } from 'src/services/configuration/codecs/confdefnView';
import { LEVEL_PROD_ROOT } from 'src/utils/Domain/Constants';
import { receiveScopeRefreshTrigger } from 'src/components/AssortmentScopeSelector/AssortmentScopeSelector.slice';
import { receiveFilterStateAfterSubmission } from 'src/components/FilterPanel/FilterPanel.slice';
import { ViewDefnState } from 'src/dao/tenantConfigClient';
import { updateSelectedItem } from 'src/pages/Worklist/Worklist.slice';

export const categorySummaryLoad: AppEpic = (action$, state$) => {
  return action$.pipe(
    ofType(
      receiveScopeRefreshTrigger.type,
      receiveFilterStateAfterSubmission.type,
      receiveViewDefns.type,
      updateFlowStatus.type,
      updateGroupBy.type,
      updateSelectedItem.type
    ),
    map(() => maybeGetComponentProps<CategorySummaryComponent>(state$.value, ConfDefnComponentType.categorySummary)),
    filter(inputIsNotNullOrUndefined),
    filter(() => isScopeDefined(state$.value.scope) && isSubheaderLoaded(state$.value.subheader)),
    filter(() => state$.value.pages.hindsighting.categoryRecap.categorySummary.viewDefnState === ViewDefnState.loaded),
    filter(() => topMemInWorklistSelected(state$.value)),
    mergeMap(({ defns, topMembers }) => {
      const flowStatus = state$.value.subheader.flowStatus.join(',');

      // TODO: move this to config when ready
      const dataApi: DataApi = {
        defnId: defns.model,
        isListData: true,
        params: {
          aggBy: '',
          topAggBy: LEVEL_PROD_ROOT,
          topMembers,
        },
      };

      // need to get current groupBy selection from subheader (minus the default bottom level)
      // and apply a topAggBy from the DataApi config.
      // to be clear there is no configure modal on this screen, just using this for the topAggBy logic
      const currentAggBy = getAggBys(state$.value.subheader, dataApi, {
        includeBottomLevel: false,
        forceReload: true,
        groupingKeyOverride: 'dataIndex',
      });
      const finalAggBy = getConfigureModalAggBys([currentAggBy], dataApi);

      const options: ExtraPivotOptions = { flowStatus, aggBy: finalAggBy };
      const finalOptions = organizeListDataOptions(options, dataApi);

      return of(fetchCategorySummaryData(defns.model, finalOptions));
    })
  );
};

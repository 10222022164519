import * as React from 'react';
import * as lodash from 'lodash';
import * as typestyle from 'typestyle';
import { SectionProps, FilterValue } from './Filters.interfaces';
import FilterSectionItem from './FilterSectionItem';
import styles from './Filters.styles';
import { flattenDepth, trim, uniq } from 'lodash';
import { SortByDirection } from 'src/components/Subheader/Subheader.types';
import FiltersWeekRangePicker from 'src/common-ui/components/WeekRange/FiltersWeekRangePicker';
import { SelectionOverride } from './Filters';

export interface State {
  query: string;
  orderBy: SortByDirection;
}

export default class FilterSection extends React.PureComponent<SectionProps, State> {
  state: State = {
    query: '',
    orderBy: 'asc',
  };

  rangePickerRef: React.RefObject<FiltersWeekRangePicker>;

  constructor(props: SectionProps) {
    super(props);
    this.rangePickerRef = React.createRef();
  }

  toggleOpen = () => {
    const { onToggleSection, id } = this.props;
    onToggleSection(id);
  };

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ query: event.currentTarget.value });
  };

  toggleFilter = (itemData: SelectionOverride) => {
    this.props.onToggleItem(itemData);
  };

  setRangeFilters = (labels: string[]) => {
    const { id, onRangeFiltersUpdated } = this.props;
    if (onRangeFiltersUpdated) {
      onRangeFiltersUpdated(id, labels);
    }
  };

  toggleOrderBy = () => {
    const { orderBy } = this.state;
    if (orderBy === 'asc') {
      this.setState({ orderBy: 'desc' });
    } else {
      this.setState({ orderBy: 'asc' });
    }
  };

  hasSelections = (): boolean => {
    return !!this.props.selectionOverrides.find(
      (value) => value.value === true && value.filterDefnId === this.props.id
    );
  };

  clearSectionFilters = (event: React.MouseEvent<HTMLElement>) => {
    const { id, inputType, open, onClearSection } = this.props;

    if (inputType === 'rangepicker') {
      // restore initial state in range picker component,
      // also resetting optionsCache to inital state
      this.rangePickerRef.current?.setInitialSelections({ resetOptionsCache: true });
    }

    onClearSection(id, open);
    event.stopPropagation();
  };

  getSearchResults = () => {
    // TODO: matchFunc is never provided, case can be removed
    const { filterValues, matchFunc } = this.props;
    const { query } = this.state;
    let searchString;
    if (query.includes(';')) {
      searchString = query.split(';').filter(trim);
    }

    let searchResults;
    if (!query) {
      searchResults = filterValues;
    } else {
      if (matchFunc) {
        searchResults = filterValues.filter((value) => matchFunc(value.id.toLowerCase(), query.trim().toLowerCase()));
      } else {
        searchResults = searchString
          ? uniq(
              flattenDepth(
                searchString.map((x) =>
                  filterValues.filter((value) => value.id.toLowerCase().indexOf(x.trim().toLowerCase()) > -1)
                )
              )
            )
          : filterValues.filter((value) => value.id.toLowerCase().indexOf(query.trim().toLowerCase()) > -1);
      }
    }

    return searchResults;
  };

  toggleAllFilters = () => {
    const filterValues = this.getSearchResults();
    const toggleTo = !this.areAllSelected();
    // TODO: handle in redux not in component,
    // passing up searchString from `getSearchResults`, `toggleTo`,
    // and moving filter logic from `getSearchResults` to redux
    filterValues.forEach(({ disabled, filterDefnId, id }) => {
      if (!disabled) {
        const itemData = { id, filterDefnId, value: toggleTo };
        this.props.onToggleItem(itemData);
      }
    });
  };

  areAllSelected = () => {
    const filterValues = this.getSearchResults();
    return filterValues.every((value) => value.disabled || !!value.selected);
  };

  renderSectionContent = (sortedResults: FilterValue[], orderByClassName: string) => {
    const {
      id,
      filterValues,
      inputType,
      allowFrom,
      allowTo,
      daysRangeList,
      scopeStartMonth,
      selectionOverrides,
    } = this.props;
    const { query } = this.state;

    switch (inputType) {
      case 'multiselect':
        return (
          <React.Fragment>
            <div
              className={typestyle.classes(styles.FlexAroundContainer, styles.clickableArea)}
              data-qa="filter-search-container"
            >
              {filterValues && filterValues.length > 0 ? (
                <React.Fragment>
                  <div className="search">
                    <i className="far fa-search" />
                    <input
                      className={styles.searchInput}
                      type="text"
                      value={query}
                      onChange={this.handleChange}
                      placeholder="Search this filter"
                    />
                    <span className="select-all" onClick={this.toggleAllFilters}>
                      {this.areAllSelected() ? 'Remove All' : 'Select All'}
                    </span>
                  </div>
                  <i className={orderByClassName} onClick={this.toggleOrderBy} />
                </React.Fragment>
              ) : (
                <div className="empty">No values to display</div>
              )}
            </div>
            <div className="pt-2" data-qa="filter-items-container">
              {sortedResults &&
                sortedResults.map((filterValue, index) => {
                  const override = selectionOverrides.find(
                    (o) => o.id === filterValue.id && o.filterDefnId === filterValue.filterDefnId
                  );
                  const selected = override === undefined ? Boolean(filterValue.selected) : override.value;
                  const selectedOverride = {
                    ...filterValue,
                    selected,
                  };
                  return (
                    <FilterSectionItem
                      key={`filter_item_${index}`}
                      {...selectedOverride}
                      onToggleFilter={this.toggleFilter}
                    />
                  );
                })}
            </div>
          </React.Fragment>
        );
      case 'rangepicker':
        const selectedOverrides = selectionOverrides.filter((o) => o.filterDefnId === id && o.value === true);
        const [start, end] = selectedOverrides;

        return (
          <div className="picker" style={{ padding: '0.5rem 0 1.5rem 0' }} data-qa="datepicker-assortmentperiod">
            <FiltersWeekRangePicker
              ref={this.rangePickerRef}
              autoSelect={true}
              allowFrom={allowFrom}
              allowTo={allowTo}
              dateToNameMap={daysRangeList}
              initialSelectedFrom={start?.id}
              initialSelectedTo={end?.id ?? start?.id}
              selectionOptions={[{ label: 'Start', selected: true }, { label: 'End' }]}
              startMonth={scopeStartMonth ?? undefined}
              onNewWeeksSelectedMulti={(range) => {
                const labels = range.map((r) => r.weekNo ?? '');
                this.setRangeFilters(labels);
              }}
            />
          </div>
        );
      default:
        return 'Unknown inputType';
    }
  };

  render() {
    const { name, open } = this.props;
    const { orderBy } = this.state;

    const searchResults = this.getSearchResults();
    const sortedResults = lodash.orderBy(searchResults, (result: FilterValue) => result.id, orderBy);

    let arrowDirection = 'fa-arrow-up';
    if (orderBy === 'desc') {
      arrowDirection = 'fa-arrow-down';
    }

    let toggleArrowDirection = 'fa-chevron-up';
    if (!open) {
      toggleArrowDirection = 'fa-chevron-down';
    }

    const orderByClassName = `sort-dir far ${arrowDirection} d-flex align-self-center`;
    const toggleSectionClassName = `expander far ${toggleArrowDirection}`;

    return (
      <div
        className="filter-section"
        // style={{ overflow: this.props.inputType === 'rangepicker' ? 'initial' : 'hidden' }}
      >
        <div className={typestyle.classes(styles.FlexAroundContainer, styles.clickableArea)} onClick={this.toggleOpen}>
          <p className="section-title">{name}</p>
          <div className={styles.sectionControls}>
            {this.hasSelections() && <i className="far fa-times" onClick={this.clearSectionFilters} />}
            <i className={toggleSectionClassName} />
          </div>
        </div>
        {!open && <hr className={styles.separator} />}
        {open && this.renderSectionContent(sortedResults, orderByClassName)}
      </div>
    );
  }
}

import React from 'react';
import { noop } from 'lodash';
import { BoundEnabledPerspective, BoundSection, BoundTab } from 'src/services/configuration/codecs/bindings.types';
import { MemoizedNavigationGroup } from 'src/components/NavigationGroup/NavigationGroup';

interface FastNavProps {
  perspective: BoundEnabledPerspective;
  tab: BoundTab;
  sections: BoundSection[] | undefined;
  onlyIndirectMfpScoping: boolean;
  mfpScopeReady: boolean;
  onItemClick: () => void;
}

export const FastNav = ({
  perspective,
  tab,
  sections,
  onItemClick,
  onlyIndirectMfpScoping,
  mfpScopeReady,
}: FastNavProps) => {
  return (
    <div style={{ display: 'flex' }}>
      {sections
        ?.filter((section) => !section.hidden)
        .map((section, index) => {
          if (section.id === 'bookmarks') return;
          return (
            <div key={`fastNavSection-${index}`} style={{ padding: '0 0.25rem' }}>
              <MemoizedNavigationGroup
                expandable={false}
                setFocusedGroup={noop}
                expanded={true}
                activeViewFrom={true}
                perspective={perspective}
                tab={tab}
                section={section}
                key={section.name}
                onItemClick={onItemClick}
                onlyIndirectMfpScoping={onlyIndirectMfpScoping}
                mfpScopeReady={mfpScopeReady}
              />
            </div>
          );
        })}
    </div>
  );
};

/** In the case that the FastNav content fails to be generated for whatever reason */
export const BlankFastNav = () => {
  return <div style={{ font: 'inherit' }}>The navigation for this tab failed to load.</div>;
};
